import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

/**
 * @param {string} type
 * @param {object} error
 * @returns {string}
 */
export const requestMessageService = (type, error) => {
	console.group('%cerror', 'color: red');
	console.error(type, error);
	console.groupEnd();
	switch (type) {
		case ACTION_TYPES.AUTH_LOGIN:
		case ACTION_TYPES.AUTH_LOAD_PROFILE:
			if (error.response && error.response.status) {
				if (
					error.response.status === 401 ||
					error.response.status === 422
				) {
					return 'Het e-mailadres en wachtwoord komen niet overeen.';
				}
				if (error.response.status === 404) {
					return 'Gebruiker niet gevonden. Neem contact op met jou HR-contactpersoon.';
				}
			}
			return 'Inloggen mislukt vanwege een onverwachte fout.';

		case ACTION_TYPES.AUTH_LOAD_PICTURE:
		case ACTION_TYPES.MEDIA_LOAD_USER_PICTURE:
		case ACTION_TYPES.MEDIA_LOAD_PICTURE:
			return errorMessageService(
				'image',
				'load',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.AUTH_LOAD_CONTACTS:
			return errorMessageService(
				'contacts',
				'load',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.MEDIA_LOAD_BLOB:
			return errorMessageService(
				'file',
				'load',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.THEME_MODE_UPDATE:
		case ACTION_TYPES.THEME_UPDATE:
			return errorMessageService(
				'theme',
				'update',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.STICKERS_LOAD:
			return errorMessageService(
				'stickers',
				'load',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.NEWS_ARTICLES_LOAD:
			return errorMessageService(
				'articles',
				'load',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.SUB_FEEDS_LOAD:
			return errorMessageService(
				'sub_feeds',
				'load',
				error.response,
				error.response.status,
			);
		case ACTION_TYPES.SUB_FEED_LOAD:
			return errorMessageService(
				'sub_feed',
				'load',
				error.response,
				error.response.status,
			);

		case ACTION_TYPES.MAIN_FEEDS_LOAD:
			return errorMessageService(
				'main_feeds',
				'load',
				error.response,
				error.response.status,
			);
		case ACTION_TYPES.MAIN_FEED_LOAD:
			return errorMessageService(
				'main_feed',
				'load',
				error.response,
				error.response.status,
			);

		default:
			return 'Er ging its onverwachts fout. Neem contact op met support@unipartners.be wanneer het probleem zich blijft voordoen.';
	}
};

/**
 * @param {string} type
 * @param {string} action
 * @param {object} response
 * @param {int} status
 * @returns {string}
 */
export const errorMessageService = (type, action, response, status) => {
	if (response) {
		if (status && status === 401) {
			return `Je bent niet bevoegd om ${typeHelper(
				type,
				true,
			).toLowerCase()} ${actionHelper(action)}.`;
		}
		if (status && status === 403) {
			return `Je hebt niet voldoende rechten om ${typeHelper(
				type,
				true,
			).toLowerCase()} ${actionHelper(action)}.`;
		}
		if (status && status === 404) {
			return `${typeHelper(type)} niet gevonden.`;
		}
	}

	return `${typeHelper(type)} ${actionHelper(
		action,
		false,
	)} mislukt vanwege een onverwachte fout.`;
};

/**
 * @param {string} type
 * @param {string} action
 * @returns {`${string} succesvol ${string}.`}
 */
export const successMessageService = (type, action) => {
	return `${typeHelper(type)} succesvol ${actionHelper(action)}.`;
};

/**
 * @param {string} type The type of item item
 * @param {boolean} addArticle Add the article
 * @returns {string}
 */
const typeHelper = (type, addArticle = false) => {
	switch (type) {
		/*case 'notifications':
            return `${addArticle ? 'De ' : ''}Notificaties`;
        case 'notification':
            return `${addArticle ? 'De ' : ''}Notificatie`;
        case 'attachments':
            return `${addArticle ? 'De ' : ''}Bijlagen`;
        case 'attachment':
            return `${addArticle ? 'De ' : ''}Bijlage`;
        case 'tags':
            return `${addArticle ? 'De ' : ''}Tags`;
        case 'tag':
            return `${addArticle ? 'De ' : ''}Tag`;*/

		case 'image':
			return `${addArticle ? 'De ' : ''}Afbeelding`;
		case 'contacts':
			return `${addArticle ? 'De ' : ''}Contacten`;
		case 'file':
			return `${addArticle ? 'Het ' : ''}Bestand`;
		case 'theme':
			return `${addArticle ? 'Het ' : ''}Thema`;
		case 'articles':
			return `${addArticle ? 'De ' : ''}Artikelen`;
		case 'article':
			return `${addArticle ? 'Het ' : ''}Artikel`;
		case 'sub_feeds':
			return `${addArticle ? 'De ' : ''}Sub-feed`;
		case 'sub_feed':
			return `${addArticle ? 'Het ' : ''}Sub-feed-item`;
		case 'main_feeds':
			return `${addArticle ? 'De ' : ''}Main-feed`;
		case 'main_feed':
			return `${addArticle ? 'Het ' : ''}Main-feed-Item`;
		case 'stickers':
			return `${addArticle ? 'De ' : ''}Stickers`;
		default:
			return `dit`;
	}
};

/**
 * @param {string} action Action to be described
 * @param {boolean} addPreposition Add a preposition to the return
 * @returns {string|string}
 */
const actionHelper = (action, addPreposition = true) => {
	switch (action) {
		case 'load':
			return `${addPreposition ? 'te ' : ''}laden`;
		case 'loaded':
			return 'geladen';
		case 'create':
			return `aan${addPreposition ? ' te ' : ''}maken`;
		case 'created':
			return 'aangemaakt';
		case 'read':
			return `${addPreposition ? 'te ' : ''}bekijken`;
		case 'update':
			return `${addPreposition ? 'te ' : ''}wijzigen`;
		case 'updated':
			return 'gewijzigd';
		case 'delete':
			return `${addPreposition ? 'te ' : ''}verwijderen`;
		case 'deleted':
			return 'verwijderd';
		case 'publish':
			return `${addPreposition ? 'te ' : ''}publiceren`;
		case 'published':
			return 'gepubliceerd';
		case 'pin':
			return `vast${addPreposition ? ' te ' : ''}pinnen`;
		case 'pinned':
			return 'vastgepind';
		case 'unpin':
			return `los${addPreposition ? ' te ' : ''}maken`;
		case 'unpinned':
			return 'los gemaakt';
		default:
			return addPreposition ? 'te doen' : 'laden';
	}
};
