import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const setInviteStatus = (id, type, accept) => {
	const state = store.getState();
	const { confirmed } = state.inviteReducer;
	dispatchAction({
		type: ACTION_TYPES.INVITE_SET,
		payload: {
			confirmed: accept,
		},
		meta: {
			action: {
				effect: {
					url: `/invitations/${type}/${id}`,
					method: 'post',
					body: { accept },
				},
				commit: { type: ACTION_TYPES.INVITE_LOAD_COMMIT },
				rollback: {
					type: ACTION_TYPES.INVITE_SET,
					payload: { confirmed },
				},
			},
			toast: accept
				? { type: 'success', text: 'Je bent ingeschreven' }
				: { type: 'warning', text: 'Je bent niet ingeschreven' },
		},
	});
};

export const getInviteStatus = (id, type) => {
	dispatchAction({
		meta: {
			action: {
				effect: {
					url: `/invitations/${type}/${id}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.INVITE_LOAD_COMMIT },
				rollback: {},
			},
		},
	});
};
