const defaultState = {
	wellbeingBarometer: null,
	wellbeingLoading: false,
};

const loadWellbeingBarometer = (state, { wellbeingLoading }) => ({
	...state,
	wellbeingLoading,
});

const loadWellbeingBarometerCommit = (state, { data }) => {
	return {
		...state,
		wellbeingBarometer: data,
		wellbeingLoading: false,
	};
};

const deleteWellBeingBarometer = (state, { id }) => {
	return {
		...state,
		wellbeingBarometer:
			state.wellbeingBarometer.id !== id
				? state.wellbeingBarometer
				: null,
	};
};

export const wellbeingReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case 'WELLBEING_LOAD':
			return loadWellbeingBarometer(state, payload);
		case 'WELLBEING_LOAD_COMMIT':
			return loadWellbeingBarometerCommit(state, payload);
		case 'WELLBEING_DELETE':
			return deleteWellBeingBarometer(state, payload);
		default:
			return state;
	}
};
