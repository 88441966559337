import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { faGlobe, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateUserData } from 'core/store/authentication/auth.actions';
import { getSubscription, isPushSupported } from 'registerServiceWorker';

import {
	Button,
	InputCheckbox,
	InputField,
	Toggle,
	useForm,
} from 'components/Form';

import {
	createLinkFields,
	linkLabelField,
	linkUrlField,
} from './settings.helpers';
import {
	getLinks,
	getSubscriptionTypes,
	subscribeToPushNotifications,
	unsubscribeFromPushNotifications,
	updateLinks,
	updatePhoneVisibility,
	updatePrivacy,
	updateSubscription,
} from './settings.service';

import './UpdateInstellingen.scss';

export const UpdateInstellingen = () => {
	const { authUser, settings } = useSelector(state => state.authReducer);
	const [links, setLinks] = useState([]);
	const [checkedLinks, setCheckedLinks] = useState([]);
	const [customLinks, setCustomLinks] = useState([]);

	useEffect(() => {
		getLinks()
			.then(links => {
				setLinks(links);
			})
			.then(() => {
				setCheckedLinks(settings?.links);
				setCustomLinks(settings?.customLinks);
			});
		getSubscription().then(subscription => {
			getNotificationsForm.setValue('getNotifications', !!subscription);
		});
		getSubscriptionTypes().then(types => {
			notificationTypesForm.setValue('timesheets', types?.timesheets);
			notificationTypesForm.setValue('trainings', types?.trainings);
			notificationTypesForm.setValue('events', types?.events);
		});

		privacyForm.setValue('showTrainings', settings?.privacy?.showTrainings);
		privacyForm.setValue('showEvents', settings?.privacy?.showEvents);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectLinksForm = useForm({
		onFormChange: values => {
			const selectedLinks = Object.keys(values).filter(
				key => values[key],
			);
			const selected = [...links, ...customLinks].filter(link =>
				selectedLinks.includes(link.label),
			);
			updateLinks(selected, customLinks).then(settings => {
				if (settings) {
					updateUserData({ settings });
					setCheckedLinks(selected);
				}
			});
		},
	});
	const addLinksForm = useForm();
	const showNumberForm = useForm({
		onFormChange: values => {
			updatePhoneVisibility(
				authUser?.telephone[0]?.number,
				values.showNumber ? 1 : 0,
			).then(status => {
				if (status) {
					updateUserData({
						authUser: {
							...authUser,
							telephone: [
								{
									...authUser?.telephone[0],
									visible: values.showNumber ? 1 : 0,
								},
							],
						},
					});
				}
			});
		},
	});
	const privacyForm = useForm({
		onFormChange: values => {
			let req = {
				settings: {
					...settings,
					privacy: {
						...settings.privacy,
						showEvents: values.showEvents,
						showTrainings: values.showTrainings,
					},
				},
			};
			updatePrivacy(req).then(status => {
				if (status) {
					updateUserData(req);
				}
			});
		},
	});

	const getNotificationsForm = useForm({
		onFormChange: values => {
			if (values.getNotifications) {
				subscribeToPushNotifications();
				notificationTypesForm.setValue('timesheets', true);
				notificationTypesForm.setValue('trainings', true);
				notificationTypesForm.setValue('events', true);
			} else {
				unsubscribeFromPushNotifications();
			}
		},
	});

	const notificationTypesForm = useForm({
		onFormChange: values => {
			updateSubscription(values);
		},
	});

	const handleAddLink = async () => {
		const label = addLinksForm.getValue('linkLabel');
		const url = addLinksForm.getValue('linkUrl');
		const newLink = {
			label: label.trim() || url,
			url,
		};
		const settings = await updateLinks(checkedLinks, [
			...customLinks,
			newLink,
		]);
		if (settings) {
			updateUserData({ settings });
			setCustomLinks(prev => [...prev, newLink]);

			addLinksForm.reset();
		}
	};

	const handleEditLink = link => {
		addLinksForm.setValue('linkLabel', link.label);
		addLinksForm.setValue('linkUrl', link.url);
		const filterLinks = ({ label }) => link.label !== label;
		setCheckedLinks(prev => prev.filter(filterLinks));
		setCustomLinks(prev => prev.filter(filterLinks));
	};

	const handleDeleteLink = async link => {
		selectLinksForm.setValue(link.name, false);
		const filterLinks = ({ label }) => link.label !== label;
		const settings = await updateLinks(
			checkedLinks.filter(filterLinks),
			customLinks.filter(filterLinks),
		);
		if (settings) {
			updateUserData({ settings });
			setCustomLinks(prev => prev.filter(filterLinks));
		}
	};

	const linkExists = () => {
		const label = addLinksForm.getValue('linkLabel');
		const href = addLinksForm.getValue('linkUrl');
		return [...customLinks, ...links].some(
			link => link.label === label || link.href === href,
		);
	};

	const createLinks = createLinkFields(checkedLinks);

	const isReadOnly = key => {
		const selected = Object.keys(selectLinksForm.values).filter(
			key => selectLinksForm.values[key],
		);
		return selected.length >= 3 && selected.indexOf(key) === -1;
	};

	return (
		<div className='settings'>
			<div className='settings__section'>
				<div className='settings__section__header'>
					<p>Algemeen</p>
				</div>
				<div className='settings__section__content'>
					<div className='settings__section__links'>
						{createLinks(links).map((link, i) => (
							<div
								className='settings__section__link'
								key={link.label}>
								<InputCheckbox
									{...selectLinksForm.register(
										link.label,
										link,
									)}
									readOnly={isReadOnly(link.label)}
								/>
								<div className='settings__section__buttons'>
									<a
										href={links[i].url}
										target='_blank'
										rel='noopener noreferrer'
										className='settings__section__button'>
										<FontAwesomeIcon
											icon={faGlobe}
											fixedWidth
										/>
									</a>
								</div>
							</div>
						))}
						<br />
						{createLinks(customLinks).map((link, i) => (
							<div
								className='settings__section__link'
								key={link.label}>
								<InputCheckbox
									{...selectLinksForm.register(
										link.label,
										link,
									)}
									readOnly={isReadOnly(link.label)}
								/>
								<div className='settings__section__buttons'>
									<span
										onClick={() =>
											handleEditLink(customLinks[i])
										}
										className='settings__section__button'>
										<FontAwesomeIcon
											icon={faPen}
											fixedWidth
										/>
									</span>
									<span
										onClick={() => handleDeleteLink(link)}
										className='settings__section__button'>
										<FontAwesomeIcon
											icon={faTrash}
											fixedWidth
										/>
									</span>
									<a
										href={customLinks[i].url}
										target='_blank'
										rel='noopener noreferrer'
										className='settings__section__button'>
										<FontAwesomeIcon
											icon={faGlobe}
											fixedWidth
										/>
									</a>
								</div>
							</div>
						))}
						<p className='settings__section__description'>
							Selecteer maximaal 3 items om toe te voegen aan jouw
							persoonlijke gebruikersmenu.
						</p>
					</div>
					<div className='settings__section__add-link'>
						<InputField
							{...addLinksForm.register(
								'linkLabel',
								linkLabelField,
							)}
						/>
						<InputField
							{...addLinksForm.register('linkUrl', linkUrlField)}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									handleAddLink();
								}
							}}
						/>
						<Button
							buttonStyle='primary'
							onClick={handleAddLink}
							disabled={!addLinksForm.isValid() || linkExists()}>
							Toevoegen
						</Button>
					</div>
				</div>
			</div>
			<div className='settings__section'>
				<div className='settings__section__header'>
					<p>Privacy</p>
				</div>
				<div className='settings__section__content'>
					<div className='settings__section__show-number'>
						<div className='settings__section__show-number__control'>
							<Toggle
								className='settings__section__show-number__toggle'
								{...showNumberForm.register('showNumber', {
									value: authUser?.telephone[0]?.visible,
								})}
							/>{' '}
							<span className='settings__section__show-number__number'>
								{authUser?.telephone[0]?.number}
							</span>
						</div>
						<p className='settings__section__description'>
							Toon je telefoonnummer op je account. Deze gegevens
							zullen altijd zichtbaar zijn op je account voor
							medewerkers die deze informatie nodig hebben.
						</p>
					</div>
					<div className='settings__section__show-upcoming'>
						<div className='settings__section__show-upcoming__control'>
							<Toggle
								className='settings__section__show-upcoming__toggle'
								{...privacyForm.register('showEvents', {})}
							/>{' '}
							<span className='settings__section__notification-types__number'>
								Toon je toekomstige evenementen
							</span>
						</div>
						<div className='settings__section__show-upcoming__control'>
							<Toggle
								className='settings__section__show-upcoming__toggle'
								{...privacyForm.register('showTrainings', {})}
							/>{' '}
							<span className='settings__section__notification-types__number'>
								Toon je toekomstige opleidingen
							</span>
						</div>
					</div>
				</div>
			</div>
			{isPushSupported() ? (
				<div className='settings__section'>
					<div className='settings__section__header'>
						<p>Notifications</p>
					</div>
					<div className='settings__section__content'>
						<div className='settings__section__get-notifications'>
							<div className='settings__section__get-notifications__control'>
								<Toggle
									className='settings__section__get-notifications__toggle'
									{...getNotificationsForm.register(
										'getNotifications',
										{},
									)}
								/>{' '}
								<span className='settings__section__get-notifications__number'>
									Ontvang Push-notifications
								</span>
							</div>
							<p className='settings__section__description'>
								Schakel notificaties in om push-notifications op
								je toestel te onvangen. Je zal altijd
								notificaties ontvangen op je UniPartners
								e-mailadres.
							</p>
						</div>
						{getNotificationsForm.getValue('getNotifications') ? (
							<div className='settings__section__notification-types'>
								<div className='settings__section__notification-types__control'>
									<Toggle
										className='settings__section__notification-types__toggle'
										{...notificationTypesForm.register(
											'timesheets',
											{},
										)}
									/>{' '}
									<span className='settings__section__notification-types__number'>
										Krijg notificaties voor je Timesheet
									</span>
								</div>
								<div className='settings__section__notification-types__control'>
									<Toggle
										className='settings__section__notification-types__toggle'
										{...notificationTypesForm.register(
											'trainings',
											{},
										)}
									/>{' '}
									<span className='settings__section__notification-types__number'>
										Krijg notificaties voor je opleidingen
									</span>
								</div>
								<div className='settings__section__notification-types__control'>
									<Toggle
										className='settings__section__notification-types__toggle'
										{...notificationTypesForm.register(
											'events',
											{},
										)}
									/>{' '}
									<span className='settings__section__notification-types__number'>
										Krijg notificaties voor je evenementen
									</span>
								</div>
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};
