import { useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMedal, fas, faStopwatch20 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';

import Table from 'components/Table/Table';

import MovingMissionSidebar from '../Sidebar/MovingMissionSidebar';

library.add(fas);

const MovingMissionAchievements = () => {
	const { movingMission, types } = useSelector(
		state => state.movingMissionReducer,
	);

	const findTypeIcon = name => types.find(type => type.name === name)?.icon;

	const parsePoints = (row, type) => {
		const count = row[type];
		const bonus = row[`${type}Points`];
		if (!count) return '0';
		return (
			count +
			(bonus ? ` (${bonus} ${count === 1 ? 'punt' : 'punten'})` : '')
		);
	};
	const headers = {
		date: 'Datum',
		type: 'Sport',
		duration: 'Minuten',
		naturePictures: "Natuurfoto's",
		selfies: 'Selfies',
		streakBonus: 'Bonus',
		total: 'Totaal',
	};

	return movingMission ? (
		<section className='moving-mission-achievements'>
			<section className='moving-mission-achievements__content'>
				<section className='moving-mission-achievements__content__today'>
					<span className='title'>Vandaag</span>
					<div className='moving-mission-achievements__content__today__points'>
						{movingMission?.today?.workouts.length ? (
							movingMission?.today?.workouts.map(
								(workout, index) => (
									<MovingMissionTodayItem
										key={index}
										text={`${
											workout.minutes
										} minuten ${workout.type.toLowerCase()}`}
										icon={faStopwatch20}
									/>
								),
							)
						) : (
							<span className='no-data-message'>
								Geen trainingen vandaag
							</span>
						)}
					</div>
					<div className='moving-mission-achievements__content__today__motivation'>
						<span className='motivation-icon'>
							<FontAwesomeIcon icon={faMedal} size='xl' />
						</span>
						<span className='motivation-text'>
							<span>{movingMission?.today?.motivation?.one}</span>
							<br />
							<span>{movingMission?.today?.motivation?.two}</span>
						</span>
					</div>
				</section>
				<section className='moving-mission-achievements__sidebar'>
					<MovingMissionSidebar />
				</section>
			</section>
			{movingMission?.history?.length ? (
				<section className='moving-mission-achievements__history'>
					<span className='title'>Moving Mission History</span>
					<Table
						headers={headers}
						totalColumn='total'
						data={movingMission?.history?.map(row => ({
							...row,
							type: (
								<span className='sport-type'>
									<FontAwesomeIcon
										className='sport-type__icon'
										icon={findTypeIcon(row.type)}
									/>
									<span className='sport-type__name'>{` ${row.type}`}</span>
								</span>
							),
							date: toShortDate(row.date),
							naturePictures: parsePoints(row, 'naturePictures'),
							selfies: parsePoints(row, 'selfies'),
						}))}
					/>
				</section>
			) : null}
		</section>
	) : null;
};

export default MovingMissionAchievements;

const MovingMissionTodayItem = ({ text, icon }) => {
	return (
		<div className='moving-mission-achievements__content__today__item'>
			<span className='item-icon'>
				<FontAwesomeIcon icon={icon} />
			</span>
			<span className='item-text'>{text}</span>
		</div>
	);
};
