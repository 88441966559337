import { handleAlert } from 'core/services/actions/action.service';
import { executeAxios } from 'core/services/actions/axios.service';
import { getSubscription, subscribe, unsubscribe } from 'registerServiceWorker';

export const getLinks = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/user-links',
		});

		return data || [];
	} catch (error) {
		console.log(error);
	}
};

export const updatePhoneVisibility = async (number, visible) => {
	try {
		const {
			data: { status },
		} = await executeAxios({
			url: '/auth/me/phone',
			method: 'PUT',
			data: {
				numbers: [{ number, visible }],
			},
		});
		handleAlert('success', 'Telefoonnummer zichtbaarheid aangepast');
		return status;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

export const updatePrivacy = async settings => {
	try {
		const {
			data: { status },
		} = await executeAxios({
			url: '/auth/me/settings',
			method: 'PUT',
			data: settings,
		});
		handleAlert('success', 'Je privacyinstellingen zijn aangepast');
		return status;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

export const updateLinks = async (links, customLinks) => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/auth/me/settings',
			method: 'PUT',
			data: {
				settings: {
					theme: 'light',
					links,
					customLinks,
				},
			},
		});
		handleAlert('success', 'Links aangepast');
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

export const subscribeToPushNotifications = async () => {
	try {
		await subscribe();
		handleAlert('success', 'Push notificaties aangezet');
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

export const unsubscribeFromPushNotifications = async () => {
	try {
		await unsubscribe();
		handleAlert('success', 'Push notificaties uitgezet');
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

/**
 *
 * @param {{
 *   timehseets: boolean,
 *   trainings: boolean,
 *   events: boolean,
 * }} types
 * @returns {Promise<void>}
 */
export const updateSubscription = async types => {
	try {
		await executeAxios({
			method: 'POST',
			url: '/push-notifications/subscription-types',
			data: { subscription: await getSubscription(), types },
			headers: {
				'Content-Type': 'application/json',
			},
		});
		handleAlert('success', 'Notificatie instellingen aangepast');
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

/**
 * Fetched the saved subscription types from the backend
 * @returns {Promise<{timehseets: 0|1, trainings: 0|1, events: 0|1}>}
 */
export const getSubscriptionTypes = async () => {
	const subscription = await getSubscription();
	if (!subscription) return { timehseets: 0, trainings: 0, events: 0 };
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/push-notifications/subscription-types',
			params: { subscription_id: subscription.endpoint },
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return data;
	} catch (error) {
		console.log(error);
	}
};
