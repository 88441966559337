const reportWebVitals = onPerfEntry => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('web-vitals').then(temp => {
			console.log(temp);
			//getCLS(onPerfEntry);
			//getFID(onPerfEntry);
			//getFCP(onPerfEntry);
			//getLCP(onPerfEntry);
			//getTTFB(onPerfEntry);
		});
	}
};

export default reportWebVitals;
