import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const loadMovingMission = () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_LOAD,
		meta: {
			action: {
				effect: {
					url: `/moving-mission`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.MOVING_MISSION_LOAD_COMMIT },
				rollback: {
					type: ACTION_TYPES.MOVING_MISSION_LOAD,
				},
			},
		},
	});
};

export const loadMovingMissionTypes = () => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_TYPES_LOAD,
		meta: {
			action: {
				effect: {
					url: `/moving-mission/types`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.MOVING_MISSION_TYPES_LOAD_COMMIT },
				rollback: {
					type: ACTION_TYPES.MOVING_MISSION_TYPES_LOAD,
				},
			},
		},
	});
};

export const updateMovingMission = data => {
	dispatchAction({
		type: ACTION_TYPES.MOVING_MISSION_LOAD_COMMIT,
		payload: { data },
	});
};
