import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MovingMissionRankingList = ({
	title,
	list,
	type: { singular, plural },
	icon,
}) => {
	const getName = item => {
		return `${item.firstName} ${item.lastName}`;
	};

	const getValue = item => {
		if (item.points) {
			return {
				value: item.points,
				label: item.points === 1 ? singular : plural,
			};
		}
		if (item.streakDays) {
			return {
				value: item.streakDays,
				label: item.streakDays === 1 ? singular : plural,
			};
		}
	};

	const renderValue = item => {
		const value = getValue(item);
		return (
			<>
				<span>{value.value}</span>
				<span>{value.label}</span>
			</>
		);
	};

	return (
		<section className='moving-mission-overview__content__ranking-list'>
			<span className='title'>{title}</span>
			<ul className='moving-mission-overview__content__ranking-list__items'>
				{list?.length ? (
					list?.map((item, index) => (
						<li
							className={`moving-mission-overview__content__ranking-list__item${
								index < 3 ? ' place__' + (+index + 1) : ''
							}`}
							key={index}>
							<span className='moving-mission-overview__content__ranking-list__item__name'>
								<span className='icon'>
									{index < 3 ? (
										<FontAwesomeIcon
											icon={icon}
											size='lg'
										/>
									) : null}
								</span>
								{getName(item)}
							</span>
							<span className='moving-mission-overview__content__ranking-list__item__value'>
								{renderValue(item)}
							</span>
						</li>
					))
				) : (
					<span className='no-data-message'>
						Geen data beschikbaar
					</span>
				)}
			</ul>
		</section>
	);
};

export default MovingMissionRankingList;
