import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadAlerts = () => {
	const state = store.getState();
	const { alertsLoading } = state.alertReducer;
	if (!alertsLoading) {
		dispatchAction({
			type: ACTION_TYPES.ALERTS_LOAD,
			payload: { alertsLoading: true },
			meta: {
				action: {
					effect: {
						url: `/alerts`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.ALERTS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.ALERTS_LOAD,
						payload: { alertsLoading: false },
					},
				},
			},
		});
	}
};

/**
 * Updates the alerts in the store
 * @param {{
 *   id?: number,
 *   type: string,
 *   date?: `${number}-${number}-${number}`,
 *   content?: {
 *     id?: number,
 *     name?: string,
 *     slug?: string,
 *     }
 * }} alert
 * @param {'add' | 'remove'} action
 */
export const updateAlerts = (alert, action) => {
	dispatchAction({
		type: ACTION_TYPES.ALERTS_UPDATE,
		payload: { alert, action },
	});
};
