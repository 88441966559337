import { useEffect, useRef } from 'react';

const useClickOutside = (callback, mouseEvent = 'click') => {
	const ref = useRef(null);

	const handleClickOutside = event => {
		const isClickOutside =
			ref.current && !ref.current.contains(event.target) && !event.key;
		const isEscapeKey = event.key === 'Escape';

		if (isClickOutside || isEscapeKey) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener(mouseEvent, handleClickOutside);
		document.addEventListener('keyup', handleClickOutside);
		return () => {
			document.removeEventListener(mouseEvent, handleClickOutside);
			document.removeEventListener('keyup', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return ref;
};

export default useClickOutside;
