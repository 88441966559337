import { useEffect } from 'react';

/**
 *
 * @param {number} nextPage
 * @param {boolean} hasNextPage
 * @param {boolean} pageIsLoading
 * @param {function} loadItems
 */
const useLoadItemsOnScroll = (
	nextPage,
	hasNextPage,
	pageIsLoading,
	loadItems,
	pageId = 'home',
) => {
	useEffect(() => {
		if (nextPage === 2 && !pageIsLoading) {
			document.getElementById(pageId).children[0].scrollIntoView({
				behavior: 'instant',
				block: 'end',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextPage]);

	useEffect(() => {
		const handleScroll = () => {
			const wrappedElement = document.getElementById(pageId);

			if (
				wrappedElement.getBoundingClientRect().bottom <=
				window.innerHeight + 1000
			) {
				if (hasNextPage && !pageIsLoading) loadItems(nextPage);
			}
		};

		window.addEventListener('scroll', handleScroll, true);

		return function cleanup() {
			window.removeEventListener('scroll', handleScroll, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasNextPage, pageIsLoading, nextPage]);
};

export default useLoadItemsOnScroll;
