import { useEffect, useState } from 'react';

import Loader from 'components/Loading/Loading';

import { TekenModal } from '../Modals/TekenModal/TekenModal';

import { getUnsignedTimesheets } from './timesheetOverview.service';
import TimesheetOverviewItem from './TimesheetOverviewItem';

import './TimesheetOverview.scss';

const TimesheetOverview = () => {
	const [months, setMonths] = useState(null);
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState();

	const loadTimesheets = async () => {
		const data = await getUnsignedTimesheets();
		setLoading(false);
		if (data) {
			setMonths(data);
		}
	};

	useEffect(() => {
		loadTimesheets();
	}, []);

	return (
		<div className='timesheet-overview'>
			{loading ? (
				<Loader />
			) : months?.length ? (
				months
					.filter(({ projects }) => projects.length)
					.map(month => (
						<TimesheetOverviewItem
							key={month.date}
							date={month.date}
							projects={month.projects}
							bikeMileages={month.bikeMileages}
							carMileages={month.carMileages}
							setModal={setModal}
							loadTimesheets={loadTimesheets}
							lastApproved={month.approvedDate}
							lastSigned={month.signedDate}
						/>
					))
			) : (
				<p className='no-data-message'>
					Er zijn geen timesheets gevonden.
				</p>
			)}
			{modal ? (
				<TekenModal
					modal={modal}
					setModal={setModal}
					loadTimesheets={loadTimesheets}
				/>
			) : null}
		</div>
	);
};

export default TimesheetOverview;
