import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { array, bool, func, node, string } from 'prop-types';

import { InputField, useField } from 'components/Form';

import './PageHeading.scss';

export const PageHeading = ({
	pageTitle,
	subTextArray,
	extraChildrenInsideInleiding,
	extraChildrenOutsideInleiding,
	extraChildrenInsideHeader,
	extraClass,
	showSearchBar = false,
	onSearch,
	overrideSearch,
	searchPlaceholder,
}) => {
	const inputSearchProps = useField({
		type: 'text',
		name: 'search',
		placeholder: searchPlaceholder || 'Zoek...',
		icon: faMagnifyingGlass,
		onChange: onSearch,
		overwriteValue: overrideSearch,
	});

	return (
		<section
			className={`inleiding-container ${extraClass ? extraClass : null}`}>
			<div className='inleiding-title'>
				{pageTitle ? (
					<div className='page__title'>
						<h1>{pageTitle}</h1>
					</div>
				) : null}
				{showSearchBar ? (
					<div className='child-inside-header-container'>
						<InputField {...inputSearchProps} />
					</div>
				) : null}
				{extraChildrenInsideHeader ? (
					<div className='child-inside-header-container'>
						{extraChildrenInsideHeader}
					</div>
				) : null}
			</div>
			<section className='inleiding-text-container'>
				{subTextArray
					? subTextArray.map((subtext, index) => (
							<p className='inleiding-text' key={index}>
								{subtext}
							</p>
						))
					: null}
				{extraChildrenInsideInleiding ? (
					<div className='child-inside-text-container'>
						{extraChildrenInsideInleiding}
					</div>
				) : null}
			</section>

			{extraChildrenOutsideInleiding ? (
				<div className='child-outside-text-container'>
					{extraChildrenOutsideInleiding}
				</div>
			) : null}
		</section>
	);
};

PageHeading.propTypes = {
	pageTitle: string,
	subTextArray: array,
	extraChildrenInsideInleiding: node,
	extraChildrenOutsideInleiding: node,
	extraClass: string,
	showSearchBar: bool,
	onSearch: func,
	overrideSearch: string,
};
