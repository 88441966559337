import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { bool, object, string } from 'prop-types';

const CardHeader = ({ title, subTitle, url, meta, icon, loading }) => {
	const online = useOnlineStatus();

	return (
		<div className='card__header'>
			<div className='card__header_wrapper'>
				{meta ? (
					<h4>
						{loading ? (
							online ? (
								<Skeleton />
							) : (
								'Item kan niet worden geladen.'
							)
						) : (
							meta
						)}
					</h4>
				) : null}

				<h2>
					{icon ? <FontAwesomeIcon icon={icon} fixedWidth /> : null}
					{loading ? (
						online ? (
							<Skeleton />
						) : (
							'Item kan niet worden geladen'
						)
					) : url ? (
						<Link to={url}>{title}</Link>
					) : (
						title
					)}
				</h2>

				{subTitle ? (
					loading ? (
						online ? (
							<Skeleton />
						) : (
							<h3>Item kan niet worden geladen.</h3>
						)
					) : (
						<h3
							dangerouslySetInnerHTML={{
								__html: subTitle.replace(/\r\n/g, '<br />'),
							}}
						/>
					)
				) : null}
			</div>
		</div>
	);
};

CardHeader.propTypes = {
	title: string.isRequired,
	subTitle: string,
	url: string,
	meta: string,
	icon: object,
	withReadMore: bool,
	showArrow: bool,
	loading: bool.isRequired,
};

export default CardHeader;
