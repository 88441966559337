import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadNotifications = () => {
	const state = store.getState();
	const { notificationsLoading } = state.notificationReducer;
	if (!notificationsLoading) {
		dispatchAction({
			type: ACTION_TYPES.NOTIFICATIONS_LOAD,
			payload: { notificationsLoading: true },
			meta: {
				action: {
					effect: {
						url: `/notifications`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.NOTIFICATIONS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.NOTIFICATIONS_LOAD,
						payload: { notificationsLoading: false },
					},
				},
			},
		});
	}
};

export const setNotificationsToSeen = () => {
	const state = store.getState();
	const { notifications } = state.notificationReducer;
	dispatchAction({
		type: ACTION_TYPES.NOTIFICATIONS_SEEN,
		meta: {
			action: {
				effect: {
					url: `/notifications`,
					method: 'post',
				},
				rollback: {
					type: ACTION_TYPES.NOTIFICATIONS_LOAD_COMMIT,
					payload: { data: notifications },
				},
			},
		},
	});
};

export const clearNotifications = () => {
	dispatchAction({
		type: ACTION_TYPES.NOTIFICATIONS_CLEAR,
	});
};
