import { useEffect, useState } from 'react';
import useAxios from 'core/hooks/api/useAxios';

import Category from 'components/Category/Category';
import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import Loading from 'components/Loading/Loading';

const Documents = () => {
	const [documents, setDocuments] = useState([]);
	const [showId, setShowId] = useState(0);

	const handleShow = id => setShowId(id);

	const [documentsRequest, , loading] = useAxios({
		url: `/documents`,
		method: 'get',
	});

	useEffect(() => {
		setDocuments(documentsRequest.data);
	}, [documentsRequest]);

	const renderDocuments = (documentsFilteredByCategory, id) => {
		if (loading) {
			return <Loading />;
		}
		return (
			<Category
				key={id}
				documents={documentsFilteredByCategory}
				id={id}
				show={showId === id}
				onShow={handleShow}
			/>
		);
	};

	let uniqueCategories = [...new Set(documents?.map(item => item.category))];

	return (
		<>
			<PageHeading
				pageTitle='Documenten'
				searchPlaceholder='Doorzoek documenten'
			/>
			<section className='documents-container'>
				{uniqueCategories.map((element, i) => {
					let arrayToDisplay = documents.filter(
						item => item.category === element,
					);
					return renderDocuments(arrayToDisplay, i);
				})}
			</section>
		</>
	);
};

export default Documents;
