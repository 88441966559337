import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Impersonate.scss';

export const Impersonate = () => {
	const { authUser } = useSelector(state => state.authReducer);
	const navigate = useNavigate();

	return (
		<div
			className='impersonate'
			onClick={() => navigate(`/medewerkers/${authUser.id}`)}>
			<div className='impersonate__content'>
				<div className='impersonate__icon'>
					<FontAwesomeIcon icon={faUserSecret} size='4x' />
				</div>
				<div className='impersonate__text'>
					{authUser?.firstName} {authUser?.lastName}
				</div>
			</div>
		</div>
	);
};
