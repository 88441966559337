import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { faGrinAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'core/hooks/ThemeProvider';
import { unique } from 'core/services/array';
import { bool, number, string } from 'prop-types';

import { Button } from '../Form';
import Icon from '../Icon/Icon';

import AddStickersPopover from './AddStickersPopover/AddStickersPopover';
import PlacedStickersPopover from './PlacedStickersPopover/PlacedStickersPopover';

import './Stickers.scss';

const useStickers = stickers => {
	return useMemo(() => {
		let placedStickers = {};

		stickers.forEach(placedSticker => {
			let authors =
				placedStickers[placedSticker.sticker.id] &&
				placedStickers[placedSticker.sticker.id].authors
					? placedStickers[placedSticker.sticker.id].authors
					: [];
			authors.push({
				...placedSticker.author,
				placedId: placedSticker.id,
			});

			placedStickers = {
				...placedStickers,
				[placedSticker.sticker.id]: {
					...placedStickers[placedSticker.sticker.id],
					sticker: placedSticker.sticker,
					authors: authors.filter(unique),
				},
			};
		});

		return placedStickers;
	}, [stickers]);
};

const useUserSticker = (stickers, authId) => {
	return useMemo(
		() => stickers.find(sticker => sticker.author.id === authId),
		[stickers, authId],
	);
};

const Stickers = ({ stickers, objId, feedType, type, addButton = false }) => {
	const authUser = useSelector(state => state.authReducer.authUser);
	const { theme } = useTheme();
	const [showPlacedStickers, setShowPlacedStickers] = useState(false);
	const [showAddStickers, setShowAddStickers] = useState(false);

	const userSticker = useUserSticker(stickers, authUser.id);
	const placedStickers = useStickers(stickers);

	const handleOnClick = e => {
		e.preventDefault();
		e.stopPropagation();
		setShowAddStickers(!showAddStickers);
	};

	return (
		<Popover
			isOpen={showPlacedStickers}
			positions={['top', 'bottom', 'left', 'right']}
			boundaryInset={95}
			padding={2}
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor='transparent'
					arrowSize={10}
					className='popover-arrow-container'
					arrowClassName='popover-arrow'>
					<PlacedStickersPopover placedStickers={placedStickers} />
				</ArrowContainer>
			)}
			containerClassName={`popover theme--${theme}`}>
			<Popover
				hasAutoWidth
				isOpen={showAddStickers}
				positions={['top', 'left']}
				padding={12}
				boundaryInset={20}
				onClickOutside={() => setShowAddStickers(false)}
				clickOutsideCapture
				content={({ position, childRect, popoverRect }) => (
					<ArrowContainer
						position={position}
						childRect={childRect}
						popoverRect={popoverRect}
						arrowColor='transparent'
						arrowSize={10}
						className='popover-arrow-container'
						arrowClassName='popover-arrow'>
						<AddStickersPopover
							userSticker={userSticker}
							objId={objId}
							feedType={feedType}
							type={type}
							closePopover={() => setShowAddStickers(false)}
						/>
					</ArrowContainer>
				)}
				containerClassName={`popover theme--${theme}`}
				align='start'>
				<div className='stickers'>
					<div className='stickers__wrapper'>
						<div
							className='stickers__content'
							onMouseOver={() => {
								setShowPlacedStickers(true);
								setShowAddStickers(false);
							}}
							onMouseLeave={() => setShowPlacedStickers(false)}>
							<div className='stickers__placed'>
								{Object.keys(placedStickers).length > 0
									? Object.keys(placedStickers).map(
											stickerId => (
												<Icon
													className='stickers__placed-sticker'
													icon={
														placedStickers[
															stickerId
														].sticker.class
													}
													key={stickerId}
												/>
											),
										)
									: '\u00a0'}
							</div>

							<div className='stickers__count'>
								{stickers.length}
							</div>
						</div>

						{addButton ? (
							<span className='add-sticker'>
								<Button
									buttonSize='small'
									buttonStyle='primary'
									className={userSticker ? 'active' : ''}
									label={<FontAwesomeIcon icon={faGrinAlt} />}
									onClick={handleOnClick}
								/>
							</span>
						) : null}
					</div>
				</div>
			</Popover>
		</Popover>
	);
};

Stickers.propTypes = {
	objId: number.isRequired,
	type: string.isRequired,
	feedType: string.isRequired,
	addButton: bool,
};

export default Stickers;
