/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from 'core/services/auth/getToken';

const useAxios = configParams => {
	const [res, setRes] = useState('');
	const [err, setErr] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);

				const token = await getToken();
				configParams.headers = {
					...configParams.headers,
					Authorization: `Bearer ${token}`,
				};

				axios.defaults.baseURL = process.env.REACT_APP_NODE_API_URL;

				const response = await axios.request(configParams);
				setRes(response.data);
			} catch (error) {
				setErr(error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return [res, err, loading];
};

export default useAxios;
