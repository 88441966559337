import { isRequired, isValidUrl } from 'core/services/form';

const findLink = (links, link) => {
	return links.find(l => l.label === link.label && l.url === link.url);
};

export const createLinkFields = checkedLinks => links => {
	const createLinkField = link => ({
		type: 'checkbox',
		name: link.label || link.url,
		label: link.label || link.url,
		value: !!findLink(checkedLinks, link),
		validations: [],
	});
	return links
		.map(link => createLinkField(link, checkedLinks))
		.filter(({ label }) => label);
};

export const linkLabelField = {
	type: 'text',
	name: 'linkLabel',
	label: 'Link Label',
	validations: [],
};

export const linkUrlField = {
	type: 'text',
	name: 'linkUrl',
	description: 'Voeg je eigen links toe aan jouw gebruikersmenu',
	label: 'Link URL',
	validations: [isRequired, isValidUrl],
	required: true,
};
