import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { isRequired } from 'core/services/form';

import { InputCheckbox, Select, Upload, useForm } from 'components/Form';
import Modal from 'components/Modal/Modal';

import './TekenModal.scss';

export const TekenModal = ({ modal, setModal, loadTimesheets }) => {
	const { projects, date } = modal;
	const { register, setValue, isDirty, isValid, values } = useForm();
	const { authUser } = useSelector(state => state.authReducer);

	useEffect(() => {
		const selectedProject =
			projects.length === 1
				? projects[0]
				: projects.find(
						({ project }) => project.id === values.projectId,
					);
		setValue('signedEmp', selectedProject?.signedEmp);
		setValue('signedClient', selectedProject?.signedClient);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projects, values.projectId]);

	const uploadTimesheet = async data => {
		const formData = new FormData();
		data.timesheet.forEach(file => {
			formData.append('attachments', file);
		});
		formData.append('projectId', data.projectId);
		formData.append('month', new Date(date).getMonth() + 1);
		formData.append('year', new Date(date).getFullYear());
		formData.append('signedEmp', data.signedEmp ? 1 : 0);
		formData.append('signedClient', data.signedClient ? 1 : 0);

		try {
			await executeAxios({
				method: 'POST',
				url: '/timesheets/upload',
				data: formData,
			});
			handleAlert('success', 'Timesheet opgeladen');
		} catch (error) {
			console.log('error', error);
			handleAlert('error', 'Er is iets misgegaan');
		}
	};

	const handleSendData = () => {
		const data = {
			date,
			projectId: values.projectId || projects[0]?.project.id,
			...values,
		};
		uploadTimesheet(data)
			.then(loadTimesheets)
			.then(() => setModal(null));
	};

	return (
		<Modal
			show={true}
			close={() => setModal(null)}
			title='Timesheet opladen'
			actions={[
				{
					label: 'Bewaar',
					styles: { marginLeft: '1rem' },
					disabled: !isDirty() || !isValid(),
					action: handleSendData,
				},
			]}>
			<section className='sign-project'>
				{projects.length > 1 ? (
					<Select
						className='sign-project__select'
						{...register('projectId', {
							label: 'Project',
							value: '',
							validations: [isRequired],
							required: true,
							isClearable: false,
							placeholder: '-- Selecteer een project --',
							options: projects.map(({ project }) => ({
								value: project.id,
								label: project.name,
							})),
						})}
					/>
				) : (
					<span>
						<span className='project-name'>Project:</span>&nbsp;
						{projects[0]?.project.name}
					</span>
				)}
				<Upload
					className='sign-project__upload'
					{...register('timesheet', {
						required: true,
						validations: [isRequired],
						value: [],
						label: 'Timesheet',
					})}
				/>
				<section className='sign-project__signed'>
					<InputCheckbox
						{...register('signedEmp', {
							label: `Getekend door ${authUser?.firstName} ${authUser?.lastName}`,
						})}
					/>
					<InputCheckbox
						{...register('signedClient', {
							label: 'Getekend door je eindklant',
						})}
					/>
				</section>
			</section>
		</Modal>
	);
};
