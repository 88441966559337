import {
	faCheck,
	faExclamationTriangle,
	faFire,
	faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Warning.scss';

const Warning = ({ className, children }) => {
	let icon;
	switch (className) {
		case 'success':
			icon = faCheck;
			break;
		case 'error':
			icon = faFire;
			break;
		case 'warning':
			icon = faExclamationTriangle;
			break;
		case 'info':
			icon = faInfoCircle;
			break;
		default:
			icon = faInfoCircle;
			break;
	}

	return (
		<div className={`warning-container ${className}`}>
			<div className='warning__icon'>
				<FontAwesomeIcon icon={icon} />
			</div>

			<div className='warning__body'>{children}</div>
		</div>
	);
};

export default Warning;
