import React from 'react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toTime } from 'core/services/date';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import './DisplayDates.scss';

export const DisplayDates = ({ days, hideIcon, extraClass }) => {
	return (
		<section
			className={`display-dates-container ${
				extraClass ? extraClass : ''
			}`}>
			<section className='datetime_container'>
				{days?.length === 1
					? days?.map((day, index) => (
							<section
								key={index}
								className='datetime-hour-and-day-container'>
								<p className='datetime_day'>
									{' '}
									{format(new Date(day.date), 'd MMMM yyyy', {
										locale: nl,
									})}{' '}
								</p>
								<p className='datetime_hour'>
									{hideIcon ? null : (
										<span className='sidebar-icon'>
											<FontAwesomeIcon icon={faClock} />
										</span>
									)}{' '}
									{toTime(day.startTime)} -{' '}
									{toTime(day.endTime)}
								</p>
							</section>
						))
					: days?.map((day, index) => (
							<section key={index}>
								<section className='datetime-hour-and-day-container'>
									<p>
										<span className='datetime_day'>
											{format(
												new Date(day.date),
												'd MMMM yyyy',
												{
													locale: nl,
												},
											)}
										</span>
										<span className='datetime_hour'>
											{hideIcon ? null : (
												<span className='sidebar-icon'>
													<FontAwesomeIcon
														icon={faClock}
													/>
												</span>
											)}{' '}
											{toTime(day.startTime)} -{' '}
											{toTime(day.endTime)}
										</span>
									</p>
								</section>
							</section>
						))}
			</section>
		</section>
	);
};
