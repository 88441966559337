import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadUsers = () => {
	const state = store.getState();
	const { usersLoading, usersFetched } = state.usersReducer;
	if (!usersLoading && !usersFetched) {
		dispatchAction({
			type: ACTION_TYPES.USERS_LOAD,
			payload: { usersLoading: true },
			meta: {
				action: {
					effect: {
						url: `/users`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.USERS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.USERS_LOAD,
						payload: { usersLoading: false },
					},
				},
			},
		});
	}
};

export const loadCocecos = () => {
	const state = store.getState();
	const { cocecosLoading, cocecosFetched } = state.usersReducer;

	if (!cocecosLoading && !cocecosFetched) {
		dispatchAction({
			type: ACTION_TYPES.COCECOS_LOAD,
			payload: { cocecosLoading: true },
			meta: {
				action: {
					effect: {
						url: `/users/coceco`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.COCECOS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.COCECOS_LOAD,
						payload: { cocecosLoading: false },
					},
				},
			},
		});
	}
};
export const loadUser = id => {
	dispatchAction({
		type: ACTION_TYPES.USER_LOAD,
		meta: {
			action: {
				effect: {
					url: `/users/${id}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.USER_LOAD_COMMIT },
				rollback: {
					type: ACTION_TYPES.USER_LOAD,
				},
			},
		},
	});
};
