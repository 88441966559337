import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	faFaceFrown,
	faFaceMeh,
	faFaceSmile,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	loadWellbeingBarometer,
	saveWellBeingBarometer,
} from 'core/store/wellbeing/wellbeing.actions';

import { InputField, InputRadioButton, useForm } from 'components/Form';
import Modal from 'components/Modal/Modal';

import './WellBeingBarometer.scss';

const WellBeingBarometer = () => {
	const [showWellBeingBarometer, setShowWellBeingBarometer] = useState(false);

	const questionsForm = useForm();
	const { wellbeingBarometer } = useSelector(state => state.wellbeingReducer);
	const ref = useRef(null);

	useEffect(() => {
		loadWellbeingBarometer();
		setShowWellBeingBarometer(wellbeingBarometer);
	}, []);

	const handleSubmit = () => {
		if (
			Object.keys(questionsForm.values).every(
				data =>
					questionsForm.values[data] != null &&
					questionsForm.values[data] != '',
			)
		) {
			const data = {
				barometerId: wellbeingBarometer.id,
				answers: Object.keys(questionsForm.values)?.flatMap(answer => ({
					questionId: parseInt(answer.split('_').pop()),
					answer: questionsForm.values[answer],
				})),
			};
			saveWellBeingBarometer(data);
			setShowWellBeingBarometer(false);
		}
	};

	const smileys = [
		{
			value: 'green',
			label: <FontAwesomeIcon className='smile' icon={faFaceSmile} />,
		},
		{
			value: 'orange',
			label: <FontAwesomeIcon className='meh' icon={faFaceMeh} />,
		},
		{
			value: 'red',
			label: <FontAwesomeIcon className='frown' icon={faFaceFrown} />,
		},
	];
	return (
		<Modal
			ref={ref}
			show={!!showWellBeingBarometer}
			title={wellbeingBarometer?.name}
			className='wellbeing-modal'
			cancel={false}
			close={() => setShowWellBeingBarometer(null)}
			cannotClose
			actions={[
				{
					label: 'Verstuur Formulier',
					styles: { marginLeft: '1rem' },
					disabled: !Object.keys(questionsForm.values).every(
						data =>
							questionsForm.values[data] != null &&
							questionsForm.values[data] != '',
					),
					action: handleSubmit,
				},
			]}>
			{wellbeingBarometer?.questions?.map(question => (
				<div
					key={'question_' + question.id}
					className='question-container'>
					<div className='question-container__answers'>
						{question.type == 'M' ? (
							<div className='multiple-choice'>
								<InputRadioButton
									{...questionsForm.register(
										'wellbeing_' + question.id,
										{
											type: 'radio',
											label: question.question,
											required: true,
											options: question.options.flatMap(
												option => ({
													value: parseInt(option.id),
													label: option.option,
												}),
											),
										},
									)}
								/>
							</div>
						) : question.type == 'S' ? (
							<div className='smileys'>
								<InputRadioButton
									{...questionsForm.register(
										'wellbeing_' + question.id,
										{
											type: 'radio',
											label: question.question,
											required: true,
											options: smileys,
										},
									)}
								/>
							</div>
						) : question.type == 'O' ? (
							<div className='textfield'>
								<InputField
									{...questionsForm.register(
										'wellbeing_' + question.id,
										{
											label: question?.question,
											required: true,
										},
									)}
								/>
							</div>
						) : null}
					</div>
				</div>
			))}
		</Modal>
	);
};

export default WellBeingBarometer;
