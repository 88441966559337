import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { findItemPage } from 'core/store/utils/updateState.utils';

import { store } from '../store.config';

export const loadMainFeeds = page => {
	const state = store.getState();
	const { mainFeedsLoading } = state.mainFeedReducer;

	if (!mainFeedsLoading) {
		dispatchAction({
			type: ACTION_TYPES.MAIN_FEEDS_LOAD,
			payload: { mainFeedLoading: true },
			meta: {
				action: {
					effect: {
						url: `/main-feed?page=${page || 1}`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.MAIN_FEEDS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.MAIN_FEEDS_LOAD,
						payload: { mainFeedLoading: false },
					},
				},
			},
		});
	}
};

export const loadMainFeedPageForItemId = itemId => {
	const state = store.getState();
	const { mainFeeds } = state.mainFeedReducer;
	const page = findItemPage(mainFeeds, itemId);

	if (page) {
		loadMainFeeds(page);
	}
};
