import { useEffect, useRef, useState } from 'react';

export const useEvents = (options, initialValue) => {
	const clientY = useRef(0);
	const initialIndex =
		options.findIndex(option => option.value === initialValue) + 1;

	const [selectedIndex, setSelectedIndex] = useState(initialIndex);

	const empty = { value: null, label: '' };
	const allOptions = [empty, ...options, empty];

	const selectNext = () => {
		setSelectedIndex(prev => {
			if (prev < allOptions.length - 2) {
				return prev + 1;
			}
			return prev;
		});
	};

	const selectPrev = () => {
		setSelectedIndex(prev => {
			if (prev > 1 && prev < allOptions.length - 1) {
				return prev - 1;
			}
			return prev;
		});
	};

	const handleScroll = e => {
		e.preventDefault();
		const delta = e.deltaY;
		//if the scroll is down
		if (delta > 0) {
			selectNext();
		} else {
			selectPrev();
		}
	};

	const handleTouchMove = e => {
		// e.preventDefault();
		const delta = e.touches[0].clientY - clientY.current;
		clientY.current = e.touches[0].clientY;
		//if the scroll is down
		if (delta > 0) {
			selectNext();
		} else {
			selectPrev();
		}
	};

	const handleKeydown = e => {
		e.preventDefault();
		const selected = document.querySelector(
			'.select-block__view__option.selected',
		);
		if (e.key === 'ArrowDown') {
			if (document.activeElement !== selected) {
				selected.focus();
			} else {
				selectNext();
			}
		} else if (e.key === 'ArrowUp') {
			selectPrev();
		} else if (e.key === 'Enter') {
			selected?.click();
		}
	};

	useEffect(() => {
		const target = document.querySelector('.select-block');
		target.addEventListener('wheel', handleScroll);
		target.addEventListener('touchmove', handleTouchMove);
		document.addEventListener('keydown', handleKeydown);
		return () => {
			target.removeEventListener('wheel', handleScroll);
			target.removeEventListener('touchmove', handleTouchMove);
			document.removeEventListener('keydown', handleKeydown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { selectedIndex, allOptions };
};
