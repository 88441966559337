import { executeAxios } from 'core/services/actions';

export const getEventForm = async eventId => {
	try {
		const {
			data: { data, status },
		} = await executeAxios({
			url: `/forms/events/${eventId}`,
		});
		if (status === 'success') {
			return data;
		}
	} catch (error) {
		console.log(error);
	}
};
