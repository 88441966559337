import RenderIfVisible from 'components/RenderIfVisible/RenderIfVisible';

import NewsFeedItem from '../NewsFeedItem/NewsFeedItem';

import '../NewsFeed.scss';

const NewsFeedList = ({ newsFeeds }) => {
	return newsFeeds.map(newsFeed => (
		<RenderIfVisible
			placeholderElementClass='card shadow with-stickers with-read-more without-picture main-feed'
			key={newsFeed.id}>
			<NewsFeedItem newsFeed={newsFeed} />
		</RenderIfVisible>
	));
};

export default NewsFeedList;
