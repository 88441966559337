import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	users: [],
	usersLoading: false,
	usersFetched: false,
	cocecos: [],
	cocecosLoading: false,
	cocecosFetched: false,
};

const loadUsers = state => ({
	...state,
	usersLoading: true,
});

const loadUsersCommit = (state, { data }) => {
	return {
		...state,
		users: [...state.users, ...data],

		usersLoading: false,
		usersFetched: true,
	};
};

const loadUser = state => ({
	...state,
	usersLoading: true,
});

const loadUserCommit = (state, { data }) => {
	const userIndex = state.users.findIndex(({ id }) => id === data.id);
	const users = [...state.users];
	users[userIndex] = data;
	return {
		...state,
		users,
		usersLoading: false,
		usersFetched: true,
	};
};

const loadCocecos = state => ({
	...state,
	cocecosLoading: true,
});

const loadCocecosCommit = (state, { data }) => {
	return {
		...state,
		cocecos: [...state.cocecos, ...data],

		cocecosLoading: false,
		cocecosFetched: true,
	};
};

export const usersReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.USERS_LOAD:
			return loadUsers(state, payload);
		case ACTION_TYPES.USERS_LOAD_COMMIT:
			return loadUsersCommit(state, payload);
		case ACTION_TYPES.COCECOS_LOAD:
			return loadCocecos(state, payload);
		case ACTION_TYPES.COCECOS_LOAD_COMMIT:
			return loadCocecosCommit(state, payload);
		case ACTION_TYPES.USER_LOAD:
			return loadUser(state, payload);
		case ACTION_TYPES.USER_LOAD_COMMIT:
			return loadUserCommit(state, payload);
		default:
			return state;
	}
};
