import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { findItemPage } from 'core/store/utils/updateState.utils';

import { store } from '../store.config';

export const loadAllNews = page => {
	const state = store.getState();
	const { newsFeedsLoading } = state.newsFeedReducer;

	if (!newsFeedsLoading) {
		dispatchAction({
			type: ACTION_TYPES.NEWS_FEEDS_LOAD_ALL,
			payload: { newsFeedLoading: true },
			meta: {
				action: {
					effect: {
						url: `/news?page=${page || 1}`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.NEWS_FEEDS_LOAD_ALL_COMMIT },
					rollback: {
						type: ACTION_TYPES.NEWS_FEEDS_LOAD_ALL,
						payload: { newsFeedLoading: false },
					},
				},
			},
		});
	}
};

export const loadNewsDetails = slug => {
	const state = store.getState();
	const { newsFeedDetails } = state.newsFeedReducer;
	const slugToUse = slug || newsFeedDetails?.slug;
	if (slugToUse) {
		dispatchAction({
			type: ACTION_TYPES.NEWS_DETAILS_LOAD,
			meta: {
				action: {
					effect: {
						url: `/news/${slugToUse}`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.NEWS_DETAILS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.NEWS_DETAILS_LOAD,
					},
				},
			},
		});
	}
};

export const loadNewsFeedPageForItemId = itemId => {
	const state = store.getState();
	const { mainFeeds } = state.mainFeedReducer;
	const page = findItemPage(mainFeeds, itemId);

	if (page) {
		loadAllNews(page);
	}
};
