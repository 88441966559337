import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadTrainings = () => {
	const state = store.getState();
	const { trainingsLoading } = state.trainingReducer;
	if (!trainingsLoading) {
		dispatchAction({
			type: ACTION_TYPES.TRAINING_FEEDS_LOAD,
			payload: { trainingsLoading: true },
			meta: {
				action: {
					effect: {
						url: `/trainings`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.TRAINING_FEEDS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.TRAINING_FEEDS_LOAD,
						payload: { trainingsLoading: false },
					},
				},
			},
		});
	}
};

export const loadTraining = slug => {
	const state = store.getState();
	const { trainingDetails } = state.trainingReducer;
	const slugToUse = slug || trainingDetails?.slug;
	if (slugToUse) {
		dispatchAction({
			type: ACTION_TYPES.TRAINING_LOAD,
			meta: {
				action: {
					effect: {
						url: `/trainings/${slugToUse}`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.TRAINING_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.TRAINING_LOAD,
					},
				},
			},
		});
	}
};

export const updateTrainingOrder = newOrder => {
	dispatchAction({
		type: ACTION_TYPES.TRAINING_UPDATE_ORDER,
		payload: newOrder,
	});
};
