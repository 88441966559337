import { toShortDate } from 'core/services/date';
import { SubFeedIconService } from 'core/services/icon';

import Card from 'components/Card/Card';

const SubFeedItem = ({ subFeed }) => (
	<Card
		title={subFeed.title}
		icon={SubFeedIconService(subFeed.type)}
		picture={subFeed.thumbnail}
		url={`/medewerkers/${subFeed.userSlug}`}
		shadowPicture
		withShadow
		stickers={subFeed.stickers}
		addStickers
		objType='subfeed'
		feedType='subFeed'
		objId={subFeed.id}
		className='subfeed-card'
		stickerType='sub_feeds'
		fullSizeLink
		meta={`geplaatst op ${toShortDate(subFeed.createdAt)}`}
	/>
);

export default SubFeedItem;
