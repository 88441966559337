import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadNewsDetails } from 'core/store/newsFeed/newsFeed.actions';

import { DetailPageLayout } from 'components/Layouts/DetailPageLayout/DetailPageLayout';
import Loading from 'components/Loading/Loading';

import './NewsDetail.scss';

const NewsDetail = () => {
	const { slug } = useParams();
	const { newsFeedDetails: newsDetails } = useSelector(
		state => state.newsFeedReducer,
	);

	useEffect(() => {
		if (slug) {
			loadNewsDetails(slug);
		}
	}, [slug]);

	return (
		<section>
			{newsDetails ? (
				<DetailPageLayout
					itemToShow={newsDetails}
					title={newsDetails.title}
					feedDetail={newsDetails}
					creator={`${newsDetails.creator.firstName} ${newsDetails.creator.lastName}`}
					createdAt={newsDetails.publishedAt}
					slug='nieuws'
					type='news'
				/>
			) : (
				<Loading />
			)}
		</section>
	);
};
export default NewsDetail;
