import Lightbox from 'react-18-image-lightbox';
import useDynamicBackgroundImage from 'core/hooks/api/useDynamicBackgroundImage';

import 'react-18-image-lightbox/style.css';
import './LightBox.scss';

export const LightBox = ({
	photoIndex,
	photoIndexNext,
	photoIndexPrev,
	prevImage,
	nextImage,
	setToggler,
}) => {
	let image = useDynamicBackgroundImage(photoIndex);
	let imageNext = useDynamicBackgroundImage(photoIndexNext);
	let imagePrev = useDynamicBackgroundImage(photoIndexPrev);
	let theme = 'light';

	const themeProvider =
		document.getElementsByClassName('ketchup-base')[0].classList;
	if (themeProvider.contains('theme--dark')) {
		theme = 'dark';
	} else {
		theme = 'light';
	}
	return (
		<section className='lightbox' key={photoIndex}>
			<div className='backdrop'>
				<Lightbox
					mainSrc={`data:image/eng;base64,${image}`}
					nextSrc={
						photoIndexNext
							? `data:image/eng;base64,${imageNext}`
							: null
					}
					prevSrc={
						photoIndexPrev
							? `data:image/eng;base64,${imagePrev}`
							: null
					}
					onCloseRequest={() => setToggler(false)}
					onMovePrevRequest={() =>
						photoIndexPrev ? prevImage() : null
					}
					onMoveNextRequest={() =>
						photoIndexNext ? nextImage() : null
					}
					wrapperClassName={theme}
				/>
			</div>
		</section>
	);
};
