import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { createFormData } from 'core/services/form';

export const loadTrainingTypes = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/moving-mission/types',
		});
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		console.log('error', error);
	}
};

export const getData = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/moving-mission',
		});
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		console.log('error', error);
	}
};

export const addTraining = async data => {
	try {
		const {
			data: { data: updatedData },
		} = await executeAxios({
			url: '/moving-mission/1',
			method: 'POST',
			data: createFormData(data),
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		handleAlert('success', 'Training is toegevoegd');
		return updatedData;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		console.log('error', error);
	}
};
