import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLoadItemsOnScroll from 'core/hooks/useLoadItemsOnScroll';
import { loadSubFeeds } from 'core/store/subFeed/subFeed.actions';

import EndOfList from 'components/EndOfList/EndOfList';
import Loading from 'components/Loading/Loading';
import RenderIfVisible from 'components/RenderIfVisible/RenderIfVisible';
import SubFeedItem from 'components/SubFeed/SubFeedItem/SubFeedItem';

import './UserFeed.scss';

const UserFeed = () => {
	const {
		subFeeds,
		subFeedsNextPage,
		subFeedHasNextPage,
		subFeedIsNextPageLoading,
	} = useSelector(state => state.subFeedReducer);

	useEffect(() => {
		if (subFeedHasNextPage && !subFeedIsNextPageLoading) loadSubFeeds(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoadItemsOnScroll(
		subFeedsNextPage,
		subFeedHasNextPage,
		subFeedIsNextPageLoading,
		loadSubFeeds,
		'user-feed',
	);

	return (
		<div id='user-feed' className='user-feed'>
			{subFeeds.map(subFeed => (
				<RenderIfVisible
					placeholderElementClass='card shadow with-stickers with-read-more without-picture main-feed'
					key={subFeed.id}>
					<SubFeedItem subFeed={subFeed} />
				</RenderIfVisible>
			))}

			{subFeedIsNextPageLoading && subFeedHasNextPage ? (
				<Loading />
			) : null}

			{!subFeedHasNextPage ? <EndOfList /> : null}
		</div>
	);
};

export default UserFeed;
