import { handleAlert } from 'core/services/actions/action.service';
import { executeAxios } from 'core/services/actions/axios.service';
import { createFormData } from 'core/services/form';

export const getDamages = async () => {
	try {
		const { data } = await executeAxios({
			url: '/carfleet/damages',
		});
		return data.data;
	} catch (error) {
		console.log(error);
	}
};

export const deleteDamage = async id => {
	try {
		const { status } = await executeAxios({
			url: `/carfleet/damages/${id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Het ongeval is succesvol verwijderd.');
		return status === 204;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const sendDamage = async data => {
	const { id: damageId, ...damageData } = data;
	try {
		const {
			data: { data: report, status },
		} = await executeAxios({
			url: `/carfleet/damages${damageId ? '/' + damageId : ''}`,
			method: damageId ? 'PUT' : 'POST',
			data: createFormData(damageData),
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		if (status === 'success') {
			handleAlert(
				'success',
				`Het ongeval is succesvol ${
					damageId ? 'aangepast' : 'aangemaakt'
				}.`,
			);
			return report;
		}

		return null;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het aanmaken van het ongeval.',
		);
		console.log(error);
		return null;
	}
};

export const deleteFile = async fileId => {
	try {
		const { status } = await executeAxios({
			url: `/carfleet/damages/attachments/${fileId}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Bestand is succesvol verwijderd.');
		return status === 204;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
