import { useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {
	array,
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import UiInput from '../UiInput/UiInput';
import useFormInput from '../useFormInput';

import './Select.scss';

const SelectField = ({
	label = '',
	name,
	value = '',
	validations = [],
	validate,
	errors = [],
	onChange,
	options = [],
	placeholder = '',
	description = '',
	required = false,
	disabled = false,
	className,
	isMulti = false,
	isClearable = true,
	isCreatable = false,
	returnOptionsObj = false,
}) => {
	const { touched, focused, ...handlers } = useFormInput({
		name,
		validate,
		errors,
		onChange,
		validations,
	});

	const [allOptions, setAllOptions] = useState([...options]);

	const SelectComponent = isCreatable ? Creatable : Select;

	return (
		<UiInput
			hasValue={value ? value.length > 0 : null}
			errorMsgs={touched ? errors : []}
			label={label}
			disabled={disabled}
			required={required}
			focused={focused}
			className={`select${className ? className : ''}`}
			description={description}>
			<SelectComponent
				name={name}
				classNamePrefix='select'
				options={allOptions}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				isClearable={isClearable ? !!value : false}
				value={
					!returnOptionsObj
						? (isMulti
								? allOptions.filter(option =>
										value.includes(option.value),
									)
								: allOptions.find(
										option => option.value === value,
									)) || null
						: value
				}
				{...handlers}
				onChange={(option, actionMeta) => {
					if (actionMeta.action === 'clear') {
						onChange('');
						return;
					}

					if (isMulti) {
						onChange(
							option.map(option =>
								!returnOptionsObj ? option.value : option,
							),
						);
					} else {
						if (option.__isNew__) {
							setAllOptions([
								...allOptions,
								{
									value: option.value,
									label: option.label,
								},
							]);
						}
						onChange(!returnOptionsObj ? option.value : option);
					}
				}}
				placeholder={placeholder}
				isDisabled={disabled}
				styles={{
					menuPortal: base => ({
						...base,
						zIndex: 9999999,
						marginLeft: '-0.5rem',
					}),
				}}
				menuPortalTarget={document.getElementById('ketchup-base')}
				menuPosition='absolute'
			/>
		</UiInput>
	);
};

SelectField.propTypes = {
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	value: oneOfType([string, number, array, object]),
	required: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	options: array,
	isMulti: bool,
	isClearable: bool,
	isCreatable: bool,
	returnOptionsObj: bool,
};

export default SelectField;
