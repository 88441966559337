import { useSelector } from 'react-redux';

import './GlasSchade.scss';
export const GlasSchade = () => {
	const { selectedCar } = useSelector(state => state.carReducer);

	return selectedCar?.info?.glassInfo ? (
		<div
			key={selectedCar?.id}
			className='glasschade-container'
			dangerouslySetInnerHTML={{
				__html: selectedCar?.info?.glassInfo?.replace(
					/\r\n/g,
					'<br />',
				),
			}}
		/>
	) : (
		<div>
			We hebben voor jouw wagen geen instructies gevonden. <br />
			Gelieve contact op te nemen met UniPartners voor meer informatie op
			het nummer{' '}
			<a
				href='tel:+32 3
			202 43 10'>
				+32 3 202 43 10
			</a>
			.
		</div>
	);
};
