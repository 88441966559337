import useClickOutside from 'core/hooks/useClickOutside';
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types';

import { Button } from 'components/Form';

import './WorkedHours.scss';

const WorkedHours = ({ onSelect = () => {}, options = [], close }) => {
	const ref = useClickOutside(close, 'mouseup');

	return (
		<div className='worked-hours' ref={ref}>
			{options.map(({ label, value }) => (
				<Button
					className='worked-hours__option'
					buttonStyle='primary'
					key={value}
					onClick={() => onSelect(value)}>
					{label}
				</Button>
			))}
		</div>
	);
};

WorkedHours.propTypes = {
	onSelect: func,
	close: func.isRequired,
	options: arrayOf(
		shape({
			label: string,
			value: oneOfType([string, number]),
		}),
	),
};

export default WorkedHours;
