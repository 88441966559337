import { useState } from 'react';
import { useSubmit } from 'core/hooks/useSubmit';
import { FormValidation } from 'core/services/form';

import { Form } from 'components/Form';
import Modal from 'components/Modal/Modal';

import { makeFormLayout, makeFormObject } from './formModal.helper';

import './FormModal.scss';

const FormModal = ({ close, formData, onSubmit }) => {
	const size = {
		[formData.formSize]: true,
	};

	const [formFields, setFormFields] = useState(
		makeFormObject(formData.fields),
	);

	const handleSubmit = useSubmit(async () => {
		try {
			await onSubmit(formFields);
			close();
		} catch (error) {
			console.error('Error while submitting form', error);
		}
	});

	return (
		<Modal
			show={true}
			close={close}
			{...size}
			title={formData.formName}
			actions={[
				{
					label: 'Verstuur Formulier',
					styles: { marginLeft: '1rem' },
					disabled: !FormValidation(formFields),
					action: handleSubmit,
				},
			]}
			className='form-modal'>
			<Form
				fields={formFields}
				setFields={setFormFields}
				showDefaultButtons={false}
				layout={makeFormLayout(formData)}
				buttons={[]}
			/>
		</Modal>
	);
};

export default FormModal;
