import { bool, func, number, object, string } from 'prop-types';

import { getTimesheetDayTotal } from '../TimeSheetForm/timesheet.helpers';

import TimesheetDay from './TimesheetDay';

const TimesheetItem = ({
	day,
	project,
	index,
	setModal,
	totalRealHours,
	formatState,
	complexViewModal,
	updateDayData,
	deleteDayData,
	simple = false,
	editable = true,
	itemState,
}) => {
	const openModal = () => {
		setModal({
			view: `${complexViewModal ? 'complex_view' : 'simple_view'}`,
			day,
			updateDayData,
			deleteDayData,
			project,
			index,
		});
	};

	const fillDefault = () => {
		updateDayData(index, { duration: day?.defaultDuration }, 'POST');
	};

	const handleClick = () => {
		const restWorkHours =
			(day?.defaultDuration - totalRealHours) * day.reference;

		if (day?.defaultDuration && (!day.allowOvertime || restWorkHours > 0)) {
			fillDefault();
		} else {
			openModal();
		}
	};

	const stateMap = {
		mismatch: openModal,
		complete: openModal,
		blank: handleClick,
		available: handleClick,
		incomplete: openModal,
		unavailable: null,
	};

	const clickAction = stateMap[itemState];

	const totalDayDuration = getTimesheetDayTotal(day);

	return (
		<TimesheetDay
			day={day ? { ...day, duration: totalDayDuration } : { duration: 0 }}
			onClick={clickAction}
			format={formatState}
			itemState={itemState}
			simple={simple}
			editable={
				itemState === 'submitted' || itemState === 'disabled'
					? false
					: editable
			}
		/>
	);
};

TimesheetItem.propTypes = {
	day: object,
	totalBookedHours: number,
	project: object,
	index: number,
	setModal: func,
	formatState: string,
	complexViewModal: bool,
	updateDayData: func,
	simple: bool,
	editable: bool,
};

export default TimesheetItem;
