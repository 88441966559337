import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SecondaryNavigation.scss';

export const SecondaryNavigation = ({ arrayOfTabs, extraClass }) => {
	return (
		<nav
			className={`account-nav-container ${
				extraClass ? extraClass : null
			}`}>
			{arrayOfTabs.map(tab => (
				<NavLink to={tab.path} key={tab.id} className='account-nav-btn'>
					<span className='account-nav-btn__icon'>
						<FontAwesomeIcon icon={tab.icon} />
					</span>
					<span className='account-nav-btn__name'>{tab.name}</span>
				</NavLink>
			))}
		</nav>
	);
};
