import SocialMedia from 'components/SocialMedia/SocialMedia';

const SocialNavigation = () => {
	return (
		<nav>
			<SocialMedia
				socials={[
					{
						id: 1,
						link: 'https://www.facebook.com/UniPartnersNV',
						type: 'Facebook',
					},
					{
						id: 2,
						link: 'https://www.instagram.com/unipartners_belgium/',
						type: 'Instagram',
					},
					{
						id: 3,
						link: 'https://twitter.com/UniPartnersNV',
						type: 'Twitter',
					},
					{
						id: 4,
						link: 'https://www.linkedin.com/company/unipartnersgroup',
						type: 'LinkedIn',
					},
					{
						id: 5,
						link: 'https://www.tiktok.com/@unipartners_consultancy',
						type: 'TikTok',
					},
					{
						id: 6,
						link: 'https://www.youtube.com/channel/UC4PW-r-pIxXlY_Tb3r29zAQ/videos?view=0&sort=da',
						type: 'YouTube',
					},
				]}
			/>
		</nav>
	);
};

export default SocialNavigation;
