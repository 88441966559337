export const getObjectSlug = type => {
	switch (type) {
		case 'users':
			return 'medewerkers';
		case 'events':
			return 'evenementen';
		case 'trainings':
			return 'opleidingen';
		case 'news':
		default:
			return 'nieuws';
	}
};
