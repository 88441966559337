import {
	faFile,
	faFileAlt,
	faFileAudio,
	faFileExcel,
	faFileImage,
	faFilePdf,
	faFilePowerpoint,
	faFileVideo,
	faFileWord,
} from '@fortawesome/free-regular-svg-icons';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

export function getMimeIcon(mime) {
	switch (mime) {
		case 'text/plain':
			return faFileAlt;
		case 'text/uri-list':
			return faGlobeEurope;
		case 'application/xls':
		case 'application/vnd.ms-excel':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return faFileExcel;
		case 'application/msword':
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return faFileWord;
		case 'application/vnd.ms-powerpoint':
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			return faFilePowerpoint;
		case 'application/pdf':
			return faFilePdf;
		case 'audio/mpeg':
			return faFileAudio;
		case 'image/jpeg':
		case 'image/png':
		case 'image/bmp':
		case 'image/gif':
			return faFileImage;
		case 'video/mp4':
		case 'video/wav':
		case 'video/wave':
		case 'video/quicktime':
			return faFileVideo;
		default:
			return faFile;
	}
}
