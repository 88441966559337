import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { updateFeedItems } from 'core/store/utils/updateState.utils';

import { updateStickersOfStickerable } from '../stickers/sticker.utils';

let defaultState = {
	subFeeds: [],
	subFeedsNextPage: 1,
	subFeedHasNextPage: true,
	subFeedIsNextPageLoading: false,
};

const loadSubFeeds = (state, { subFeedLoading }) => ({
	...state,
	subFeedIsNextPageLoading: subFeedLoading,
});

const loadSubFeedsCommit = (state, { data }) => {
	return {
		...state,
		subFeeds: updateFeedItems(state.subFeeds, data),
		subFeedsNextPage: data.hasNextPage ? data.currentPage + 1 : null,
		subFeedHasNextPage: data.hasNextPage,
		subFeedIsNextPageLoading: false,
	};
};

const updateStickersOfSubFeed = updateStickersOfStickerable('subFeed');

export const subFeedReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SUB_FEEDS_LOAD:
			return loadSubFeeds(state, payload);
		case ACTION_TYPES.SUB_FEEDS_LOAD_COMMIT:
			return loadSubFeedsCommit(state, payload);
		case ACTION_TYPES.STICKER_ADD:
			return updateStickersOfSubFeed(state, payload, 'ADD');
		case ACTION_TYPES.STICKER_DELETE:
			return updateStickersOfSubFeed(state, payload, 'DELETE');
		default:
			return state;
	}
};
