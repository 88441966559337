import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const getToppings = () => {
	dispatchAction({
		type: ACTION_TYPES.TOPPINGS_INIT,
		payload: [],
		meta: {
			action: {
				effect: {
					url: `/toppings`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.TOPPINGS_INIT },
				rollback: {},
			},
		},
	});
};
