import { bool, func, node, object, oneOfType, string } from 'prop-types';

import './Button.scss';

const Button = ({
	onClick,
	children,
	label = null,
	type = 'submit',
	buttonStyle,
	buttonSize = null,
	block = false,
	className = null,
	styles = {},
	disabled = false,
	ariaLabel,
}) => {
	const string = ['btn'];

	buttonStyle && string.push(`btn--${buttonStyle}`);
	buttonSize && string.push(`btn--${buttonSize}`);
	block && string.push(`btn--block`);
	className && string.push(className);
	disabled && !string.includes('disabled') && string.push(`disabled`);

	return (
		<button
			type={type}
			onClick={onClick}
			className={string.join(' ')}
			style={styles}
			disabled={disabled}
			aria-label={ariaLabel}>
			{children}
			{label}
		</button>
	);
};

Button.propTypes = {
	type: string,
	label: oneOfType([string, object]),
	onClick: func,
	buttonStyle: string.isRequired,
	buttonSize: string,
	block: bool,
	className: string,
	styles: object,
	ariaLabel: string,
	disabled: bool,
	children: node,
};

export default Button;
