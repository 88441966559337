import { executeAxios } from 'core/services/actions';

export const fetchAllNotifications = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/notifications/archive',
		});
		return data;
	} catch (error) {
		console.log(error);
		return [];
	}
};
