const defaultState = {
	notifications: [],
	notificationsLoading: false,
};

const loadNotifications = (state, { notificationsLoading }) => ({
	...state,
	notificationsLoading,
});

const loadNotificationsCommit = (state, { data }) => {
	return {
		...state,
		notifications: data,
		notificationsLoading: false,
	};
};

const setNotificationsToSeen = state => {
	return {
		...state,
		notifications: [],
		notificationsLoading: false,
	};
};

const clearNotifications = () => {
	return {
		...defaultState,
	};
};

export const notificationReducer = (
	state = defaultState,
	{ type, payload },
) => {
	switch (type) {
		case 'NOTIFICATIONS_LOAD':
			return loadNotifications(state, payload);
		case 'NOTIFICATIONS_LOAD_COMMIT':
			return loadNotificationsCommit(state, payload);
		case 'NOTIFICATIONS_SEEN':
			return setNotificationsToSeen(state);
		case 'NOTIFICATIONS_CLEAR':
			return clearNotifications();
		default:
			return state;
	}
};
