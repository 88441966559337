import React, { useState } from 'react';
import { extractFormData, FormValidation } from 'core/services/form';
import { unflattenObject } from 'core/services/form/extraction/form.extraction';
import {
	bool,
	func,
	number,
	object,
	oneOfType,
	shape,
	string,
} from 'prop-types';

import { Form } from 'components/Form';
import Modal from 'components/Modal/Modal';

import { getInitialFormData, getLayout } from './timesheet.helper';

import './TimesheetModal.scss';

export const TimesheetModal = ({ modal, setModal }) => {
	const { day, project, updateDayData, index, deleteDayData } = modal;
	const [formData, setFormData] = useState(getInitialFormData(project, day));

	const handleSaveButtonClick = () => {
		const extractedData = extractFormData(formData);
		extractedData.wasOnCall =
			extractedData.wasOnCall ||
			extractedData['onCall.workedOnCall'] > 0 ||
			extractedData['onCall.hoursOnCall'] > 0;
		updateDayData(index, unflattenObject(extractedData), 'PUT').then(() =>
			setModal(null),
		);
	};

	const handleDeleteButtonClick = () => {
		const emptyProps = {
			duration: 0,
			...(day.h100 && { h100: 0 }),
			...(day.h150 && { h150: 0 }),
			...(day.h200 && { h200: 0 }),
			realDuration: 0,
			remark: '',
			workedAbroadCountry: '',
			...(day.onCall && { onCall: { workedOnCall: 0, hoursOnCall: 0 } }),
			...(day.mileage.car && { mileage: { car: 0 } }),
			...(day.mileage.bike && { mileage: { bike: 0 } }),
			combinedHours: 0,
		};
		deleteDayData(emptyProps).then(() => setModal(null));
	};

	return (
		<Modal
			show={modal.view === 'complex_view'}
			close={() => setModal(null)}
			title='Werkuren ingeven'
			actions={[
				{
					label: 'Bewaar',
					styles: { marginLeft: '1rem' },
					disabled: !FormValidation(formData),
					action: handleSaveButtonClick,
				},
				{
					label: 'Verwijder',
					styles: { float: 'left' },
					className: 'error',
					action: handleDeleteButtonClick,
				},
			]}>
			<Form
				className='form-container'
				fields={formData}
				setFields={setFormData}
				showDefaultButtons={false}
				layout={getLayout(formData, project)}
				buttons={[]}
			/>
		</Modal>
	);
};

TimesheetModal.propTypes = {
	modal: shape({
		view: string.isRequired,
		day: shape({
			duration: number.isRequired,
			defaultDuration: number.isRequired,
			onCall: oneOfType([bool, object]).isRequired,
			mileage: shape({
				car: number,
				bike: number,
			}).isRequired,
			remark: string,
		}).isRequired,
		updateDayData: func.isRequired,
		index: number.isRequired,
		project: object.isRequired,
	}).isRequired,
	setModal: func.isRequired,
};
