import { useRef, useState } from 'react';
import {
	faEye,
	faEyeSlash,
	faFile,
	faPaperclip,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { executeAxios } from 'core/services/actions';
import { getFileSize } from 'core/services/file';
import { arrayOf, bool, func, number, object, string } from 'prop-types';

import UiInput from '../UiInput/UiInput';

import './Upload.scss';

const Upload = ({
	accept = 'image/png, image/jpeg, application/pdf',
	className,
	description = 'Enkel bestanden kleiner dan 10MB zijn toegelaten',
	disabled = false,
	label = '',
	multiple = false,
	name = '',
	required = false,
	type = 'file',
	maxSize = 10000000,
	value: currentFiles,
	onChange: setCurrentFiles,
	onDelete,
	showPrivacyOptions = false,
	allowDownload,
}) => {
	const ref = useRef(null);
	const [error, setError] = useState(null);

	const id = name
		.toLowerCase()
		.split(' ')
		.map(word => word.replace(/[^a-z]+/g, ''))
		.join('-');

	const handleAdd = e => {
		const { files } = e.target;
		if ([...files].some(file => file.size > maxSize)) {
			setError(
				`Bestand is te groot. Kies een bestand kleiner dan ${
					maxSize / 1000000
				}MB.`,
			);
			e.target.value = null;
			return;
		} else if (
			[...files].some(file => accept && !accept.includes(file.type))
		) {
			setError('Bestandstype is niet toegelaten.');
			e.target.value = null;
			return;
		} else {
			setError(null);
		}
		const updatedFilesArray = multiple
			? [...currentFiles, ...files]
			: [...files];
		setCurrentFiles(updatedFilesArray);
	};

	const handleRemove = async (e, removedFile) => {
		try {
			e.preventDefault();
			if (onDelete && !removedFile.size) {
				await onDelete(removedFile);
			}

			const findIndex = currentFiles.findIndex(
				file => file.name === removedFile.name,
			);

			const filteredFilesArray = currentFiles.filter(
				(_, index) => index !== findIndex,
			);

			const list = new DataTransfer();

			filteredFilesArray.forEach(
				file => typeof file === File && list.items.add(file),
			);
			ref.current.files = list.files;

			setCurrentFiles(filteredFilesArray);
		} catch (error) {
			console.error(error);
		}
	};

	const handleRemoveAll = () => {
		setCurrentFiles([]);
		ref.current.files = new DataTransfer().files;
	};

	const handleFileClick = async fileData => {
		if (allowDownload) {
			let file = fileData;
			try {
				if (file.dataId) {
					const { data } = await executeAxios({
						url: `/data/${file.dataId}`,
						responseType: 'blob',
					});
					file = new File([data], file.name, { type: file.type });
				}
				const url = URL.createObjectURL(file);
				const a = document.createElement('a');
				a.href =
					typeof file === 'string' ? file : URL.createObjectURL(file);
				a.download = file.name;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const changeFileVisibility = index => {
		setCurrentFiles(
			[...currentFiles].map((file, i) => {
				const newFile = new File([file], file.name, {
					type: file.type,
				});

				newFile.shareable =
					i === index ? !file.shareable : file.shareable;
				return newFile;
			}),
		);
	};

	return (
		<>
			<UiInput
				className={className}
				isFileUpload
				errorMsgs={error ? [error] : []}
				id={id}
				label={label}
				description={description}
				required={required}
				disabled={disabled}>
				<input
					id={id}
					ref={ref}
					className='form-field__input'
					type={type}
					accept={accept}
					multiple={multiple}
					disabled={disabled}
					onChange={handleAdd}
				/>
				<span
					className={`form-field__label${
						disabled ? ' form-field__label--disabled' : ''
					}`}>
					<div className='form-field__icon'>
						<FontAwesomeIcon
							icon={faPaperclip}
							size='lg'
							fixedWidth
						/>
					</div>
					<span>Bestand(en) toevoegen</span>
				</span>
				<span
					className={`form-field__sub-label${
						disabled ? ' form-field__sub-label--disabled' : ''
					}`}>
					Sleep bestand(en) naar hier
				</span>
			</UiInput>
			{currentFiles.length !== 0 ? (
				<div className='form-field__upload-file-section'>
					{currentFiles.map((file, index) => (
						<div className='form-field__upload-file' key={index}>
							<div
								className={`form-field__upload-file-label${
									allowDownload
										? ' form-field__upload-file-label--clickable'
										: ''
								}`}
								onClick={() => handleFileClick(file)}>
								<FontAwesomeIcon icon={faFile} fixedWidth />
								<span>
									{file.name}
									{file.size
										? ` (${getFileSize(file.size)})`
										: ''}
								</span>
							</div>
							<div className='form-field__upload-file__buttons'>
								{showPrivacyOptions ? (
									<span
										className='form-field__upload-file__extra'
										onClick={() =>
											changeFileVisibility(index)
										}>
										{file.shareable ? (
											<FontAwesomeIcon icon={faEye} />
										) : (
											<FontAwesomeIcon
												icon={faEyeSlash}
											/>
										)}
									</span>
								) : null}
								<button
									className='form-field__upload-file-remove-button'
									onClick={e => handleRemove(e, file)}>
									<FontAwesomeIcon
										icon={faTimes}
										fixedWidth
									/>
								</button>
							</div>
						</div>
					))}
					<div className='form-field__upload-buttons'>
						<button
							className='form-field__upload-remove-all-button'
							onClick={handleRemoveAll}>
							<FontAwesomeIcon icon={faTrash} fixedWidth />
							<span>Verwijder alles</span>
						</button>
					</div>
				</div>
			) : null}
		</>
	);
};

Upload.propTypes = {
	accept: string,
	description: string,
	disabled: bool,
	maxSize: number,
	label: string,
	multiple: bool,
	name: string.isRequired,
	required: bool,
	type: string,
	value: arrayOf(object),
	onChange: func.isRequired,
	onDelete: func,
	allowDownload: bool,
	showPrivacyOptions: bool,
};

export default Upload;
