import { parseTimeToNumber } from 'core/services/parse';

export const isValidRange = (value, label) => {
	const [from = '', until = ''] = value;
	const fromNumber = parseTimeToNumber(from);
	const untilNumber = parseTimeToNumber(until);
	const isValid = fromNumber <= untilNumber;
	return !isFilled(value) || isValid ? '' : `The ${label} range is not valid`;
};

const isFilled = value => {
	const [from, until] = value;
	return !!from && !!until;
};

export const isRangeRequired = (value, label) => {
	return Array.isArray(value) && isFilled(value)
		? ''
		: `${label} is een verplicht veld`;
};
