import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

let defaultState = {
	mode: 'auto',
	theme: darkThemeMediaQuery.matches ? 'dark' : 'light',
};

const changeTheme = (state, mode) => ({
	...state,
	theme:
		mode === 'auto'
			? darkThemeMediaQuery.matches
				? 'dark'
				: 'light'
			: mode,
});

const changeMode = (state, mode) => ({
	...state,
	mode: mode.data ? mode.data.theme : mode.theme,
});

export const applicationReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.THEME_UPDATE:
			return changeTheme(state, payload);
		case ACTION_TYPES.THEME_MODE_UPDATE:
			return changeMode(state, payload);
		default:
			return state;
	}
};
