import { toShortDate } from 'core/services/date';

import {
	InputCheckbox,
	InputField,
	InputRadioButton,
	useForm,
} from 'components/Form';
import Modal from 'components/Modal/Modal';

import { createIllness, parseErrors } from '../sickness.helpers';

import './SicknessExtraInfoModal.scss';

const SicknessExtraInfoModal = ({ modal, setModal }) => {
	const {
		extraInfoNeeded,
		illness,
		addIllness,
		addIllnessToIllnessList,
		resetForm,
	} = modal;

	const form = useForm();

	const parsedExtraInfo = parseErrors(extraInfoNeeded);

	const sendIllness = async () => {
		const updatedIllness = createIllness(
			illness,
			parsedExtraInfo,
			form.values,
		);

		setModal(null);

		const addedIllness = await addIllness(updatedIllness);

		if (addedIllness) {
			addIllnessToIllnessList(addedIllness);
			resetForm();
		}
	};

	return (
		<Modal
			show={!!modal}
			title='Extra info'
			close={() => setModal(null)}
			actions={[
				{
					label: 'Bewaar',
					styles: { marginLeft: '1rem' },
					disabled: false,
					action: sendIllness,
				},
			]}>
			<div className='sickness-extra-info-modal'>
				{parsedExtraInfo.needsAction?.length ? (
					<div>
						<h2>Actions</h2>
						{parsedExtraInfo.needsAction?.map(action => (
							<div key={action.date}>
								{action.timeToClear ? (
									<p>
										Op{' '}
										<strong>
											{toShortDate(action.date)}
										</strong>{' '}
										moet je{' '}
										<strong>{action.timeToClear}u</strong>{' '}
										vrij maken. Vul bij elke optie in
										hoeveel uur je wilt verwijderen
									</p>
								) : null}
								{action.currentDurations?.map(duration =>
									duration.recover?.length > 0 ? (
										duration.recover.map(day => (
											<InputCheckbox
												{...form.register(
													`recover-${action.date}_${day.type}_${day.id}`,
													{
														label: `Herstel ${
															day.duration
														}u op ${toShortDate(
															action.date,
														)}`,
														value: 1,
													},
												)}
											/>
										))
									) : action.timeToClear ? (
										<InputField
											{...form.register(
												`${action.date}_${duration.type}_${duration.id}`,
												{
													label: `${
														duration.type ===
														'absences'
															? 'Afwezigheid'
															: `Werk op ${duration.project}`
													} van ${
														duration.duration
													} uur`,
													type: 'number',
													required: true,
													value: action
														.currentDurations[0]
														?.duration,
												},
											)}
										/>
									) : null,
								)}
							</div>
						))}
					</div>
				) : null}
				{parsedExtraInfo.possibleRelations?.length ? (
					<div>
						Is deze ziektemelding gerelateerd aan een van de
						onderstaande meldingen?
						<InputRadioButton
							{...form.register('relatedIllness', {
								options: [
									...parsedExtraInfo.possibleRelations.map(
										relation => ({
											label: `${toShortDate(
												relation?.period[0].date,
											)} - ${toShortDate(
												relation?.period[
													relation?.period.length - 1
												].date,
											)}`,
											value: relation.id,
										}),
									),
									{
										label: 'Nee',
										value: '',
									},
								],
								required: true,
								type: 'radio',
								value: '',
							})}
						/>
					</div>
				) : null}
			</div>
		</Modal>
	);
};

export default SicknessExtraInfoModal;
