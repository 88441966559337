import { bool, node, number, object, string } from 'prop-types';

const Col = props => {
	let string = [];
	props.xs && string.push('col-xs-' + props.xs);
	props.sm && string.push('col-sm-' + props.sm);
	props.md && string.push('col-md-' + props.md);
	props.lg && string.push('col-lg-' + props.lg);
	props.xl && string.push('col-xl-' + props.xl);

	props.xsOrder && string.push('order-xs-' + props.xsOrder);
	props.smOrder && string.push('order-sm-' + props.smOrder);
	props.mdOrder && string.push('order-md-' + props.mdOrder);
	props.lgOrder && string.push('order-lg-' + props.lgOrder);
	props.xlOrder && string.push('order-xl-' + props.xlOrder);

	props.xsHide && string.push('d-sx-none');
	props.smHide && string.push('d-sm-none');
	props.mdHide && string.push('d-md-none');
	props.lgHide && string.push('d-lg-none');
	props.xlHide && string.push('d-xl-none');

	props.xsShow && string.push('d-sx-block');
	props.smShow && string.push('d-sm-block');
	props.mdShow && string.push('d-md-block');
	props.lgShow && string.push('d-lg-block');
	props.xlShow && string.push('d-xl-block');

	props.xsAuto && string.push('col-xs-auto');
	props.smAuto && string.push('col-sm-auto');
	props.mdAuto && string.push('col-md-auto');
	props.lgAuto && string.push('col-lg-auto');
	props.xlAuto && string.push('col-xl-auto');

	props.className && string.push(props.className);

	return (
		<div className={string.join(' ')} style={props.style}>
			{props.children}
		</div>
	);
};

Col.propTypes = {
	xs: number,
	sm: number,
	md: number,
	lg: number,
	xl: number,
	xsOrder: number,
	smOrder: number,
	mdOrder: number,
	lgOrder: number,
	xlOrder: number,
	xsHide: bool,
	smHide: bool,
	mdHide: bool,
	lgHide: bool,
	xlHide: bool,
	xsShow: bool,
	smShow: bool,
	mdShow: bool,
	lgShow: bool,
	xlShow: bool,
	xsAuto: bool,
	smAuto: bool,
	mdAuto: bool,
	lgAuto: bool,
	xlAuto: bool,
	className: string,
	style: object,
	children: node,
};

export default Col;
