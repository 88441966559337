import {
	faFacebookF,
	faFacebookSquare,
	faInstagram,
	faInstagramSquare,
	faLinkedin,
	faSquareXTwitter,
	faTiktok,
	faXTwitter,
	faYoutube,
	faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

/**
 * @param {string} type
 * @param {boolean} square
 * @returns {IconDefinition}
 * @constructor
 */
export const SocialMediaIconService = (type, square = false) => {
	let icon;

	switch (type) {
		case 'Twitter':
			icon = square ? faSquareXTwitter : faXTwitter;
			break;
		case 'LinkedIn':
			icon = faLinkedin;
			break;
		case 'Instagram':
			icon = square ? faInstagramSquare : faInstagram;
			break;
		case 'YouTube':
			icon = square ? faYoutubeSquare : faYoutube;
			break;
		case 'TikTok':
			icon = faTiktok;
			break;
		case 'Facebook':
		default:
			icon = square ? faFacebookSquare : faFacebookF;
			break;
	}

	return icon;
};
