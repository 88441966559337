export const createUpdateBody = values => {
	const { biography, facebook, twitter, tiktok, linkedin, instagram } =
		values;

	const socials = [
		...(facebook && [{ type: 'facebook', link: facebook }]),
		...(twitter && [{ type: 'twitter', link: twitter }]),
		...(tiktok && [{ type: 'tiktok', link: tiktok }]),
		...(linkedin && [{ type: 'linkedin', link: linkedin }]),
		...(instagram && [{ type: 'instagram', link: instagram }]),
	];

	const interview = Object.keys(values).reduce((acc, key) => {
		if (key.includes('question')) {
			const questionId = key.split('-')[1];
			const answer = values[key];

			return [...acc, { questionId, answer }];
		}

		return acc;
	}, []);

	return {
		bio: biography,
		socials,
		interview,
	};
};

export const findAnswer = (interview, questionId) => {
	const interviewItem = interview.find(
		answer => answer.questionId === questionId,
	);

	return interviewItem ? interviewItem.answer : '';
};

export const findSocialMedia = (socialMedia, socialMediaType) => {
	const socialMediaItem = socialMedia.find(
		socialMedia =>
			socialMedia.type.toLowerCase() === socialMediaType.toLowerCase(),
	);

	return socialMediaItem ? socialMediaItem.link : '';
};
