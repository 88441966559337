import { NavLink } from 'react-router-dom';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DisplayOrganisors.scss';

//Geeft organisors weer.
//Indien er geen zijn wordt er centraal 'Geen organisatoren' weergegeven.
export const DisplayOrganisors = ({ organisors }) => {
	return (
		<section>
			{organisors.length >= 1 ? (
				<section className='organisors-container'>
					<span className='sidebar-icon'>
						<FontAwesomeIcon icon={faUsers} />
					</span>
					<section>
						{organisors.map(organisor => (
							<NavLink
								key={organisor.id}
								className='organisors'
								to={`/medewerkers/${organisor.id}`}>
								{organisor.firstName} {organisor.lastName}
							</NavLink>
						))}
					</section>
				</section>
			) : (
				<section className='organisors-container'>
					<p className='no-organisors'> Geen organisatoren</p>
				</section>
			)}
		</section>
	);
};
