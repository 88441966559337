import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadWellbeingBarometer = () => {
	const state = store.getState();
	const { wellbeingLoading } = state.wellbeingReducer;
	if (!wellbeingLoading) {
		dispatchAction({
			type: ACTION_TYPES.WELLBEING_LOAD,
			payload: { wellbeingLoading: true },
			meta: {
				action: {
					effect: {
						url: `/well-being`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.WELLBEING_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.WELLBEING_LOAD,
						payload: { wellbeingLoading: false },
					},
				},
			},
		});
	}
};

export const saveWellBeingBarometer = data => {
	dispatchAction({
		type: ACTION_TYPES.WELLBEING_POST,
		meta: {
			action: {
				effect: {
					url: `/well-being`,
					method: 'post',
					body: data,
				},
				rollback: {
					type: ACTION_TYPES.WELLBEING_LOAD_COMMIT,
					payload: { data: data },
				},
			},
		},
	});
};

export const deleteWellBeingBarometer = id => {
	dispatchAction({
		type: ACTION_TYPES.WELLBEING_DELETE,
		payload: { id },
	});
};
