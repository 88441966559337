import Dexie from 'dexie';

export const db = new Dexie('ketchup-db');
db.version(1).stores({
	//auth: '$$oid,token,authId,authPicture,role,permissions,contacts,authLoading,loggedIn',
	//application: 'mode,theme'
	dispatchActions: '$$oid,type,payload,meta',
	dispatchMedia: '$$oid,uuid,type,thumb',
	media: '[uuid+type],loading,loaded,description,filename,mime,file',
});
