/**
 * @param {object} fields
 * @returns {object}
 * @constructor
 */
export const extractFormData = fields => {
	let obj = {};

	Object.keys(fields).map(fieldName => {
		const field = fields[fieldName];
		obj[field.name] = field.value;
		if (field.type === 'number') {
			obj[field.name] = field.value ? parseFloat(obj[field.name]) : 0;
		}
		return null;
	});

	return obj;
};

// a function that loops over a nested objects and returns a flat object with combined keys
export const flattenObject = (obj = {}, prefix = '') => {
	return Object.keys(obj).reduce((acc, k) => {
		const pre = prefix.length ? prefix + '.' : '';
		if (typeof obj[k] !== 'object' || obj[k] === null) {
			acc[pre + k] = obj[k];
		} else {
			Object.assign(acc, flattenObject(obj[k], pre + k));
		}
		return acc;
	}, {});
};

// convert a flatten object back to a nested object
export const unflattenObject = data => {
	const result = {};
	Object.keys(data).forEach(key => {
		const keys = key.split('.');
		keys.reduce((acc, current, index) => {
			if (index === keys.length - 1) {
				acc[current] = data[key];
			} else if (!acc[current]) {
				acc[current] = {};
			}
			return acc[current];
		}, result);
	});
	return result;
};
