import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import useLoader from 'core/hooks/useLoader';
import { useSubmit } from 'core/hooks/useSubmit';
import { localDate, toShortDate } from 'core/services/date';

import {
	Button,
	Datepicker,
	InputCheckbox,
	InputField,
	Textarea,
	Upload,
	useForm,
} from 'components/Form';
import { Col, Container, Row } from 'components/Grid';
import Loader from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import Warning from 'components/Warning/Warning';

import InfoCard from '../Timesheets/InfoCard/InfoCard';

import SicknessExtraInfoModal from './SicknessModals/SicknessExtraInfoModal';
import SicknessHoursModal from './SicknessModals/SicknessHoursModal';
import {
	createNewIllness,
	emailField,
	getDisabledDates,
	getDisabledFields,
	getReportStatus,
	oneDaySick,
	remark,
	selectedDates,
	sendEmail,
	sortByDate,
	uploadField,
} from './sickness.helpers';
import {
	addIllness,
	deleteIllness,
	deleteIllnessFile,
	getAllIllnesses,
} from './sickness.service';

import './ReportSickness.scss';

export const ziekMelden_subText_example = [
	'Wanneer je ziek bent en niet kunt gaan werken, verwittig ons dan door onderstaand formulier in te vullen. Vergeet niet om ook je eindklant op de hoogte te brengen. Ben je nog niet naar de dokter geweest? Geen probleem. Vul de datum in vanaf wanneer je afwezig bent. De rest kun je later invullen, nadat je bij de dokter bent geweest. Doe dit echter altijd binnen 48 uur.',
];

const ReportSickness = () => {
	const [illnessList, setIllnessList] = useState([]);
	const [lockBefore, setLockBefore] = useState(null);
	const [showHoursModal, setShowHoursModal] = useState(false);
	const [extraInfoModal, setExtraInfoModal] = useState(null);
	const [dateHours, setDateHours] = useState({});
	const [editIllness, setEditIllness] = useState(null);
	const [errors, setErrors] = useState([]);
	const [isListLoading, setIsListLoading] = useState(true);
	const [showDateChangeWarning, setShowDateChangeWarning] = useState(false);
	const { isLoading } = useLoader('illnesses');
	const { id } = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const pageBaseUrl = `/${pathname
		.split('/')
		.filter(part => Number.isNaN(+part))
		.join('/')}`;

	useEffect(() => {
		getAllIllnesses().then(({ illnesses, lockBefore }) => {
			setIllnessList(
				illnesses.map(illness => ({
					...illness,
					status: getReportStatus(illness),
				})),
			);
			setLockBefore(lockBefore);
			setIsListLoading(false);
		});
	}, []);

	const { register, reset, values, getValue, setValue, isDirty, isValid } =
		useForm({
			onFormChange: ({ dates }) => {
				if (editIllness) {
					const datesChanged = dates.some(
						date =>
							!editIllness.period.find(
								period => period.date === date,
							),
					);
					if (datesChanged) {
						setShowDateChangeWarning(true);
					}
				}
			},
		});

	const sendIllness = async newIllness => {
		const { illness, extraInfoNeeded } = await addIllness(newIllness);

		if (extraInfoNeeded) {
			// If there are no errors, but the illness is not created,
			// we need to show the extra info modal
			// where the user can fill in the missing info
			setExtraInfoModal({
				extraInfoNeeded,
				illness: newIllness,
				addIllness: sendIllness,
				addIllnessToIllnessList,
				resetForm: reset,
			});

			return null;
		}

		return illness;
	};

	const addIllnessToIllnessList = illness => {
		setIllnessList(prev => {
			return [...prev.filter(({ id }) => id !== illness.id), illness];
		});
		setEditIllness(null);
	};

	const handleSubmit = useSubmit(async () => {
		const newIllness = createNewIllness(values, editIllness, dateHours);
		const createdIllness = await sendIllness(newIllness);
		if (createdIllness) {
			addIllnessToIllnessList({
				...createdIllness,
				status: getReportStatus(createdIllness),
			});
			reset();
			setShowDateChangeWarning(false);
			navigate(pageBaseUrl);
		}
	});

	const handleDelete = async id => {
		const success = await deleteIllness(id);
		if (!success) return;
		setIllnessList(prev => {
			return prev.filter(illness => illness.id !== id);
		});
	};

	const handleChange = id => {
		if (editIllness) {
			addIllnessToIllnessList(editIllness);
		}
		const illness = illnessList.find(illness => illness.id === id);
		setIllnessList(prev => {
			return prev.filter(illness => illness.id !== id);
		});
		setEditIllness(illness);

		setDateHours(
			illness?.period.reduce(
				(acc, period) => ({ ...acc, [period.date]: period.duration }),
				{},
			),
		);

		setValue('oneDaySick', illness.hasNotes === 0);
		setValue(
			'dates',
			illness?.period.map(period => period.date),
		);
		setValue('remark', illness?.remark || '');
		setValue(
			'attachments',
			illness?.attachments.map(file => ({
				id: file.id,
				name: file.description,
				type: file.mime,
				dataId: file.filename,
			})) || [],
		);
	};

	const handleDeleteFile = async file => {
		const deleted = await deleteIllnessFile(file.id);
		if (deleted) {
			setEditIllness(prev => {
				return {
					...prev,
					attachments: prev.attachments.filter(
						attachment => attachment.id !== file.id,
					),
				};
			});
		}
	};

	const handleCancel = () => {
		if (editIllness) {
			addIllnessToIllnessList(editIllness);
		}
		reset();
		setEditIllness(null);
		setShowDateChangeWarning(false);
		navigate(pageBaseUrl);
	};

	useEffect(() => {
		if (id && !isListLoading && illnessList.length > 0) {
			const illness = illnessList.find(illness => illness.id === +id);
			if (illness) {
				handleChange(illness.id);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isListLoading]);

	const disabledFields = editIllness ? getDisabledFields(editIllness) : [];

	return isListLoading ? (
		<Loader />
	) : illnessList ? (
		<section className='sickness'>
			<section className='sickness-reports'>
				{editIllness ? null : (
					<section className='sickness-reports__items'>
						{illnessList
							.sort(sortByDate)
							.map(({ id, period, status }) =>
								period[0] ? (
									<InfoCard
										key={period[0]?.date}
										handleChange={() =>
											navigate(`${pageBaseUrl}/${id}`)
										}
										changeDisabled={status === 'completed'}
										deleteDisabled={[
											'completed',
											'uncompleted',
										].includes(status)}
										handleDelete={() => handleDelete(id)}
										infoIcon={faNotesMedical}
										title={localDate(period[0]?.date)}
									/>
								) : null,
							)}
					</section>
				)}
				<section className='sickness-reports__info'>
					{ziekMelden_subText_example}
				</section>
			</section>
			<section className='sickness-form'>
				<form>
					<Container>
						<Row>
							<Col xl={5} lg={12}>
								<Datepicker
									{...register('dates', selectedDates)}
									disabledDays={
										editIllness
											? getDisabledDates(editIllness)
											: [{ before: new Date(lockBefore) }]
									}
									mode={
										getValue('oneDaySick') //|| editIllness
											? 'single'
											: 'range'
									}
								/>
								{getValue('dates')?.length ? (
									<div className='sickness-form__extra'>
										<span className='sickness-form__dates'>
											<span>Je bent afwezig vanaf</span>
											<br />
											<span className='sickness-form__date'>
												{toShortDate(
													getValue('dates')[0],
												)}
											</span>
											<span>tot</span>
											<span className='sickness-form__date'>
												{toShortDate(
													getValue('dates')[
														getValue('dates')
															.length - 1
													],
												)}
											</span>
										</span>
										<span
											className='sickness-form__hours'
											onClick={() =>
												setShowHoursModal(true)
											}>
											Wijzig het aantal uren
										</span>
									</div>
								) : null}
							</Col>
							<Col xl={7} lg={12}>
								{getValue('dates')?.length > 1 ? null : (
									<InputCheckbox
										{...register('oneDaySick', oneDaySick)}
										disabled={disabledFields.includes(
											'oneDaySick',
										)}
									/>
								)}
								{!editIllness ? (
									<InputCheckbox
										{...register('sendEmail', sendEmail)}
									/>
								) : null}
								{getValue('sendEmail') ? (
									<InputField
										{...register('clientEmail', emailField)}
										disabled={disabledFields.includes(
											'clientEmail',
										)}
									/>
								) : null}
								{showDateChangeWarning ? (
									<Warning>
										<strong>Opgelet!</strong>
										<br /> Je hebt je ziekteperiode
										aangepast. Vergeet niet je eindklant van
										deze wijziging op de hoogte te brengen.
									</Warning>
								) : null}
							</Col>
						</Row>
						<Row>
							{!getValue('oneDaySick') ? (
								<Col xl={6} lg={12}>
									<Upload
										{...register('attachments', {
											...uploadField,
											onDelete: handleDeleteFile,
										})}
										disabled={disabledFields.includes(
											'attachments',
										)}
										allowDownload={
											!disabledFields.includes('download')
										}
									/>
								</Col>
							) : null}
							<Col xl={6} lg={12}>
								<Textarea
									{...register('remark', remark)}
									disabled={disabledFields.includes('remark')}
								/>
							</Col>
						</Row>
						<Row>
							<Col className='sickness-form__submit'>
								{isDirty() ? (
									<Button
										buttonStyle='secondary'
										onClick={handleCancel}>
										Annuleren
									</Button>
								) : null}
								<Button
									disabled={!isDirty() || !isValid()}
									buttonStyle='primary'
									onClick={handleSubmit}>
									Bewaren
								</Button>
							</Col>
						</Row>
					</Container>
				</form>
				{isLoading ? (
					<div className='sickness__loader'>
						<Loader centered />
					</div>
				) : null}
				{extraInfoModal ? (
					<SicknessExtraInfoModal
						modal={extraInfoModal}
						setModal={setExtraInfoModal}
					/>
				) : null}
				{showHoursModal && getValue('dates').length > 0 ? (
					<SicknessHoursModal
						showModal={showHoursModal}
						setShowModal={setShowHoursModal}
						dates={getValue('dates')}
						dateHours={dateHours}
						disabled={editIllness?.period.every(
							period => period.approved,
						)}
						setDateHours={setDateHours}
					/>
				) : null}
				{errors.length ? (
					<Modal
						show={errors.length > 0}
						close={() => setErrors([])}
						title='Errors'>
						{errors.map((error, i) => (
							<p key={i}>{error}</p>
						))}
					</Modal>
				) : null}
			</section>
		</section>
	) : (
		<div className='timesheet__message'>
			Er is iets misgegaan. Probeer later opnieuw.
		</div>
	);
};

export default ReportSickness;
