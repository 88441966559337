import { useSelector } from 'react-redux';
import {
	faBuilding,
	faDownload,
	faEnvelope,
	faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { downloadFile } from 'core/services/file';

import './MijnWagen.scss';

export const MijnWagen = () => {
	const { selectedCar } = useSelector(state => state.carReducer);

	const isObjEmpty = obj => {
		return obj ? Object.keys(obj).length === 0 : false;
	};

	const handleClick = async insurance => {
		try {
			downloadFile(insurance.documentId, null, insurance.filename);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<section>
			<section className='wagen-container'>
				{(!isObjEmpty(selectedCar?.leasing) &&
					selectedCar?.leasing?.name) ||
				(!isObjEmpty(selectedCar?.insurance) &&
					selectedCar?.insurance?.company) ? (
					<section className='wagen-leasing-and-insurance'>
						{!isObjEmpty(selectedCar?.leasing) &&
						selectedCar?.leasing?.name ? (
							<div className='wagen-leasing'>
								<h2>Leasingmaatschappij</h2>
								<p>{selectedCar?.leasing.name}</p>

								{selectedCar?.leasing.startDate ? (
									<div>
										<p className='wagen-leasing-label'>
											Startdatum:
										</p>
										<p className='wagen-leasing-data'>
											{toShortDate(
												selectedCar?.leasing.startDate,
											)}
										</p>
									</div>
								) : null}
								{selectedCar?.leasing.endDate ? (
									<div>
										<p className='wagen-leasing-label'>
											Einddatum:
										</p>
										<p className='wagen-leasing-data'>
											{toShortDate(
												selectedCar?.leasing.endDate,
											)}
										</p>
									</div>
								) : null}
							</div>
						) : null}
						{!isObjEmpty(selectedCar?.insurance) &&
						(selectedCar?.insurance?.company ||
							selectedCar?.insurance?.contractNumber.length >
								0) ? (
							<div className='wagen-insurance'>
								<h2>Verzekering</h2>
								<p>{selectedCar?.insurance.company}</p>
								{selectedCar?.insurance?.email ? (
									<p>
										<span>
											<FontAwesomeIcon
												icon={faEnvelope}
												className='label-icon'
												fixedWidth
											/>
										</span>
										{selectedCar?.insurance.email}
									</p>
								) : null}

								{selectedCar?.insurance?.telephone ? (
									<p>
										<span>
											<FontAwesomeIcon
												icon={faPhone}
												className='label-icon'
												fixedWidth
											/>
										</span>
										{selectedCar?.insurance?.telephone}
									</p>
								) : null}

								<p>{selectedCar?.insurance?.contractNumber}</p>
							</div>
						) : null}

						{selectedCar?.insurance?.greenCard?.documentId ? (
							<button
								onClick={() =>
									handleClick(
										selectedCar?.insurance?.greenCard,
									)
								}
								className='btn btn--primary'>
								<FontAwesomeIcon icon={faDownload} />
								Verzekeringsbewijs
							</button>
						) : null}

						<div className='icon-wrapper'>
							<FontAwesomeIcon
								icon={faBuilding}
								className='building-icon'
								fixedWidth
							/>
						</div>
					</section>
				) : null}
				<section className='wagen-item-container'>
					<h2>
						{selectedCar?.brand} {selectedCar?.type}
					</h2>
					<section className='wagen-item'>
						{selectedCar?.licencePlate ? (
							<div>
								<p className='wagen-label'>Nummerplaat:</p>
								<p className='wagen-data'>
									{selectedCar?.licencePlate}
								</p>
							</div>
						) : null}
						{selectedCar?.chassis ? (
							<div>
								<p className='wagen-label'>Chassisnummer:</p>
								<p className='wagen-data'>
									{selectedCar?.chassis}
								</p>
							</div>
						) : null}
					</section>
					{!isObjEmpty(selectedCar?.technical) ? (
						<section className='wagen-item'>
							<h3>Technische Informatie</h3>
							{selectedCar?.technical?.cci ? (
								<div>
									<p className='wagen-label'>
										Cilinderinhoud:
									</p>
									<p className='wagen-data'>
										{selectedCar?.technical?.cci}
									</p>
								</div>
							) : null}

							{selectedCar?.technical?.kw ||
							selectedCar?.technical?.pw ? (
								<div>
									<p className='wagen-label'>Vermogen:</p>
									<p className='wagen-data'>
										{selectedCar?.technical?.kw} kw /{' '}
										{selectedCar?.technical?.pk} pk
									</p>
								</div>
							) : null}
							{selectedCar?.technical?.co2 ? (
								<div>
									<p className='wagen-label'>Uitstoot:</p>
									<p className='wagen-data'>
										{selectedCar?.technical?.co2} gram
									</p>
								</div>
							) : null}
						</section>
					) : null}
					{!isObjEmpty(selectedCar?.technical?.tyres) ? (
						<section className='wagen-item'>
							<h3>Bandeninformatie</h3>

							{selectedCar?.technical?.tyres?.tyreBrand ? (
								<div>
									<p className='wagen-label'>Merk:</p>
									<p className='wagen-data'>
										{
											selectedCar?.technical?.tyres
												?.tyreBrand
										}
									</p>
								</div>
							) : null}

							{selectedCar?.technical?.tyres?.tyreSizeBack &&
							selectedCar?.technical?.tyres?.tyreSizeFront ? (
								<div>
									<p className='wagen-label'>Maat:</p>
									<p className='wagen-data'>
										vooraan{' '}
										{
											selectedCar?.technical?.tyres
												?.tyreSizeFront
										}
										<br />
										achteraan{' '}
										{
											selectedCar?.technical?.tyres
												?.tyreSizeBack
										}
									</p>
								</div>
							) : null}

							{selectedCar?.technical?.tyres?.tyreAllSeason !=
								true &&
							selectedCar?.technical?.tyres?.tyreAllSeason !=
								false ? (
								<div>
									<p className='wagen-label'>All-season:</p>
									<p className='wagen-data'>
										{selectedCar?.technical?.tyres
											?.tyreAllSeason
											? 'ja'
											: 'nee'}
									</p>
								</div>
							) : null}

							{selectedCar?.technical?.tyres?.tyreSensor !=
								true &&
							selectedCar?.technical?.tyres?.tyreSensor !=
								false ? (
								<div>
									<p className='wagen-label'>Sensor:</p>
									<p className='wagen-data'>
										{selectedCar?.technical?.tyres
											?.tyreSensor
											? 'ja'
											: 'nee'}
									</p>
								</div>
							) : null}

							{selectedCar?.technical?.tyres?.tyreCenter ? (
								<div>
									<p className='wagen-label'>
										Bandencentrale:
									</p>
									<p className='wagen-data'>
										{
											selectedCar?.technical?.tyres
												?.tyreCenter
										}
										<br />
										{
											selectedCar?.technical?.tyres
												?.tyreCenterCity
										}
									</p>
								</div>
							) : null}
						</section>
					) : null}
				</section>
			</section>
		</section>
	);
};
