import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import {
	faChevronRight,
	faFileAlt,
	faGlassCheers,
	faGraduationCap,
	faHome,
	faNewspaper,
	faTimes,
	faUser,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HamburgerNavigation = forwardRef((props, ref) => {
	const hamburgerRef = useRef();

	const [opened, setOpened] = useState(false);

	useImperativeHandle(ref, () => ({
		toggleHamburger() {
			setOpened(!opened);
		},
	}));

	const handleClickOutsideDropdown = e => {
		if (hamburgerRef.current && hamburgerRef.current.contains(e.target))
			return true;
		setOpened(false);
	};

	const handleScrollOutsideDropdown = e => {
		if (hamburgerRef.current && hamburgerRef.current.contains(e.target))
			return true;
		setOpened(false);
	};

	useEffect(() => {
		document.addEventListener(
			'mousedown',
			handleClickOutsideDropdown,
			false,
		);
		document.addEventListener('wheel', handleScrollOutsideDropdown, false);

		return function cleanup() {
			document.removeEventListener(
				'mousedown',
				handleClickOutsideDropdown,
				false,
			);
			document.removeEventListener(
				'wheel',
				handleScrollOutsideDropdown,
				false,
			);
		};
	});

	return (
		<nav
			className={`header__hamburger${opened ? ' opened' : ''}`}
			ref={hamburgerRef}>
			<div className='header__hamburger_close-button'>
				<button onClick={() => setOpened(false)} aria-label='sluiten'>
					<FontAwesomeIcon icon={faTimes} />
				</button>
			</div>

			<ul>
				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/'
						className='header__hamburger_link'>
						<FontAwesomeIcon icon={faHome} fixedWidth={true} />
						Home
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>

				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/opleidingen'
						className='header__hamburger_link'>
						<FontAwesomeIcon
							icon={faGraduationCap}
							fixedWidth={true}
						/>
						Opleidingen
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>

				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/evenementen'
						className='header__hamburger_link'>
						<FontAwesomeIcon
							icon={faGlassCheers}
							fixedWidth={true}
						/>
						Evenementen
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>

				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/nieuws'
						className='header__hamburger_link'>
						<FontAwesomeIcon icon={faNewspaper} fixedWidth={true} />
						Nieuws
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>

				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/medewerkers'
						className='header__hamburger_link'>
						<FontAwesomeIcon icon={faUsers} fixedWidth={true} />
						Medewerkers
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>

				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/documenten'
						className='header__hamburger_link'>
						<FontAwesomeIcon icon={faFileAlt} fixedWidth={true} />
						Documenten
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>

				<li className='header__hamburger_item'>
					<NavLink
						onClick={() => setOpened(false)}
						to='/account'
						className='header__hamburger_link'>
						<FontAwesomeIcon icon={faUser} fixedWidth={true} />
						Mijn account
						<FontAwesomeIcon
							icon={faChevronRight}
							className='chevron'
						/>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
});

HamburgerNavigation.displayName = 'HamburgerNavigation';

export default HamburgerNavigation;
