import Collapsible from 'react-collapsible';
import { faAngleUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { downloadFile } from 'core/services/file';
import { getMimeIcon } from 'core/services/icon';

import './Category.scss';

const Category = ({ documents, filter, id, show, onShow }) => {
	return (
		<Collapsible
			trigger={
				<>
					<h2>{documents[0].category}</h2>
					<FontAwesomeIcon
						icon={faAngleUp}
						fixedWidth
						className='collapse-icon'
					/>
				</>
			}
			transitionTime={400}
			open={show}
			onClosing={() => onShow(null)}
			onOpening={() => onShow(id)}>
			{documents.map((item, index) => (
				<section className='sub-item-container' key={index}>
					<h3>{item.group}</h3>
					{item.files
						.filter(file =>
							filter
								? file.filename
										.toLowerCase()
										.includes(filter.toLowerCase())
								: true,
						)
						.map((file, index) => (
							<p key={index}>
								<FontAwesomeIcon
									icon={getMimeIcon(file.mime)}
									fixedWidth
									className='document-icon'
								/>
								<span className='text'>
									<span
										className='description'
										onClick={() =>
											downloadFile(
												file.filename,
												file.mine,
												file.description,
											)
										}>
										{file.description}
									</span>
									<span className='file-info'>
										{file.filesize ? (
											<span>{file.filesize}B</span>
										) : (
											''
										)}
										<span>{toShortDate(file.date)}</span>
									</span>
								</span>
								<FontAwesomeIcon
									icon={faDownload}
									fixedWidth
									className='download-icon'
									onClick={() =>
										downloadFile(
											file.filename,
											file.mine,
											file.description,
										)
									}
								/>
							</p>
						))}
				</section>
			))}
		</Collapsible>
	);
};

export default Category;
