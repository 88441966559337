import React, { memo } from 'react';
import { getObjectSlug } from 'core/services';
import { toShortDate } from 'core/services/date';
import { object } from 'prop-types';

import Card from 'components/Card/Card';

const MainFeedItem = ({ mainFeed }) => (
	<Card
		title={mainFeed.title}
		meta={`geplaatst door ${mainFeed.creator || 'admin'} op ${toShortDate(
			mainFeed.createdAt,
		)}`}
		picture={mainFeed.picture}
		shadowPicture
		withShadow
		skeletonCount={2}
		className={`main-feed ${
			mainFeed && mainFeed.department ? ` ${mainFeed.department}` : ''
		}${mainFeed.feedItem.pinned ? ' pinned' : ''}${
			mainFeed.feedItem.important ? ' important' : ''
		}`}
		hasOverflow={mainFeed.type !== 'UserMainFeedItem'}
		stickers={mainFeed.stickers}
		addStickers
		stickerType={mainFeed.type === 'UserMainFeedItem' ? 'users' : 'entries'}
		objType={mainFeed ? mainFeed.feedItem.type : null}
		feedType='mainFeed'
		objId={
			mainFeed.type === 'UserMainFeedItem'
				? mainFeed.feedItem.id
				: mainFeed.feedItem.entryId
		}
		url={`/${getObjectSlug(mainFeed.feedItem.type)}/${
			mainFeed.feedItem.slug
		}`}
		fullSizeLink
	/>
);

MainFeedItem.propTypes = {
	mainFeed: object.isRequired,
};

export default memo(MainFeedItem);
