import React, { memo } from 'react';
import { string } from 'prop-types';

import './EndOfList.scss';

const EndOfList = ({ className }) => {
	return (
		<div className={`end-of-list${className ? ` ${className}` : ''}`}>
			Je bereikte het einde van de lijst
		</div>
	);
};

EndOfList.propTypes = {
	className: string,
};

export default memo(EndOfList);
