import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { createFormData } from 'core/services/form';

import { findErrors } from './sickness.helpers';

const base_url = '/illnesses';
/**
 * Executes an Axios request to add a new illness or update an existing one.
 * @param {import('./sickness.helpers').Illness} newIllness - The illness data.
 * @returns {Promise<import('./sickness.helpers').Illness|object>} The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const addIllness = async newIllness => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: `${base_url}${newIllness.id ? '/' + newIllness.id : ''}`,
			method: newIllness.id ? 'PUT' : 'POST',
			data: createFormData(newIllness),
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		handleAlert('success', 'Ziekte succesvol aangemaakt');
		return newIllness.id ? { illness: data } : data;
	} catch (error) {
		const errorObject = error.response.data.error
			? error.response.data
			: { error: error.response.data };

		if (
			errorObject.error.message &&
			errorObject.error.errors.length === 0
		) {
			handleAlert('error', 'Er is iets misgegaan');
			return {};
		}

		const errors = findErrors(errorObject.error);

		if (errors.length) {
			handleAlert(
				'error',
				errors.map(error => (
					<span>
						{error}
						<br />
					</span>
				)),
			);
			return {};
		}

		return { extraInfoNeeded: errorObject.error.errors };
	}
};

/**
 * Executes an Axios request to delete an illness.
 * @param {string} id - The ID of the illness to delete.
 * @returns {Promise<boolean>} True if the deletion was successful, false otherwise.
 * @throws Will throw an error if the request fails.
 */
export const deleteIllness = async id => {
	try {
		const { status } = await executeAxios({
			url: `${base_url}/${id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Ziekte succesvol verwijderd');
		return status === 204;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan, neem contact met administratie',
		);
		return false;
	}
};

/**
 * Executes an Axios request to delete a file associated with an illness.
 * @param {string} id - The ID of the file to delete.
 * @returns {Promise<boolean>} True if the deletion was successful, false otherwise.
 * @throws Will throw an error if the request fails.
 */
export const deleteIllnessFile = async id => {
	try {
		const { status } = await executeAxios({
			url: `${base_url}/attachments/${id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Bestand succesvol verwijderd');
		return status === 204;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan, neem contact met administratie',
		);
		return false;
	}
};

/**
 * Executes an Axios request to get all illnesses.
 * @returns {Promise<import('./sickness.helpers').Illness[]>} - An array with all illnesses
 */
export const getAllIllnesses = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: base_url,
		});
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan, neem contact met administratie',
		);
		return null;
	}
};
