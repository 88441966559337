import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { findItemPage } from 'core/store/utils/updateState.utils';

import { store } from '../store.config';

export const loadSubFeeds = page => {
	const state = store.getState();
	const { subFeedsLoading, subFeedsNextPage } = state.subFeedReducer;

	if (!subFeedsLoading) {
		dispatchAction({
			type: ACTION_TYPES.SUB_FEEDS_LOAD,
			payload: { subFeedLoading: true },
			meta: {
				action: {
					effect: {
						url: `/sub-feed?page=${
							page || subFeedsNextPage - 1 || 1
						}`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.SUB_FEEDS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.SUB_FEEDS_LOAD,
						payload: { subFeedLoading: false },
					},
				},
			},
		});
	}
};

export const loadSubFeedPageForItemId = itemId => {
	const state = store.getState();
	const { mainFeeds } = state.mainFeedReducer;
	const page = findItemPage(mainFeeds, itemId);

	if (page) {
		loadSubFeeds(page);
	}
};
