import { executeAxios } from 'core/services/actions';

let registration = null;

export const getSubscription = async () => {
	const swRegistration = await navigator.serviceWorker.ready;

	return swRegistration.pushManager.getSubscription();
};

export const askPermission = async () => {
	if ('Notification' in window) {
		const permission = Notification.permission;
		if (permission === 'default') {
			await Notification.requestPermission();
			return askPermission();
		}
		return permission === 'granted';
	}
};

export const isPushSupported = () => {
	return 'PushManager' in window;
};

export const subscribe = async () => {
	if (!isPushSupported()) {
		console.log('##Service Worker##  Push notifications are not supported');
		return;
	}

	const permissionGranted = await askPermission();

	if (!permissionGranted) {
		console.log(
			'##Service Worker##  Permission to send notifications not granted',
		);
		return;
	}

	console.log('##Service Worker##  Registration exists: ', registration);
	console.log(
		'##Service Worker##  Push Manager exists: ',
		registration.pushManager,
	);

	let subscription = await getSubscription();

	if (!subscription) {
		const {
			data: { data: vapidPublicKey },
		} = await executeAxios({
			method: 'GET',
			url: '/push-notifications/public-key',
		});

		subscription = await registration.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: vapidPublicKey,
		});
	}

	console.log('##Service Worker##  Subscription exists: ', subscription);

	if (subscription) {
		await executeAxios({
			method: 'POST',
			url: '/push-notifications/subscribe',
			data: subscription,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}
};

export const unsubscribe = async () => {
	let subscription = await getSubscription();

	if (subscription) {
		await subscription.unsubscribe();

		await executeAxios({
			method: 'POST',
			url: '/push-notifications/unsubscribe',
			data: subscription,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}
};

export const registerServiceWorker = async () => {
	if ('serviceWorker' in navigator) {
		const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

		try {
			registration = await navigator.serviceWorker.getRegistration();
			if (!registration) {
				registration = await navigator.serviceWorker.register(swUrl);
			}

			console.log(
				'##Service Worker##  Service worker registration successful with scope: ',
				registration.scope,
			);
			let serviceWorker;
			if (registration.installing) {
				serviceWorker = registration.installing;
				console.log('##Service Worker##  Service worker installing');
			} else if (registration.waiting) {
				serviceWorker = registration.waiting;
				console.log('##Service Worker##  Service worker waiting');
			} else if (registration.active) {
				serviceWorker = registration.active;
				console.log('##Service Worker##  Service worker active');
			}
			if (serviceWorker) {
				serviceWorker.addEventListener('statechange', e => {
					console.log(
						'##Service Worker##  Service Worker State Changed:',
						e.target.state,
					);
				});
			}
		} catch (error) {
			console.log(
				'##Service Worker##  Service Worker registration failed: ',
				error,
			);
		}
	}
};
