/**
 * Calculate the state of the day item, based on the day data and the total real hours for the week.
 * @param {{
 *   day: {
 *     locked: boolean,
 *     duration: number,
 *     defaultDuration: number,
 *   },
 *   totalRealHours: number,
 *   dayHours: number,
 *   absenceDuration: number,
 *   countOvertime: boolean,
 * }} param0
 * @returns {'complete' | 'incomplete' | 'mismatch' | 'submitted' | 'disabled' | 'available' | 'unavailable' | 'blank'} itemState
 * @example
 * const itemState = getItemState({
 *  day: {
 *   locked: false,
 *   duration: 8,
 *  defaultDuration: 8,
 * },
 * totalRealHours: 8,
 * dayHours: 8,
 * absenceDuration: 0,
 * });
 * // itemState = 'complete';
 * @example
 * const itemState = getItemState({
 * day: {
 * locked: false,
 * duration: 8,
 * defaultDuration: 8,
 * },
 * totalRealHours: 4,
 * dayHours: 8,
 * absenceDuration: 0,
 * });
 * // itemState = 'incomplete';
 */
export const getItemState = ({
	day,
	totalRealHours,
	dayHours,
	absenceDuration,
}) => {
	const totalDayHours = day?.combinedHours || 0;

	if (!day) {
		return 'unavailable';
	}
	if (day?.locked) {
		if (totalDayHours === 0) {
			return 'disabled';
		}
		return 'submitted';
	}
	if (totalDayHours && totalRealHours > dayHours && !day.allowOvertime) {
		return 'mismatch';
	}
	if (
		((totalRealHours === dayHours ||
			(day.allowOvertime && totalRealHours > dayHours)) &&
			totalDayHours > 0) ||
		(day.allowOvertime && dayHours === 0 && day.duration > 0)
	) {
		return 'complete';
	}
	if (totalRealHours < dayHours && totalDayHours > 0) {
		return 'incomplete';
	}
	if (day?.defaultDuration === 0) {
		if (day.allowOvertime || !!day.onCall) {
			return 'available';
		}
		return 'unavailable';
	}
	if (absenceDuration === dayHours || totalRealHours >= dayHours) {
		if (day.allowOvertime || !!day.onCall) {
			return 'available';
		}
		return 'unavailable';
	}
	return 'blank';
};
