import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

import { createStickerObject, getFeedType } from './sticker.utils';

export const loadAvailableStickers = () => {
	dispatchAction({
		type: ACTION_TYPES.STICKERS_INIT,
		payload: [],
		meta: {
			action: {
				effect: {
					url: `/stickers`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.STICKERS_INIT },
				rollback: {},
			},
		},
	});
};

export const loadStickers = (id, type) => {
	dispatchAction({
		type: ACTION_TYPES.STICKERS_LOAD,
		payload: { type, id },
		meta: {
			action: {
				effect: {
					url: `/${type}/${id}/stickers`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.STICKERS_LOAD_COMMIT,
					payload: { type, id },
				},
				rollback: {},
			},
		},
	});
};

const createPayload = (stickerId, typeId, feedType) => {
	const state = store.getState();
	const { authUser } = state.authReducer;
	const { availableStickers } = state.stickerReducer;
	return {
		typeId,
		sticker: createStickerObject(stickerId, authUser, availableStickers),
		feedType: getFeedType(feedType),
	};
};

export const postSticker = (
	stickerId,
	typeId,
	type,
	feedType,
	placedSticker,
) => {
	const payload = createPayload(stickerId, typeId, feedType);
	dispatchAction({
		type: ACTION_TYPES.STICKER_ADD,
		payload,
		meta: {
			action: {
				effect: {
					url: '/stickers',
					method: 'post',
					body: { stickerId, typeId, type },
				},
				rollback: placedSticker
					? {
							type: ACTION_TYPES.STICKER_ADD,
							payload: createPayload(
								placedSticker.sticker.id,
								typeId,
								feedType,
							),
						}
					: {
							type: ACTION_TYPES.STICKER_DELETE,
							payload,
						},
			},
		},
	});
};

export const deleteSticker = (stickerId, typeId, type, feedType) => {
	const payload = createPayload(stickerId, typeId, feedType);
	dispatchAction({
		type: ACTION_TYPES.STICKER_DELETE,
		payload,
		meta: {
			action: {
				effect: {
					url: '/stickers',
					method: 'delete',
					body: { typeId, type },
				},
				rollback: {
					type: ACTION_TYPES.STICKER_ADD,
					payload,
				},
			},
		},
	});
};

export const stickerListener = (id, type, data) => {
	dispatchAction({
		type: ACTION_TYPES.STICKERS_LOAD_COMMIT,
		payload: { type, id, data },
	});
};
