import { useLayoutEffect, useState } from 'react';

const useIsWidthOverflow = (ref, callback) => {
	const [isOverflow, setIsOverflow] = useState(undefined);

	useLayoutEffect(() => {
		const current = ref.current;
		const trigger = () => {
			const hasOverflow = current.scrollWidth > current.clientWidth;
			setIsOverflow(hasOverflow);
			if (callback) callback(hasOverflow);
		};

		if (current) {
			if ('ResizeObserver' in window) {
				const resizeObserver = new ResizeObserver(trigger);
				resizeObserver.observe(current);
				return () => resizeObserver.disconnect();
			}
			trigger();
		}
	}, [callback, ref]);

	return isOverflow;
};

export default useIsWidthOverflow;
