import { isBiggerThan, isRequired, isSmallerThan } from 'core/services/form';

const createOptions = dates => {
	return [
		{ value: 'single', label: 'Eenvoudig' },
		...(dates.length > 2
			? [{ value: 'range', label: 'Eerste en laatste dag afwijkend' }]
			: []),
		...(dates.length > 7
			? [{ value: 'week', label: 'Week aanpassen' }]
			: []),
		{ value: 'all', label: 'Alles aanpassen' },
	];
};

const getSuitableViewOption = dateHours => {
	const values = Object.values(dateHours);

	const arrayValuesAreSame = values =>
		values.slice(1).every(value => value === values[0]);

	if (arrayValuesAreSame(values)) {
		return 'single';
	}

	if (arrayValuesAreSame(values.slice(1, -1))) {
		return 'range';
	}

	return 'all';
};

export const createRadioProps = (dates, dayHours) => {
	return {
		type: 'radio',
		options: createOptions(dates),
		validations: [],
		required: true,
		errors: [],
		value: getSuitableViewOption(dayHours),
	};
};

export const createDayProps = disabled => name => ({
	name,
	type: 'text',
	label: name,
	value: '8',
	disabled,
	errors: [],
	validations: [isSmallerThan(8), isRequired, isBiggerThan(0)],
	required: true,
});

/**
 * Creates an object with the day hours for the given type to be shown in the form.
 * @param {string} type - The type of the day hours.
 * @param {*} fields - The fields for the day hours.
 * @param {*} week - The week object.
 * @param {*} dates - The dates for the week.
 * @returns {Object} - The day hours object.
 * @example
 * const dayHours = createDayHours('single', { single: '8' }, {}, ['2021-07-01', '2021-07-02']);
 * // dayHours = { '2021-07-01': '8', '2021-07-02': '8' }
 * @example
 * const dayHours = createDayHours('range', { first: '8', last: '8' }, {}, ['2021-07-01', '2021-07-02']);
 * // dayHours = { '2021-07-01': '8', '2021-07-02': '8' }
 * @example
 * const dayHours = createDayHours('week', {}, { 1: '8', 2: '8', 3: '8', 4: '8', 5: '8' }, ['2021-07-01', '2021-07-02']);
 * // dayHours = { '2021-07-01': '8', '2021-07-02': '8' };
 */
export const createDayHours = (type, fields, week, dates) => {
	if (type === 'single') {
		return dates.reduce(
			(acc, date) => ({
				...acc,
				[date]: fields.single,
			}),
			{},
		);
	} else if (type === 'range') {
		return dates.reduce((acc, date, index) => {
			if (index === 0) {
				return {
					...acc,
					[date]: fields.first,
				};
			} else if (index === dates.length - 1) {
				return {
					...acc,
					[date]: fields.last,
				};
			}
			return {
				...acc,
				[date]: '8',
			};
		}, {});
	} else if (type === 'week') {
		return dates.reduce(
			(acc, date) => ({
				...acc,
				[date]: Object.values(week)[new Date(date).getDay() - 1] || '8',
			}),
			{},
		);
	} else {
		return fields;
	}
};
