import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	faBackward,
	faCheck,
	faForward,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxios from 'core/hooks/api/useAxios';
import {
	getMonthDays,
	getMonthWithOffset,
	getYearAndMonth,
	shortNameDays,
	toShortDate,
	toTechnicalDate,
} from 'core/services/date';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import { Button } from 'components/Form';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import SelectBlock from 'components/SelectBlock/SelectBlock';
import Table from 'components/Table/Table';
import TimesheetDay from 'components/Timesheet/TimesheetRowItem/TimesheetDay';

import VacationCounterModal from './VacationCounterModal/VacationCounterModal';
import VacationModal from './VacationModal/VacationModal';
import {
	createAbsence,
	fetchAbsences,
	getCounters,
	loadOverview,
} from './vacation.service';

import './VacationView.scss';

export const VacationView = () => {
	const [absences, setAbsences] = useState([]);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [vacationModal, setVacationModal] = useState(null);
	const [vacationCounterModal, setVacationCounterModal] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [modalAborted, setModalAborted] = useState(false);
	const [overviewModal, setOverviewModal] = useState(null);
	const [showMonthSelector, setShowMonthSelector] = useState(false);

	const { settings } = useSelector(state => state.authReducer);

	const [{ types: absenceTypes }] = useAxios({
		url: '/absences/types',
	});

	useEffect(() => {
		setLoading(true);
		fetchAbsences(currentDate).then(absences => {
			setAbsences(absences);
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentDate]);

	const notation = settings?.timesheetDisplay || 'decimal';
	const getMaxHours = () => settings?.workRegime / settings?.workDays;

	const getAbsencesForDate = date => {
		return absences?.filter(absence => absence.date === date);
	};

	const getTotalAbsenceHoursForDate = date => {
		const absencesForDate = getAbsencesForDate(date);
		return absencesForDate?.reduce(
			(total, absence) => total + (+absence.duration || 0),
			0,
		);
	};

	const getAbsenceDataForDate = (date, index) => {
		const totalHours = getTotalAbsenceHoursForDate(date);
		const absencesForDate = getAbsencesForDate(date);
		const locked = absencesForDate?.some(absence => absence.locked);
		const weekend = [5, 6].includes(index);
		const disabled = weekend || locked;

		return {
			date,
			duration: totalHours,
			locked,
			disabled,
			weekend,
		};
	};

	const handlePrevMonth = () => {
		setCurrentDate(getMonthWithOffset(-1));
	};

	const handleNextMonth = () => {
		setCurrentDate(getMonthWithOffset(1));
	};

	const createHandleDayClick = () => {
		let isLoading = false; // Prevents double clicks to create absences
		return async day => {
			if (day.disabled || isLoading) return;
			if (day.duration === 0) {
				isLoading = true;
				const newAbsence = await createAbsence(day.date);
				isLoading = false;
				if (!newAbsence) return;
				setAbsences(prev => [...prev, newAbsence]);
			} else {
				setVacationModal({ date: day.date, getMaxHours });
			}
		};
	};

	const handleDayClick = createHandleDayClick();

	const showVacationCounter = async () => {
		setLoadingModal(true);
		setModalAborted(false);
		const data = await getCounters();
		if (modalAborted || !data) return;
		setVacationCounterModal({ data });
		setLoadingModal(false);
	};

	const handleModalLoaderClose = () => {
		setModalAborted(true);
		setLoadingModal(false);
		setVacationCounterModal(null);
	};

	const createMothSelectOption = offset => {
		const date = getMonthWithOffset(offset)(new Date());
		const label = format(date, 'MMMM yyyy', {
			locale: nl,
		});
		return {
			value: toTechnicalDate(date),
			label,
		};
	};

	const createMonthSelectOptions = () => {
		const options = [];
		for (let i = -1; i < 12; i++) {
			options.push(createMothSelectOption(i));
		}
		return options;
	};

	const handleLoadOverview = async () => {
		const overview = await loadOverview();
		if (overview) {
			setOverviewModal(overview);
		}
	};

	return loading ? (
		<Loading />
	) : (
		<section className='absences'>
			<section className='absences__header'>
				<div className='absences__navigation'>
					<button
						className='btn btn--secondary absences__navigation--previous'
						onClick={handlePrevMonth}>
						<FontAwesomeIcon
							icon={faBackward}
							className='absences__navigation__icon'
						/>
					</button>
					<button
						className='btn btn--secondary absences__navigation--current'
						onClick={e => {
							e.stopPropagation();
							setShowMonthSelector(prev => !prev);
						}}>
						<span>
							{format(currentDate, 'MMMM yyyy', { locale: nl })}
						</span>
						{showMonthSelector ? (
							<SelectBlock
								options={createMonthSelectOptions()}
								initialValue={toTechnicalDate(
									currentDate.setDate(1),
								)}
								close={() => setShowMonthSelector(false)}
								onChange={value => {
									setCurrentDate(new Date(value));
								}}
							/>
						) : null}
					</button>
					<button
						className='btn btn--secondary absences__navigation--next'
						onClick={handleNextMonth}>
						<FontAwesomeIcon
							icon={faForward}
							className='absences__navigation__icon'
						/>
					</button>
				</div>
			</section>
			<section className='absences__body'>
				<header className='absences__body__header'>
					{shortNameDays.map((day, i) => (
						<div
							className={`absences__body__header__day${
								[5, 6].includes(i)
									? ' absences__body__header__day--weekend'
									: ''
							}`}
							key={day}>
							<span>{day.slice(0, 2)}</span>
							<span className='absences__body__header__day--tail'>
								{day.slice(2)}
							</span>
						</div>
					))}
				</header>
				{absences ? (
					<div className='absences__body__content'>
						{getMonthDays(getYearAndMonth(currentDate)).map(
							(week, i) => (
								<div
									className='absences__body__content__week'
									key={`week-${i}`}>
									{week
										.map(getAbsenceDataForDate)
										.map((day, j) => (
											<div
												className={`absences__body__content__day${
													day.weekend
														? ' absences__body__content__day--weekend'
														: ''
												}`}
												key={`day-${j}`}>
												{day.date ? (
													<TimesheetDay
														day={day}
														itemState={`${
															day.disabled
																? 'disabled'
																: day.duration
																	? 'complete'
																	: 'blank'
														}`}
														onClick={() =>
															handleDayClick(day)
														}
														format={notation}
														editable={!day.disabled}
														simple
														header={true}
													/>
												) : null}
											</div>
										))}
								</div>
							),
						)}
					</div>
				) : (
					<div className='timesheet__message'>
						Er is iets misgegaan. Probeer later opnieuw.
					</div>
				)}
				{vacationModal ? (
					<VacationModal
						modal={vacationModal}
						setModal={setVacationModal}
						absences={absences}
						notation={notation}
						setAbsences={setAbsences}
						absenceTypes={absenceTypes}
					/>
				) : null}
				<Modal
					show={loadingModal}
					title='Vakantieteller'
					className='vacation-modal'
					close={handleModalLoaderClose}>
					<Loading />
				</Modal>
				{vacationCounterModal ? (
					<VacationCounterModal
						modal={vacationCounterModal}
						setModal={setVacationCounterModal}
					/>
				) : null}
				<Modal
					show={overviewModal}
					title='Jaaroverzicht'
					close={() => setOverviewModal(null)}>
					<section className='absences__overview'>
						{overviewModal ? (
							<Table
								headers={{
									date: 'Datum',
									duration: 'Uren',
									type: 'Type',
									remark: 'Opmerking',
								}}
								data={overviewModal.map(row => ({
									date: (
										<span>
											<span className='absences__overview__approval'>
												{row.approved ? (
													<FontAwesomeIcon
														icon={faCheck}
														className='absences__overview__approved'
													/>
												) : (
													<FontAwesomeIcon
														icon={faXmark}
														className='absences__overview__not-approved'
													/>
												)}
											</span>
											<span>{toShortDate(row.date)}</span>
										</span>
									),
									duration: row.duration,
									remark: row.remark
										?.split('\n')
										.map((line, i) => (
											<span key={i}>
												{line}
												<br />
											</span>
										)),
									type: row.absenceType.name,
									...(new Date(row.date) < new Date()
										? {
												className:
													'absences__overview__row--past',
											}
										: {}),
								}))}
								className='absences__overview__table'
							/>
						) : null}
					</section>
				</Modal>
			</section>
			<section className='absences__footer'>
				<div className='absences__counter'>
					<Button
						buttonStyle='secondary'
						onClick={showVacationCounter}>
						Vakantieteller
					</Button>
					<Button
						buttonStyle='secondary'
						onClick={handleLoadOverview}>
						Jaaroverzicht
					</Button>
				</div>
			</section>
		</section>
	);
};
