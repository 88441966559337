/**
 * Updates stickers of an item
 * @param {{
 *   items: Object[],
 *   typeId: string,
 *   sticker: {
 *     id: string,
 *     class: string,
 *   },
 *   action: 'POST' | 'DELETE',
 * }} param0
 * @returns {Object[]}
 */
const updateStickers = ({ items, typeId, sticker, action = 'ADD' } = {}) => {
	return items.map(item => {
		const itemId = item.feedItem?.entryId || item.entryId || item.id;
		if (itemId !== typeId) {
			return item;
		}

		const stickers = item.stickers.filter(
			({ author }) => author.id !== sticker.author.id,
		);

		if (action === 'ADD') {
			stickers.push(sticker);
		}

		return {
			...item,
			stickers,
		};
	});
};

const mapStickerTypes = {
	subFeed: 'subFeed',
	newsFeed: 'newsFeed',
	news: 'newsFeed',
	evenementen: 'event',
	events: 'event',
	opleidingen: 'training',
	trainings: 'training',
	mainFeed: 'mainFeed',
};

export const getFeedType = type => {
	return mapStickerTypes[type];
};

export const createStickerObject = (stickerId, authUser, stickers) => {
	const sticker = stickers.find(x => x.id === stickerId);
	return {
		id: 'id',
		sticker: {
			id: sticker?.id,
			class: sticker?.class,
		},
		author: {
			id: authUser.id,
			name: `${authUser.firstName} ${authUser.lastName}`,
		},
	};
};

/**
 * Creates a reducer that updates stickers of a stickerable item
 * @param {'mainFeed' | 'subFeed' | 'newsFeed' | 'event' | 'training'} stickerableName
 * @returns {(state: Object, action: Object) => Object}
 */
export const updateStickersOfStickerable =
	stickerableName =>
	(state, { typeId, feedType, sticker }, action) => {
		if (feedType !== stickerableName) {
			return { ...state };
		}

		const detailsName = `${stickerableName}Details`;

		return {
			...state,
			[`${stickerableName}s`]: updateStickers({
				items: state[`${stickerableName}s`],
				sticker,
				typeId,
				action,
			}),
			...(state[detailsName] && {
				[detailsName]: {
					...state[detailsName],
					stickers: updateStickers({
						items: [state[detailsName]],
						sticker,
						typeId,
						action,
					})[0].stickers,
				},
			}),
		};
	};
