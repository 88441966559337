import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocialMediaIconService } from 'core/services/icon';
import { arrayOf, bool, number, shape, string } from 'prop-types';

import Icon from '../Icon/Icon';

const SocialMedia = ({ socials, squareIcons, addClassName, teams }) => {
	const renderIcon = icon => <FontAwesomeIcon icon={icon} />;

	const renderSquareIcon = (icon, social) => (
		<span className='fa-layers fa-fw'>
			{social.type != 'TikTok' ? (
				<FontAwesomeIcon icon={faSquare} />
			) : null}
			<FontAwesomeIcon icon={icon} />
		</span>
	);

	return (
		<ul className={addClassName ? 'social-media' : ''}>
			{teams ? (
				<li className={`teams${socials.length === 0 ? ' single' : ''}`}>
					<a
						href={`https://teams.microsoft.com/l/chat/0/0?users=${teams}`}
						target='_blank'
						rel='noopener noreferrer'
						className='Teams'>
						<Icon icon='teams' className='teams' />
						<span aria-hidden='true'>Teams</span>
					</a>
				</li>
			) : null}

			{socials.map(social => {
				let icon = SocialMediaIconService(social.type, squareIcons);

				return (
					<li key={social.id}>
						<a
							href={social.link}
							target='_blank'
							className={social.type}
							rel='noopener noreferrer'>
							{squareIcons
								? renderSquareIcon(icon, social)
								: renderIcon(icon)}
							<span aria-hidden='true'>{social.type}</span>
						</a>
					</li>
				);
			})}
		</ul>
	);
};

SocialMedia.propTypes = {
	socials: arrayOf(
		shape({
			type: string,
			id: number,
			link: string,
		}),
	),
	squareIcons: bool,
	addClassName: bool,
	teams: string,
};

export default SocialMedia;
