import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';

import Card from 'components/Card/Card';
import Tag from 'components/Tag/Tag';

import { DisplayDates } from '../DisplayDates/DisplayDates';
import { DisplayLocation } from '../DisplayLocation/DisplayLocation';

import './ListOverviewItem.scss';

const ItemCard = ({
	item,
	index,
	type,
	showTitle = true,
	fullSizeLink = false,
	children,
}) => (
	<Card
		key={index}
		title={showTitle ? item.name : null}
		addStickers
		stickers={item.stickers}
		stickerType='entries'
		objId={item.entryId}
		feedType={type}
		className='list-overview-item'
		url={item.slug}
		fullSizeLink={fullSizeLink}>
		<section className='card_children'>
			{item.days ? <DisplayDates days={item.days} /> : null}
			{item.location ? (
				<DisplayLocation location={item.location} />
			) : null}

			{children}
			{type == 'news' || !item.maxAttendDate ? null : (
				<SubscribeInfo item={item} />
			)}
			<section className='tags__container container'>
				{item.tags?.map((tag, index) => (
					<Tag key={index} label={tag} />
				))}
			</section>
		</section>
	</Card>
);

const SubscribeInfo = ({ item }) => {
	return (
		<div className='subscribe-info'>
			<span className='sidebar-icon'>
				<FontAwesomeIcon icon={faUserGroup} fixedWidth />
			</span>{' '}
			<span className='subscribe-info__text'>
				<span>
					{item.numberOfAttendees == 0
						? 'Nog geen'
						: item.numberOfAttendees}
					{item.maxAttendees && item.numberOfAttendees > 0
						? ` / ${item.maxAttendees}`
						: item.numberOfAttendees == 1
							? ' inschrijving'
							: ' inschrijvingen'}{' '}
				</span>
				<span>inschrijven voor {toShortDate(item.maxAttendDate)}</span>
			</span>
		</div>
	);
};

export default ItemCard;
