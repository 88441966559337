import { InputField, InputRadioButton, useForm } from 'components/Form';
import Modal from 'components/Modal/Modal';

import {
	createDayHours,
	createDayProps,
	createRadioProps,
} from './sicknessModal.helpers';

const SicknessHoursModal = ({
	dates,
	dateHours,
	setDateHours,
	showModal,
	disabled,
	setShowModal,
}) => {
	const week = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag'];
	const createDay = createDayProps(disabled);
	const typeForm = useForm();
	const fieldsForm = useForm();
	const weekForm = useForm();

	const handleSubmit = () => {
		const newDayHours = createDayHours(
			typeForm.values.type,
			fieldsForm.values,
			weekForm.values,
			dates,
		);
		setDateHours(newDayHours);
		setShowModal(false);
	};

	return (
		<Modal
			show={showModal}
			title='Ziek melden'
			close={() => setShowModal(false)}
			actions={[
				{
					label: 'Bewaar',
					styles: { marginLeft: '1rem' },
					disabled: !fieldsForm.isValid(),
					action: handleSubmit,
				},
			]}>
			{dates.length > 1 ? (
				<InputRadioButton
					{...typeForm.register(
						'type',
						createRadioProps(dates, dateHours),
					)}
				/>
			) : null}

			{dates.length > 7 && typeForm.values.type === 'week' ? (
				week.map(dag => (
					<InputField
						{...weekForm.register(dag, {
							...createDay(dag),
							description: `Dit schema zal worden toegepast op alle ${dag.toLowerCase()}en van de afwezigheid`,
						})}
						key={dag}
					/>
				))
			) : dates.length > 2 && typeForm.values.type === 'range' ? (
				<>
					<InputField
						{...fieldsForm.register('first', {
							...createDay(dates[0]),
							label: 'Eerste dag',
							value: Object.values(dateHours)[0] ?? '8',
							description:
								'Deze uren worden toegepast op de eerste dag van de afwezigheid.',
						})}
					/>
					<InputField
						{...fieldsForm.register('last', {
							...createDay(dates[dates.length - 1]),
							label: 'Laatste dag',
							value: dateHours[dates[dates.length - 1]] ?? '8',
							description:
								'Deze uren worden toegepast op de laatste dag van de afwezigheid. Voor de andere dagen word 8u geregistreerd',
						})}
					/>
				</>
			) : dates.length > 1 && typeForm.values.type === 'single' ? (
				<InputField
					{...fieldsForm.register('single', {
						...createDay('single'),
						label: 'Uren afwezig',
						value:
							Object.values(dateHours)[0] === undefined
								? '8'
								: Object.values(dateHours)[0],
						description:
							'Deze uren worden toegepast op alle dagen van de afwezigheid',
					})}
				/>
			) : (
				dates.map(date => (
					<InputField
						{...fieldsForm.register(date, {
							...createDay(date),
							value:
								dateHours[date] === undefined
									? '8'
									: dateHours[date],
						})}
						key={date}
					/>
				))
			)}
		</Modal>
	);
};

export default SicknessHoursModal;
