import { useSelector } from 'react-redux';
import { removeToast } from 'core/store/snackbar/snackbar.actions';

import Toast from './Toast';

const Snackbar = () => {
	const { toasts } = useSelector(state => state.snackbarReducer);

	return (
		<div className='toasts' id='toast'>
			{toasts.map(toast => {
				const { id } = toast;
				return (
					<Toast
						{...toast}
						key={id}
						onDismissClick={() => removeToast(id)}
					/>
				);
			})}
		</div>
	);
};

export default Snackbar;
