import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	confirmed: null,
	request: null,
	invited: false,
};

const setInviteStatus = (state, data) => {
	return {
		...state,
		...data,
	};
};

const loadInviteCommit = (state, { data }) => {
	return {
		...state,
		...data,
		invited: !!data,
	};
};

export const inviteReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.INVITE_SET:
			return setInviteStatus(state, payload);
		case ACTION_TYPES.INVITE_LOAD_COMMIT:
			return loadInviteCommit(state, payload);
		default:
			return state;
	}
};
