import { toShortDate } from 'core/services/date';
import { isEmailList, isRequired } from 'core/services/form';

export const selectedDates = {
	name: 'date',
	type: 'datepicker',
	value: [],
	errors: [],
	mode: 'range',
	validations: [isRequired],
};

export const uploadField = {
	type: 'file',
	label: 'Ziektebewijs',
	name: 'attachments',
	multiple: true,
	description:
		'Enkel bestanden kleiner dan 10MB zijn toegelaten. \r\nJe ziektemelding is pas volledig in orde na het indienen van een doktersattest.',
	errors: [],
	value: [],
};

export const remark = {
	type: 'textarea',
	name: 'remark',
	label: 'Opmerking',
	validations: [],
	errors: [],
	value: '',
};

export const oneDaySick = {
	type: 'checkbox',
	name: 'oneDaySick',
	label: 'Melding zonder ziektebewijs',
	description: 'Maximaal 1 dag',
	validations: [],
	errors: [],
	value: false,
};

export const sicknessRepeat = {
	type: 'checkbox',
	name: 'sicknessRepeat',
	label: 'Het gaat om en hervatting van arbeidsongeschiktheid',
	description:
		'De gerelateerde arbeidsongeschiktheid mag niet langer dan 2 weken eerder zijn beeindigd',
	validations: [],
	errors: [],
	value: false,
};

export const sendEmail = {
	type: 'checkbox',
	name: 'sendEmail',
	label: 'Stuur melding naar je eindklant',
	description: 'De eindklant wordt op de hoogte gebracht van je afwezigheid',
	validations: [],
	errors: [],
	value: false,
};

export const emailField = {
	type: 'email',
	name: 'clientEmail',
	label: 'E-mailadres',
	validations: [isEmailList],
	errors: [],
	value: '',
};

/**
 * Finds the actual errors in the error object from the API
 * @param {object} error - The error object from the API, which can contain multiple errors
 * @returns {string[]} - An array with the actual errors
 * @example
 * const error = {
 * 	errors: [
 * 		{
 * 			needsAction: [
 * 				{
 * 					date: '2021-09-01',
 * 					timeToClear: 8,
 * 					currentDurations: [
 * 						{
 * 							type: 'sick',
 * 							duration: 8,
 * 							id: 1,
 * 							project: 'project',
 * 							recover: [],
 * 						},
 * 					],
 * 				},
 * 			],
 * 			possibleRelations: [
 * 				{
 * 					id: 1,
 * 					relatedIllness: '2021-09-01',
 * 					period: '2021-09-01',
 * 				},
 * 			],
 * 		},
 * 	],
 * };
 * const errors = findErrors(error);
 * console.log(errors); // ['2021-09-01 - The duration of the sick period is too long'];
 */
export const findErrors = error => {
	if (!error) {
		return [];
	}

	const { needsAction, possibleRelations } = error.errors[0];

	const errorItems = [
		...(needsAction || []),
		...(possibleRelations || []),
		...(!needsAction && !possibleRelations ? error.errors : []),
	];

	return errorItems
		.filter(({ ready }) => !ready)
		.reduce((acc, errorItem) => {
			const { date, clearFrom = [] } = errorItem;
			if (clearFrom.length > 0) {
				clearFrom.forEach(({ message }) => {
					if (message) {
						acc.push(`${toShortDate(date)} - ${message}`);
					}
				});
			} else if (typeof errorItem === 'string') {
				acc.push(errorItem);
			}

			return acc;
		}, []);
};

/**
 * Creates a new illness object form the form values and values from the edit illness object and dateHours object
 * @param {FormValues} formValues - The values from the form
 * @param {Illness} editIllness - The illness that is being edited
 * @param {{[k: string]: string}} dateHours - An object with the hours per date (e.g. { '2021-09-01': 8 }
 * @returns {Illness} - The new illness object
 * @example
 * const formValues = {
 * 	remark: 'remark',
 * 	attachments: 'attachments',
 * 	dates: ['2021-09-01'],
 * 	clientEmail: 'clientEmail',
 * 	oneDaySick: false,
 * };
 * const editIllness = {
 * 	id: 1,
 * 	remark: 'remark',
 * 	attachments: 'attachments',
 * 	clientEmail: ['clientEmail'],
 * 	period: [
 * 		{
 * 			date: '2021-09-01',
 * 			duration: 8,
 * 		},
 * 	],
 * };
 * const dateHours = {
 * 	'2021-09-01': 8,
 * };
 * const newIllness = createNewIllness(formValues, editIllness, dateHours);
 * console.log(newIllness); // { hasNotes: 1, remark: 'remark', attachments: 'attachments', clientEmail: ['clientEmail'], period: [{ date: '2021-09-01', duration: 8 }], id: 1 }
 */
export const createNewIllness = (formValues, editIllness, dateHours) => {
	const { remark, attachments, dates, clientEmail, oneDaySick } = formValues;
	const newIllness = {
		hasNotes: oneDaySick ? 0 : 1,
		remark,
		attachments,
		clientEmail: clientEmail
			? clientEmail.replace(/\s/g, '').split(',').filter(Boolean)
			: [],
		period: dates.map(date => ({
			date,
			duration: dateHours[date] || 8,
		})),
	};

	if (editIllness) {
		newIllness.id = editIllness.id;
	} else {
		newIllness.askedForRelated = 0;
		newIllness.relatedIllness = '';
	}

	return newIllness;
};

/**
 * Creates a new period object from the form values and the period object
 * @param {Illness} illness - The original illness object which is being edited
 * @param {ParsedErrors} parsedErrors - The parsed errors from the API
 * @param {FormValues} formValues - The values from the form
 * @returns {Period[]} - An array with the updated periods
 * @example
 * const illness = {
 * 	period: [
 * 		{
 * 			date: '2021-09-01',
 * 			duration: 8,
 * 		},
 * 	],
 * };
 * const parsedErrors = {
 * 	needsAction: [
 * 		{
 * 			date: '2021-09-01',
 * 			timeToClear: 8,
 * 			currentDurations: [
 * 				{
 * 					type: 'sick',
 * 					duration: 8,
 * 					id: 1,
 * 					project: 'project',
 * 					recover: [],
 * 				},
 * 			],
 * 		},
 * 	],
 * };
 * const formValues = {
 * 	recover_1: 1,
 * };
 * const newPeriod = createPeriod(illness, parsedErrors, formValues);
 * console.log(newPeriod); // [{ date: '2021-09-01', duration: 8, clearFrom: [{ id: 1, type: 'sick', duration: 1, recover: 1 }] }];
 */
const createPeriod = (illness, parsedErrors, formValues) => {
	return illness.period.map(period => ({
		...period,
		...(parsedErrors.needsAction && {
			clearFrom: Object.keys(formValues).reduce((acc, label) => {
				const [prefix, type, id] = label.split('_');
				if (label.includes(period.date)) {
					acc.push({
						id,
						type,
						duration: formValues[label],
						...(prefix.indexOf('recover') === 0 && {
							recover: 1,
						}),
					});
				}
				return acc;
			}, []),
		}),
	}));
};

/**
 * Creates a new illness object from the form values and the parsed errors
 * @param {Illness} illness
 * @param {ParsedErrors} parsedErrors
 * @param {FormValues} formValues
 * @returns {Illness} - The updated illness object
 * @example
 * const illness = {
 * 	remark: 'remark',
 * 	attachments: 'attachments',
 * 	period: [
 * 		{
 * 			date: '2021-09-01',
 * 			duration: 8,
 * 		},
 * 	],
 * };
 * const parsedErrors = {
 * 	possibleRelations: [
 * 		{
 * 			id: 1,
 * 			relatedIllness: '2021-09-01',
 * 			period: '2021-09-01',
 * 		},
 * 	],
 * };
 * const formValues = {
 * 	relatedIllness: 1,
 * };
 * const newIllness = createIllness(illness, parsedErrors, formValues);
 * console.log(newIllness); // { remark: 'remark', attachments: 'attachments', period: [{ date: '2021-09-01', duration: 8 }], relatedIllness: 1, askedForRelated: 1 }
 */
export const createIllness = (illness, parsedErrors, formValues) => {
	return {
		...illness,
		period: createPeriod(illness, parsedErrors, formValues),
		...(parsedErrors.possibleRelations?.length > 0 && {
			relatedIllness: formValues.relatedIllness,
			askedForRelated: 1,
		}),
		// ...(illness.id && { askedForRelated: 1 }),
	};
};

/**
 * Parses the errors from the API
 * @param {object[]} errors - The errors from the API
 * @returns {ParsedErrors} - The parsed errors
 * @example
 * const errors = [
 * 	{
 * 		needsAction: [
 * 			{
 * 				date: '2021-09-01',
 * 				timeToClear: 8,
 * 				currentDurations: [
 * 					{
 * 						type: 'sick',
 * 						duration: 8,
 * 						id: 1,
 * 						project: 'project',
 * 						recover: [],
 * 					},
 * 				],
 * 			},
 * 		],
 * 		possibleRelations: [
 * 			{
 * 				id: 1,
 * 				relatedIllness: '2021-09-01',
 * 				period: '2021-09-01',
 * 			},
 * 		],
 * 	},
 * ];
 * const parsedErrors = parseErrors(errors);
 * console.log(parsedErrors);
 * // {
 * // 	needsAction: [
 * // 		{
 * // 			date: '2021-09-01',
 * // 			timeToClear: 8,
 * // 			currentDurations: [
 * // 				{
 * // 					type: 'sick',
 * // 					duration: 8,
 * // 					id: 1,
 * // 					project: 'project',
 * // 					recover: [],
 * // 				},
 * // 			],
 * // 		},
 * // 	],
 * // 	possibleRelations: [
 * // 		{
 * // 			id: 1,
 * // 			relatedIllness: '2021-09-01',
 * // 			period: '2021-09-01',
 * // 		},
 * // 	],
 * // }
 */
export const parseErrors = errors => {
	return {
		needsAction: (errors[0]?.needsAction || errors || [])
			?.filter(action => !action.ready)
			.map(action => ({
				date: action.date,
				timeToClear: action.timeToClear,
				currentDurations: action.currentDurations?.map(duration => ({
					type: duration.type,
					duration: duration.duration,
					id: duration.id,
					project: duration.project,
					recover: action.clearFrom?.filter(
						({ id, recover }) =>
							recover === null && id === duration.id,
					),
				})),
			})),
		possibleRelations: errors[0]?.possibleRelations?.map(relation => ({
			id: relation.id,
			relatedIllness: relation.relatedIllness,
			period: relation?.period,
		})),
	};
};

export const sortByDate = (a, b) => {
	return new Date(a.period[0].date) - new Date(b.period[0].date);
};

/**
 * Checks the status of the report
 * @param {*} report
 * @returns {'completed' | 'uncompleted' | 'unapproved' | 'unhandled'} - The status of the report
 * @example
 * const report = {
 * 	completed: true,
 * 	period: [
 * 		{
 * 			date: '2021-09-01',
 * 			approved: true,
 * 		},
 * 		{
 * 			date: '2021-09-02',
 * 			approved: false,
 * 		},
 * 	],
 * };
 * const status = getReportStatus(report);
 * console.log(status); // 'uncompleted'
 */
export const getReportStatus = report => {
	const completed = report?.completed;
	const someDaysApproved = report?.period.some(day => day.approved);
	const allDaysNotApproved = report?.period.every(day => !day.approved);

	if (allDaysNotApproved) {
		return completed ? 'unapproved' : 'unhandled';
	}

	if (someDaysApproved) {
		return completed ? 'completed' : 'uncompleted';
	}

	return completed ? 'approved' : 'unhandled';
};

/**
 * Returns the fields that should be disabled based on the report status
 * @param {*} report
 * @returns {('date'|'attachment'|'remark'|'oneDaySick'|'sicknessRepeat'|'clientEmail'|'download')[]} - The fields that should be disabled
 */
export const getDisabledFields = report => {
	switch (report.status) {
		case 'unapproved':
			return [
				'date',
				'attachments',
				'remark',
				'oneDaySick',
				'clientEmail',
				'sicknessRepeat',
			];
		case 'completed':
			return [
				'date',
				'attachments',
				'remark',
				'oneDaySick',
				'clientEmail',
				'sicknessRepeat',
				'download',
			];
		case 'uncompleted':
			return [
				'date',
				'remark',
				'oneDaySick',
				'clientEmail',
				'sicknessRepeat',
				'download',
			];
		default:
			return [];
	}
};

/**
 * Returns the disabled dates based on the report status
 * @param {{ period: Array, status: string }} param0
 * @returns {Array} - The disabled dates
 * @example
 * const report = {
 * 	period: [
 * 		{
 * 			date: '2021-09-01',
 * 			approved: true,
 * 		},
 * 		{
 * 			date: '2021-09-02',
 * 			approved: false,
 * 		},
 * 	],
 * 	status: 'unapproved',
 * };
 * const disabledDates = getDisabledDates(report);
 * console.log(disabledDates); // [{ before: '2021-09-01', after: '2021-09-02' }]
 * @example
 * const report = {
 * 	period: [
 * 		{
 * 			date: '2021-09-01',
 * 			approved: true,
 * 		},
 * 		{
 * 			date: '2021-09-02',
 * 			approved: true,
 * 		},
 * 	],
 * 	status: 'completed',
 * };
 * const disabledDates = getDisabledDates(report);
 * console.log(disabledDates); // true
 */
export const getDisabledDates = ({ period, status }) => {
	return status === 'unapproved'
		? [
				{
					before: new Date(period[0].date),
					after: new Date(period[period.length - 1].date),
				},
			]
		: status === 'completed' || status === 'uncompleted'
			? true
			: [];
};

/**
 * @typedef {object} FormValues
 * @property {string[]} dates - The dates
 * @property {string} remark - The remark
 * @property {string} attachments - The attachments
 * @property {string} relatedIllness - The related illness
 * @property {string} askedForRelated - The asked for related
 * @property {string} period - The period
 * @property {string} sendEmail - The send email
 * @property {string} email - The email
 * @property {string} oneDaySick - The one day sick
 * @property {string} sicknessRepeat - The sickness repeat
 * @property {string} recover - The recover
 * @property {string} clearFrom - The clear from
 */

/**
 * @typedef {object} Illness
 * @property {string} [id] - The id
 * @property {string} remark - The remark
 * @property {string} attachments - The attachments
 * @property {number|string} [relatedIllness] - The related illness
 * @property {number} [askedForRelated] - The asked for related
 * @property {Period[]} period - The period
 */

/**
 * @typedef {object} Period
 * @property {string} date - The date
 * @property {string|number} duration - The duration
 */

/**
 * @typedef {object} ParsedErrors
 * @property {object[]} needsAction - The needsAction errors
 * @property {object[]} possibleRelations - The possibleRelations errors
 */

/**
 * @typedef {object} Recover
 * @property {string} id - The id
 * @property {string} type - The type
 * @property {number} duration - The duration
 */

/**
 * @typedef {object} currentDurations
 * @property {string} type - The type of the duration
 * @property {number} duration - The duration
 * @property {string} id - The id
 * @property {string} project - The project
 * @property {Recover[]} recover - The recover
 */

/**
 * @typedef {object} NeedsAction
 * @property {string} date - The date of the error
 * @property {number} timeToClear - The time to clear
 * @property {currentDurations[]} currentDurations - The current durations
 * @property {object[]} clearFrom - The clear from
 */

/**
 * @typedef {object} PossibleRelations
 * @property {string} id - The id
 * @property {string} relatedIllness - The related illness
 * @property {string} period - The period
 */
