export const createFormData = data => {
	const formData = new FormData();

	for (const key in data) {
		parseObject(data[key], key, formData);
	}
	return formData;
};

const parseObject = (obj, parentKey, formData) => {
	if (obj && typeof obj === 'object') {
		if (obj instanceof File) {
			formData.append(parentKey, obj);
		} else {
			Object.keys(obj).forEach(key => {
				if (obj[key] instanceof File) {
					formData.append(parentKey, obj[key]);
				} else {
					const newKey = parentKey ? `${parentKey}[${key}]` : key;
					parseObject(obj[key], newKey, formData);
				}
			});
		}
	} else {
		formData.append(parentKey, obj);
	}
};
