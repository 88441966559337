import React, { memo } from 'react';
import { toShortDate } from 'core/services/date';
import { PropTypes } from 'prop-types';

import Card from 'components/Card/Card';

const NewsFeedItem = ({ newsFeed }) => {
	return (
		<Card
			title={newsFeed.title}
			meta={`geplaatst door ${
				newsFeed.creator?.firstName +
					' ' +
					newsFeed.creator?.lastName || 'admin'
			} op ${toShortDate(newsFeed.publishedAt)}`}
			url={`/nieuws/${newsFeed.slug}`}
			picture={newsFeed.logo}
			shadowPicture
			withShadow
			stickers={newsFeed.stickers}
			addStickers
			stickerType='entries'
			skeletonCount={2}
			className={`main-feed ${
				newsFeed && newsFeed.department ? ` ${newsFeed.department}` : ''
			}`}
			objId={newsFeed ? newsFeed.entryId : null}
			objType='news'
			feedType='newsFeed'
			fullSizeLink
		/>
	);
};

NewsFeedItem.propTypes = {
	newsFeed: PropTypes.shape({
		title: PropTypes.string.isRequired,
		creator: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		publishedAt: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		logo: PropTypes.string,
		department: PropTypes.string,
		id: PropTypes.number,
	}).isRequired,
};

export default memo(NewsFeedItem);
