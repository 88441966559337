import {
	isBiggerThan0,
	isNotExponent,
	isRequired,
	isShorterThan,
} from 'core/services/form';

import { deleteExpenseFile } from './expenses.service';

export const findTypeById = (expenseTypes, id) => {
	return expenseTypes.find(type => type.id == id);
};

export const findTypeByName = (expenseTypes, name) => {
	return expenseTypes.find(type => type.name == name);
};

export const expenseTypeField = {
	type: 'select',
	label: 'Type onkost',
	placeholder: 'Selecteer een optie',
	validations: [isRequired],
	required: true,
	value: '',
};

export const dateField = {
	name: 'date',
	type: 'datepicker',
	value: [],
	errors: [],
	mode: 'single',
	validations: [isRequired],
};

export const projectIdField = {
	type: 'select',
	label: 'Project',
	placeholder: 'Selecteer een project',
	validations: [isRequired],
	required: true,
	value: '',
};

export const amountField = {
	type: 'number',
	label: 'Bedrag',
	placeholder: '€ 0,00',
	validations: [isRequired, isNotExponent, isBiggerThan0],
	required: true,
};

export const uploadField = {
	type: 'file',
	label: 'Onkostenbewijs',
	multiple: true,
	onDelete: async file => {
		await deleteExpenseFile(file.id);
	},
	value: [],
};

export const remarkField = {
	type: 'textarea',
	name: 'remark',
	label: 'Opmerking',
	validations: [isShorterThan(50)],
};
