import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import snackbarFactories from 'core/factories/snackbar.factories';
import { dispatchAction, dispatchMedia } from 'core/services/actions';
import { db } from 'dexie-db';

import { loadAvailableStickers } from '../stickers/sticker.actions';
import { store } from '../store.config';

export const logoutUser = () => {
	dispatchAction({
		type: ACTION_TYPES.AUTH_LOGOUT,
		payload: {},
		meta: {
			action: {
				effect: {
					url: '/logout',
					method: 'get',
					retry: false,
				},
				commit: { type: ACTION_TYPES.AUTH_LOGOUT_COMMIT },
			},
			followup: [clearStorage],
		},
	});
};

export const loadProfile = () => {
	const state = store.getState();
	const { meInfoFetched, loginTries } = state.authReducer;
	if (!meInfoFetched && loginTries < 5) {
		dispatchAction({
			type: ACTION_TYPES.AUTH_LOAD_PROFILE,
			payload: { authLoading: true, loginTries: loginTries + 1 },
			meta: {
				action: {
					effect: {
						url: `/auth/me`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.AUTH_LOAD_PROFILE_COMMIT },
					rollback: {
						type: ACTION_TYPES.AUTH_LOGOUT_COMMIT,
						followup: [clearStorage],
					},
				},
				followup: [loadUserSettings],
			},
		});
	}
};

export const updateUserData = updatedData => {
	dispatchAction({
		type: ACTION_TYPES.USER_UPDATE,
		payload: updatedData,
	});
};

const loadUserSettings = store => {
	//TODO: set listener for changes in auth user (role, permissions, picture, contacts + pictures, settings)
	const { authPicture, settings } = store.authReducer;
	if (authPicture) dispatchMedia(authPicture, true);
	loadAvailableStickers();

	const darkThemeMediaQuery = window.matchMedia(
		'(prefers-color-scheme: dark)',
	);
	const mode = settings;

	dispatchAction({
		type: ACTION_TYPES.THEME_MODE_UPDATE,
		payload: mode,
	});

	dispatchAction({
		type: ACTION_TYPES.THEME_UPDATE,
		payload:
			mode.theme === 'auto'
				? darkThemeMediaQuery.matches
					? 'dark'
					: 'light'
				: mode.theme,
	});
};

export const resetAccount = () => {
	/*ExecuteAction({
      meta: {
        action: {
          effect: {
            url: '/users/reset',
            method: 'post',
            body: data
          },
          commit: {
            type: TYPES.TOAST_ADD,
            payload: {
              toast: snackbarFactories({ text: 'Je account is hersteld.', type: 'success' })
            }
          }
        }
      }
    });*/
};

export const activateAccount = () => {
	/*ExecuteAction({
      type: TYPES.AUTH_LOGIN,
      payload: { authLoading: true },
      meta: {
        action: {
          effect: {
            url: '/register',
            method: 'post',
            body: data,
            retry: false,
            addHeader: false
          },
          commit: { type: TYPES.AUTH_LOGIN_COMMIT },
          rollback: {
            type: TYPES.AUTH_LOGOUT,
            followup: [clearLocalStorage]
          }
        },
        followup: [loadProfile]
      }
    });*/
};

export const loadContacts = () => {
	dispatchAction({
		type: ACTION_TYPES.AUTH_LOAD_CONTACTS,
		meta: {
			action: {
				effect: {
					url: `/auth/contacts`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.AUTH_LOAD_CONTACTS_COMMIT },
			},
			followup: [loadContactPictures],
		},
	});
};

const loadContactPictures = store => {
	const { contacts } = store.authReducer;
	contacts.forEach(contact => dispatchMedia(contact.picture, true));
};

const clearStorage = () => {
	localStorage.clear();
	db.delete();
};

export const putBiography = bio => {
	dispatchAction({
		type: ACTION_TYPES.UPDATE_BIOGRAPHY,
		payload: bio,
		meta: {
			action: {
				effect: {
					url: '/auth/me/bio',
					method: 'put',
					body: { bio },
				},
				rollback: {},
				commit: {
					type: ACTION_TYPES.TOAST_ADD,
					payload: {
						toast: snackbarFactories({
							text: 'Je gegevens zijn correct opgeslagen.',
							type: 'success',
						}),
					},
				},
			},
		},
	});
};

export const putSocials = socials => {
	dispatchAction({
		type: ACTION_TYPES.UPDATE_SOCIAL_MEDIA,
		payload: socials,
		meta: {
			action: {
				effect: {
					url: '/auth/me/socials',
					method: 'put',
					body: { socials },
				},
				rollback: {},
				commit: {
					type: ACTION_TYPES.TOAST_ADD,
					payload: {
						toast: snackbarFactories({
							text: 'Je gegevens zijn correct opgeslagen.',
							type: 'success',
						}),
					},
				},
			},
		},
	});
};
