import React, {
	createElement,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import './RenderIfVisible.scss';
const RenderIfVisible = ({
	initialVisible = false,
	visibleOffset = 1000,
	stayRendered = false,
	root = null,
	rootElement = 'div',
	rootElementClass = '',
	placeholderElement = 'div',
	placeholderElementClass = '',
	children,
}) => {
	const [isVisible, setIsVisible] = useState(initialVisible);

	const wasVisible = useRef(initialVisible);
	const intersectionRef = useRef(null);

	const rootClasses = useMemo(
		() => `renderIfVisible ${rootElementClass}`,
		[rootElementClass],
	);

	const placeholderClasses = useMemo(
		() => `renderIfVisible-placeholder ${placeholderElementClass}`,
		[placeholderElementClass],
	);

	useEffect(() => {
		if (intersectionRef.current) {
			const localRef = intersectionRef.current;
			const observer = new IntersectionObserver(
				entries => {
					if (
						typeof window !== 'undefined' &&
						window.requestIdleCallback
					) {
						window.requestIdleCallback(
							() => setIsVisible(entries[0].isIntersecting),
							{
								timeout: 600,
							},
						);
					} else {
						setIsVisible(entries[0].isIntersecting);
					}
				},
				{
					root: document.querySelector('#root main') || root,
					rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px`,
				},
			);

			observer.observe(localRef);
			return () => {
				if (localRef) {
					observer.unobserve(localRef);
				}
			};
		}
		return () => {};
	}, [root, visibleOffset]);

	useEffect(() => {
		if (isVisible) {
			wasVisible.current = true;
		}
	}, [isVisible]);

	return createElement(rootElement, {
		children:
			isVisible || (stayRendered && wasVisible.current) ? (
				// false positive
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>{children}</>
			) : (
				createElement(placeholderElement, {
					className: placeholderClasses,
				})
			),
		ref: intersectionRef,
		className: rootClasses,
	});
};

export default RenderIfVisible;
