import { useRef, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';

import Modal from 'components/Modal/Modal';

import { AbsenceTypes } from '../VacationModal/vacation.modal.helper';

import './VacationCounterModal.scss';

const VacationCounterModal = ({ modal, setModal }) => {
	const {
		data: { counters, requested, available },
	} = modal;
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const ref = useRef(null);
	const parseCounters = ({ type, prediction }) => {
		const counter = {
			label: type?.name,
			value: prediction?.counter || 0,
		};

		if (type.machineName == AbsenceTypes.BANK_HOLIDAY) {
			counter.value = prediction.prediction?.availableNow;
			counter.extra = prediction.prediction?.freeHolidays
				?.filter(feestdag => !feestdag.availableNow)
				.map(feestdag => ({
					label: `${feestdag.name} vanaf ${toShortDate(
						feestdag.asOff,
					)}`,
					value: feestdag.counter,
				}));
		} else if (type.machineName == AbsenceTypes.ADV) {
			counter.value = prediction.counter?.toFixed(2);
			counter.extra = [
				{
					label: 'Voorspeld',
					value: prediction.prediction?.predicted,
				},
			];
		} else if (
			type.machineName === AbsenceTypes.SENIORITY_LEAVE &&
			prediction.prediction?.asOff
		) {
			counter.extra = [
				{
					label: `vanaf ${prediction.prediction?.asOff}`,
					value: prediction.prediction?.predicted,
				},
			];
		} else if (type.machineName === AbsenceTypes.RECOVERY_LEAVE) {
			counter.extra = prediction.prediction?.availability?.map(
				availability => ({
					label: `tot ${toShortDate(availability.date)}`,
					value: availability.counter,
				}),
			);
		}

		return counter;
	};

	const showExtraInfo = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	return (
		<Modal
			ref={ref}
			show={!!modal}
			title='Vakantieteller'
			small
			close={() => setModal(null)}
			className='vacation-modal'
			actions={[
				{
					label: <FontAwesomeIcon icon={faInfoCircle} size='xl' />,
					action: showExtraInfo,
					buttonType: 'link',
					styles: { float: 'left' },
				},
			]}>
			<section className='vacation-counters'>
				<section className='counters'>
					{counters
						? counters.map(parseCounters).map((counter, i) => (
								<div className='counter' key={i}>
									<div className='counter__main'>
										<span className='counter__label'>
											{counter.label}
										</span>
										<span className='counter__value'>
											{(+counter.value)
												.toFixed(2)
												.replace('.', ',')}{' '}
											u
										</span>
									</div>
									{counter.extra
										? counter.extra.map((item, j) => (
												<div
													className='counter__info'
													key={`${i}${j}`}>
													<span className='counter__info__label'>
														{item.label}
													</span>
													<span className='counter__info__value'>
														{item.value
															.toFixed(2)
															.replace(
																'.',
																',',
															)}{' '}
														u
													</span>
												</div>
											))
										: null}
								</div>
							))
						: null}
				</section>
				<section className='total'>
					<div className='total__requested'>
						<span>Aangevraagd</span>
						<span>
							{(requested || 0).toFixed(2).replace('.', ',')} u
						</span>
					</div>
					<div
						className={`total__available--${
							available >= 0 || !available
								? 'positive'
								: 'negative'
						}`}>
						<span>Beschikbaar</span>
						<span>
							{(available || 0).toFixed(2).replace('.', ',')} u
						</span>
					</div>
				</section>
				{isPopoverOpen ? (
					<section className='vacation-info'>
						<div>
							Vakantietellers zijn weergegeven in uren.
							<br />
							De voorspelde ADV-opbouw is een indicatie en wordt
							berekend op basis van de duurtijd van je huidige
							project(en). Het effectief opgebouwde ADV kan
							afwijken van de voorspelling.
							<br />
							Indien je project eindigt, zal eerst ADV worden
							afgerekend gedurende de periode dat je op de bank
							zit. Je voorspelde teller houdt hier geen rekening
							mee net zoals er geen rekening wordt gehouden met
							ziekte.
							<br />
							ADV wordt opgebouwd per werkuur. Het is mogelijk dat
							je meer of minder dan 8 uur per maand opbouwt. Je
							hebt wel altijd recht op 96 uur per jaar tenzij je
							ziek bent.
							<br />
							<br />
							Vakantietypes worden bepaald tijdens de indiening
							van je timesheet volgens beschikbaarheid. Er wordt
							rekening gehouden met de ADV die je opbouwt tijdens
							de registratie, er wordt geen rekening gehouden met
							de voorspelling.
							<br />
							Als je meer afwezigheid wil indienen dan
							beschikbaar, zal een onbetaalde afwezigheid worden
							geregistreerd.
						</div>
					</section>
				) : null}
			</section>
		</Modal>
	);
};

export default VacationCounterModal;
