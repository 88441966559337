/* eslint-disable no-unused-vars */
import { forwardRef, useEffect, useState } from 'react';
import useClickOutside from 'core/hooks/useClickOutside';

import { useEvents } from './useEvents';

import './SelectBlock.scss';
// create a scrollable select block that shows 3 options from the available where the one in the middle is the selected one
// something like tge IOS datepicker
const SelectBlock = ({ options, onChange, close, initialValue }) => {
	const ref = useClickOutside(close);
	const { allOptions, selectedIndex } = useEvents(options, initialValue);

	return (
		<div className='select-block' ref={ref}>
			<div className='select-block__view'>
				{allOptions
					.slice(selectedIndex - 1, selectedIndex + 2)
					.map((option, index) => (
						<div
							key={index}
							tabIndex={index}
							className={`select-block__view__option ${
								index === 1 ? 'selected' : ''
							}`}
							onClick={e => {
								if (option.value) {
									e.stopPropagation();
									e.preventDefault();
									onChange(option.value);
									close();
								}
							}}>
							{option.label}
						</div>
					))}
			</div>
		</div>
	);
};

export default SelectBlock;
