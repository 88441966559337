import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadCoces = () => {
	const state = store.getState();
	const { cocesLoading, cocesFetched } = state.cocesReducer;
	if (!cocesLoading && !cocesFetched) {
		dispatchAction({
			type: ACTION_TYPES.COCES_LOAD,
			payload: { cocesLoading: true },
			meta: {
				action: {
					effect: {
						url: `/coces`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.COCES_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.COCES_LOAD,
						payload: { cocesLoading: false },
					},
				},
			},
		});
	}
};
