import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	faCarBurst,
	faCheck,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSubmit } from 'core/hooks/useSubmit';
import { localDate } from 'core/services/date';
import { isRequired } from 'core/services/form';
import { selectCar } from 'core/store/cars/car.actions';
import { number, shape, string } from 'prop-types';
import InfoCard from 'views/Timesheets/InfoCard/InfoCard';

import {
	Button,
	Datepicker,
	InputCheckbox,
	InputRadioButton,
	Textarea,
	Upload,
	useForm,
} from 'components/Form';
import { Col, Container, Row } from 'components/Grid';

import OngevalModal from './OngevalModal/OngevalModal';
import { createModalData } from './accident.helper';
import {
	deleteDamage,
	deleteFile,
	getDamages,
	sendDamage,
} from './accident.service';

import './Ongeval.scss';

export const Ongeval = () => {
	const [reports, setReports] = useState([]);
	const [modal, setModal] = useState(null);
	const [editReport, setEditReport] = useState(null);
	const { id: reportId } = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const pageBaseUrl = `/${pathname
		.split('/')
		.filter(part => Number.isNaN(+part))
		.join('/')}`;

	const questionsForm = useForm();
	const damageForm = useForm();

	const { selectedCar } = useSelector(state => state.carReducer);

	useEffect(() => {
		getDamages().then(setReports);
	}, []);

	const { id, insurance = {} } = selectedCar || {};

	const handleChange = id => {
		const report = reports.find(report => report.id === id);
		setEditReport(report);
		setReports(prev => prev.filter(report => report.id !== id));
		selectCar({ id: report.carId });
		damageForm.setValue('date', [report.date]);
		damageForm.setValue('status', report.status);
		damageForm.setValue('service', report.service);
		damageForm.setValue('description', report.description);
		if (report.attachments) {
			damageForm.setValue(
				'upload',
				report.attachments.map(file => ({
					id: file.id,
					name: file.description,
					type: file.mime,
				})),
			);
		}
	};

	const handleDelete = async id => {
		const deleted = await deleteDamage(id);
		if (deleted) {
			setReports(prev => prev.filter(report => report.id !== id));
		}
	};

	const addReportToList = report => {
		setReports(prev => {
			return [...prev.filter(({ id }) => id !== report.id), report];
		});
		setEditReport(null);
	};

	const handleSubmit = useSubmit(async () => {
		const damage = {
			date: damageForm.getValue('date')[0],
			status: damageForm.getValue('status') ? 1 : 0,
			service: damageForm.getValue('service') ? 1 : 0,
			description: damageForm.getValue('description'),
			attachments: damageForm.getValue('upload'),
			...(editReport && { id: editReport.id }),
		};

		const created = await sendDamage({ ...damage, carId: id });

		if (created?.id) {
			addReportToList(created);
			damageForm.reset();
			navigate(pageBaseUrl);
		}
	});

	const handleFileDelete = async file => {
		await deleteFile(file.id);
	};

	const handleCancel = () => {
		if (editReport) {
			addReportToList(editReport);
		}
		damageForm.reset();
		navigate(pageBaseUrl);
	};

	useEffect(() => {
		if (reportId && reports.length) {
			const report = reports.find(report => report.id === +reportId);
			if (report) {
				handleChange(report.id);
			}
		}
	}, [reportId, reports]);

	const wounded = questionsForm.getValue('wounded');
	const known = questionsForm.getValue('known');

	return (
		<section className='accident'>
			{reports.length && !editReport ? (
				<section className='accident__report-list'>
					{reports
						.filter(report => report.carId === selectedCar?.id)
						.map(({ id, date, status }, i) =>
							status ? null : (
								<InfoCard
									key={i}
									handleChange={() =>
										navigate(`${pageBaseUrl}/${id}`)
									}
									handleDelete={() => handleDelete(id)}
									title={localDate(date)}
									infoIcon={faCarBurst}
								/>
							),
						)}
				</section>
			) : null}
			{!editReport ? (
				<section className='accident__section'>
					<section className='accident__section__header'>
						<div className='accident__section__title'>
							Had je een ongeval?
						</div>
						<div className='accident__section__description'>
							<p>
								Heb je kort na het ongeval ergens pijn? Ga zo
								snel mogelijk naar de dokter. Dit moet zo snel
								mogelijk geregistreerd en doorgegeven worden!
							</p>
						</div>
					</section>
					<section className='accident__form__content'>
						<Container>
							<Row>
								<Col
									xl={4}
									lg={12}
									className='accident__form__question'>
									<InputRadioButton
										{...questionsForm.register('wounded', {
											type: 'radio',
											label: 'Zijn er gewonden?',
											required: true,
											options: [
												{ label: 'Ja', value: 'yes' },
												{ label: 'Nee', value: 'no' },
											],
										})}
									/>
								</Col>

								<Col
									xl={8}
									lg={12}
									className='accident__form__info'>
									{wounded ? (
										<div className='accident__form__info__text'>
											{wounded === 'no' ? (
												<span>
													<span>
														Hinder het verkeer niet.
													</span>
													<br />
													<span>
														Zet de voertuigen aan de
														kant{' '}
														<strong>NADAT</strong>{' '}
														je{' '}
														<strong>
															foto&apos;s
														</strong>{' '}
														van de situatie hebt
														genomen.
													</span>
												</span>
											) : null}
											{wounded === 'yes' ? (
												<span>
													Bel indien nodig de{' '}
													<strong className='accident__form__urgent-numbers'>
														100
													</strong>{' '}
													of{' '}
													<strong className='accident__form__urgent-numbers'>
														112
													</strong>{' '}
													(Europees alarmnummer).
													Vermeld de identiteit van de
													slachtoffers aan de
													achterzijde van het
													aanrijdingsformulier!
												</span>
											) : null}
										</div>
									) : null}
								</Col>

								<Col
									xl={4}
									lg={12}
									className='accident__form__question'>
									<InputRadioButton
										{...questionsForm.register('known', {
											type: 'radio',
											label: 'Ken je de tegenpartij?',
											required: true,
											options: [
												{ label: 'Ja', value: 'yes' },
												{ label: 'Nee', value: 'no' },
											],
										})}
									/>
								</Col>
								<Col
									xl={8}
									lg={12}
									className='accident__form__info'>
									{known ? (
										<div className='accident__form__info__text'>
											{known === 'yes' ? (
												<div className='accident__form__info__known'>
													<span>
														Kijk bij{' '}
														<strong>
															&quot;Het
															aanrijdingsformulier&quot;
														</strong>{' '}
														voor alle standaardinfo
														die je in aangifte moet
														invullen.
													</span>
													<br />
													<span>
														<FontAwesomeIcon
															icon={faCheck}
															className='accident__form__info__check'
														/>
														De tegenpartij wil WEL
														tekenen
													</span>
													<ul>
														<li>
															Vul samen het
															aanrijdingsformulier
															in.
														</li>
													</ul>
													<br />
													<span>
														<FontAwesomeIcon
															icon={faXmark}
															className='accident__form__info__close'
														/>
														De tegenpartij wil NIET
														tekenen
													</span>
													<ul>
														<li>
															Noteer de
															nummerplaat van de
															tegenpartij.
														</li>
														<li>
															Neem duidelijke
															foto&apos;s van de
															schade aan jouw
															wagen en die van de
															tegenpartij.
														</li>
														<li>
															Neem foto&apos;s van
															de situatie.
														</li>
													</ul>
												</div>
											) : null}
											{known === 'no' ? (
												<div className='accident__form__info__unknown'>
													Vul het aanrijdingsformulier
													alleen in. Kijk bij
													&quot;het
													aanrijdingsformulier&quot;
													voor alle standaardinfo die
													je in aangifte moet
													invullen. Laat Proces
													Verbaal opmaken bij de
													politie.
												</div>
											) : null}
										</div>
									) : null}
								</Col>
							</Row>
							<Row className='accident__form__generate-form'>
								<Button
									buttonStyle='primary'
									onClick={() =>
										setModal(createModalData(insurance))
									}>
									Het aanrijdingsformulier
								</Button>
							</Row>
						</Container>
					</section>
				</section>
			) : null}
			<section className='accident__section'>
				<section className='accident__section__header'>
					<div className='accident__section__title'>Meld schade</div>
					<div className='accident__section__description'>
						{selectedCar?.info?.formInfo ? (
							<p
								dangerouslySetInnerHTML={{
									__html: selectedCar?.info?.formInfo?.replace(
										/\r\n/g,
										'<br />',
									),
								}}
							/>
						) : (
							<p className='text'>
								Vul onderstaand formulier in of bel naar de
								verzekeringsmaatschappij. Indien nodig geven zij
								hulp bij het invullen van het
								aanrijdingsformulier.
							</p>
						)}
					</div>
				</section>
				<section className='accident__report-damage__content'>
					<Container>
						<Row>
							<Col
								xl={4}
								lg={12}
								className='accident__report-damage__date'>
								<Datepicker
									{...damageForm.register('date', {
										value: [],
										disabledDays: [{ after: new Date() }],
										validations: [isRequired],
									})}
								/>
							</Col>
							<Col xl={1} lg={12} />
							<Col
								xl={7}
								lg={12}
								className='accident__report-damage__status'>
								<InputCheckbox
									{...damageForm.register('status', {
										label: 'Deze aangifte is volledig',
										disabled:
											!damageForm.getValue('date')
												?.length,
										value: false,
										description:
											'Een melding die volledig is, kan niet meer worden aangepast. Let erop dat je alle benodigde bijlagen en informatie hebt opgeladen alvorens deze checkbox aan te vinken. Zodra volledig zal dit schadedossier aan ' +
											(insurance.company ||
												'de verzekeraar') +
											' worden bezorgd.',
									})}
								/>
								<InputCheckbox
									{...damageForm.register('service', {
										label: `Er was contact met ${
											insurance.company ||
											'de verzekeraar'
										}`,
										value: false,
									})}
								/>
							</Col>
						</Row>
						<Row>
							<Col xl={6} lg={12}>
								<Upload
									{...damageForm.register('upload', {
										label: "Aangifteformulier en foto's",
										validations: [],
										value: [],
										multiple: true,
										onDelete: handleFileDelete,
									})}
								/>
							</Col>
							<Col xl={6} lg={12}>
								<Textarea
									{...damageForm.register('description', {
										label: 'Opmerking',
										value: '',
									})}
								/>
							</Col>
						</Row>
						<Row>
							<Col className='accident__report-damage__buttons'>
								{damageForm.isDirty() ? (
									<Button
										buttonStyle='secondary'
										onClick={handleCancel}>
										Annuleren
									</Button>
								) : null}
								<Button
									buttonStyle='primary'
									disabled={
										!damageForm.isDirty() ||
										!damageForm.isValid()
									}
									onClick={handleSubmit}>
									Bewaren
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
			</section>
			{modal ? <OngevalModal modal={modal} setModal={setModal} /> : null}
		</section>
	);
};

Ongeval.defaltProps = {
	car: [
		{
			id: null,
			insurance: {
				company: null,
				telephone: null,
			},
		},
	],
};

Ongeval.propTypes = {
	car: shape([
		{
			id: number,
			insurance: shape({
				company: string,
				telephone: string,
			}),
		},
	]),
};
