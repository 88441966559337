const defaultState = {
	alerts: [],
	alertsLoading: false,
};

const loadAlerts = (state, { alertsLoading }) => ({
	...state,
	alertsLoading,
});

const loadAlertsCommit = (state, { data }) => {
	return {
		...state,
		alerts: data,
		alertsLoading: false,
	};
};

const updateAlerts = (state, { alert, action }) => {
	const { alerts } = state;
	const alertExists = alerts.find(({ id, type }) => {
		if (type.includes('timesheet')) {
			return type === alert.type;
		}
		return id == alert.id && type === alert.type;
	});

	if (action === 'add' && !alertExists) {
		const { date, type, ...content } = alert;
		return {
			...state,
			alerts: [
				...alerts,
				{
					type,
					id: content.id,
					content: {
						...content,
						lastAttendDate: date,
					},
				},
			],
		};
	} else if (action === 'remove' && alertExists) {
		return {
			...state,
			alerts: alerts.filter(({ id }) => id != alert.id),
		};
	}
	return state;
};

export const alertReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case 'ALERTS_LOAD':
			return loadAlerts(state, payload);
		case 'ALERTS_LOAD_COMMIT':
			return loadAlertsCommit(state, payload);
		case 'ALERTS_UPDATE':
			return updateAlerts(state, payload);
		default:
			return state;
	}
};
