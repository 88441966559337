import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	faArrowLeftLong,
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { executeAxios } from 'core/services/actions';
import { toFullMonthYear } from 'core/services/date';
import { parseToDecimal } from 'core/services/parse';

import {
	countTotals,
	formatDate,
	parseRegistration,
} from './registrationNota.helpers';

import './RegistrationNote.scss';

export const RegistrationNote = () => {
	const { state } = useLocation();

	const absenceTypes = ['Z', 'BF', 'V', 'ANC', 'AND'];

	const [registration, setRegistration] = useState(null);
	const [startCounter, setStartCounter] = useState(null);
	const [total, setTotal] = useState(null);
	const [adv, setAdv] = useState(0);
	const [overtime, setOvertime] = useState(0);
	const [year, setYear] = useState(state.year);
	const [month, setMonth] = useState(state.month);

	useEffect(() => {
		executeAxios({
			url: `/timesheets/registration-note?year=${year}&month=${month}`,
		})
			.then(({ data: { data } }) => {
				setRegistration(parseRegistration(data.registrationNote));
				setStartCounter(data.startCounter);
				setTotal(countTotals(data.registrationNote));
			})
			.catch(error => {
				console.error('error', error);
			});
	}, [year, month]);

	useEffect(() => {
		setAdv((total?.advAdd ?? 0) - (total?.advDistract ?? 0));
		setOvertime((total?.overtimeAdd ?? 0) - (total?.overtimeDistract ?? 0));
	});

	return registration ? (
		<section className='registration-note'>
			<h5 className='registration-note__go-back'>
				<Link to='/timesheet/tekenen/overzicht'>
					<FontAwesomeIcon
						icon={faArrowLeftLong}
						className='icon'
						fixedWidth
					/>{' '}
					Terug naar overzicht
				</Link>
			</h5>
			<h1>Registratienota</h1>
			<div className='registration-note__content'>
				<table className='table'>
					<thead>
						<tr>
							<th>{toFullMonthYear(`${year}-${month}-1`)}</th>
							<th colSpan={2}>Norm.</th>
							<th colSpan={3}>Overuren</th>
							<th colSpan={2} className='b-left-2 b-right-2'>
								ADV
							</th>
							<th colSpan={2} className='b-right-2'>
								Overuren
							</th>
							<th colSpan={6} align='right'>
								<div className='btn__group'>
									<div
										className='btn btn--link btn--small'
										onClick={() => {
											setMonth(
												month !== 1 ? month - 1 : 12,
											);
											setYear(
												month !== 1 ? year : year - 1,
											);
										}}>
										<FontAwesomeIcon icon={faChevronLeft} />{' '}
										vorige
									</div>
									<div
										className='btn btn--link btn--small'
										onClick={() => {
											setMonth(
												month !== 12 ? month + 1 : 1,
											);
											setYear(
												month !== 12 ? year : year + 1,
											);
										}}>
										volgende{' '}
										<FontAwesomeIcon
											icon={faChevronRight}
										/>
									</div>
								</div>
							</th>
						</tr>
						<tr>
							<th />
							<th>
								100% <br />
								Fact.
							</th>

							<th>
								Niet <br />
								Fact.
							</th>
							<th>100%</th>
							<th>150%</th>
							<th>200%</th>
							<th className='b-left-2'>+</th>
							<th className='b-right-2'>-</th>
							<th>+</th>
							<th className='b-right-2'>-</th>
							{absenceTypes.map(x => (
								<th>{x}</th>
							))}
							<th>Opmerkingen</th>
						</tr>
						<tr>
							<td colSpan={6} />
							<td colSpan={2} className='b-left-2 b-right-2'>
								{parseToDecimal(startCounter.ADV)}
							</td>
							<td colSpan={2} className='b-right-2'>
								{parseToDecimal(startCounter.Overuren)}
							</td>
							{absenceTypes.map(x => (
								<td>
									{x !== 'Z'
										? parseToDecimal(startCounter[x], 1)
										: ''}
								</td>
							))}
							<td>Beginsaldo</td>
						</tr>
					</thead>
					<tbody>
						{Object.keys(registration).map(weekNumber => (
							<Week
								key={weekNumber}
								weekNumber={weekNumber}
								weekData={registration[weekNumber]}
							/>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td />
							<td>{parseToDecimal(total.normFact ?? 0)}</td>
							<td>{parseToDecimal(total.normNonFact ?? 0)}</td>
							<td>{parseToDecimal(total.overtimeH100 ?? 0)}</td>
							<td>{parseToDecimal(total.overtimeH150 ?? 0)}</td>
							<td>{parseToDecimal(total.overtimeH200 ?? 0)}</td>
							<td className='b-left-2'>
								{parseToDecimal(total.advAdd ?? 0)}
							</td>
							<td className='b-right-2'>
								{parseToDecimal(total.advDistract ?? 0)}
							</td>
							<td>{parseToDecimal(total.overtimeAdd ?? 0)}</td>
							<td className='b-right-2'>
								{parseToDecimal(total.overtimeDistract ?? 0)}
							</td>
							{absenceTypes.map(x => (
								<td>
									{parseToDecimal(
										total[`absences${x}`] ?? 0,
										1,
									)}
								</td>
							))}
							<td>Maand totaal</td>
						</tr>
						<tr>
							<td colSpan={6} />
							<td className='b-left-2'>
								{adv < 0 ? '' : parseToDecimal(adv)}
							</td>
							<td className='b-right-2'>
								{adv < 0 ? parseToDecimal(-adv) : ''}
							</td>
							<td>
								{overtime < 0 ? '' : parseToDecimal(overtime)}
							</td>
							<td className='b-right-2'>
								{overtime < 0 ? parseToDecimal(-overtime) : ''}
							</td>
							<td colSpan={5} />
							<td>subtotaal</td>
						</tr>
						<tr>
							<td colSpan={6} />
							<td colSpan={2} className='b-left-2 b-right-2'>
								{parseToDecimal(startCounter.ADV + adv)}
							</td>
							<td colSpan={2} className='b-right-2'>
								{parseToDecimal(
									startCounter.Overuren + overtime,
								)}
							</td>
							{absenceTypes.map(x => (
								<td>
									{x !== 'Z' && x !== 'AND'
										? parseToDecimal(
												(startCounter[x] ?? 0) -
													(total[`absences${x}`] ??
														0),
												1,
											)
										: ''}
								</td>
							))}
							<td>Over te dragen</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</section>
	) : null;
};

const Week = ({ weekNumber, weekData }) => {
	return (
		<>
			<tr className='week-number'>
				<th>Week {weekNumber}</th>
				<th colSpan={15} />
			</tr>
			{weekData.days.map(day => (
				<TableRow day={day} key={day.date} />
			))}
			<TableRow day={weekData.summary} className='week-summary' />
		</>
	);
};

const TableRow = ({ day, className }) => {
	return (
		<tr
			className={`${className ? className : ''}${day.isWeekend ? ' weekend' : ''}`}>
			<td>{day.date ? formatDate(day.date) : ''}</td>
			<td>{day.normFact?.toFixed(2)}</td>
			<td>{day.normNonFact?.toFixed(2)}</td>
			<td>{day.overtimeH100?.toFixed(2)}</td>
			<td>{day.overtimeH150?.toFixed(2)}</td>
			<td>{day.overtimeH200?.toFixed(2)}</td>
			<td className='b-left-2'>{day.advAdd?.toFixed(2)}</td>
			<td className='b-right-2'>{day.advDistract?.toFixed(2)}</td>
			<td>{day.overtimeAdd?.toFixed(2)}</td>
			<td className='b-right-2'>{day.overtimeDistract?.toFixed(2)}</td>
			<td>{day.absencesZ?.toFixed(1)}</td>
			<td>{day.absencesBF?.toFixed(1)}</td>
			<td>{day.absencesV?.toFixed(1)}</td>
			<td>{day.absencesANC?.toFixed(1)}</td>
			<td>{day.absencesAND?.toFixed(1)}</td>
			<td>
				{day.remark
					? day.remark.map((line, i) => (
							<span key={i}>
								{line}
								<br />
							</span>
						))
					: day.date
						? ''
						: 'Weektotaal'}
			</td>
		</tr>
	);
};
