import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';

export const postForm = async data => {
	try {
		const {
			data: { data: response },
		} = await executeAxios({
			url: '/forms',
			method: 'POST',
			data,
		});
		handleAlert('success', 'Formulier is succesvol verstuurd');
		return response;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		throw error;
	}
};
