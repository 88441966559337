import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	cars: null,
	selectedCar: null,
	carsLoading: false,
};

const loadCar = (state, { carsLoading }) => ({
	...state,
	carsLoading,
});

const selectCar = (state, { carId }) => ({
	...state,
	selectedCar: state.cars.find(car => car.id === carId),
});

const loadCarCommit = (state, { data }) => {
	return {
		...state,
		cars: data,
		selectedCar: data[0],
		carsLoading: false,
	};
};

export const carReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.CAR_FLEET_LOAD:
			return loadCar(state, payload);
		case ACTION_TYPES.CAR_FLEET_LOAD_COMMIT:
			return loadCarCommit(state, payload);
		case ACTION_TYPES.CAR_SELECT:
			return selectCar(state, payload);
		default:
			return state;
	}
};
