import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { getInviteStatus } from 'core/store/invites/invite.actions';

import { updateStickersOfStickerable } from '../stickers/sticker.utils';

let defaultState = {
	events: [],
	eventsLoading: false,
	eventDetails: null,
};

const loadEvents = (state, { eventsLoading }) => ({
	...state,
	eventsLoading,
});

//Hier specifieren welk soort type data het is : bv hier events dus.
const loadEventsCommit = (state, { data }) => {
	//const hasNextPage = meta.current_page < meta.last_page;
	return {
		...state,
		events: data.map(event => ({ ...event, isLoaded: false })),
		eventsLoading: false,
	};
};

const loadEventCommit = (state, { data }) => {
	getInviteStatus(data.id, 'events');
	return {
		...state,
		eventDetails: data,
	};
};

const updateStickersOfEvents = updateStickersOfStickerable('event');

export const eventReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.EVENT_FEEDS_LOAD:
			return loadEvents(state, payload);
		case ACTION_TYPES.EVENT_FEEDS_LOAD_COMMIT:
			return loadEventsCommit(state, payload);
		case ACTION_TYPES.EVENT_LOAD_COMMIT:
			return loadEventCommit(state, payload);
		case ACTION_TYPES.STICKER_ADD:
			return updateStickersOfEvents(state, payload, 'ADD');
		case ACTION_TYPES.STICKER_DELETE:
			return updateStickersOfEvents(state, payload, 'DELETE');
		default:
			return state;
	}
};
