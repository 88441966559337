import { useState } from 'react';
import axios from 'axios';

const useLoader = basePath => {
	const [isLoading, setIsLoading] = useState(false);

	const updateState = (config, state) => {
		if (config.url.includes(basePath)) {
			setIsLoading(state);
		}
	};

	axios.interceptors.request.use(
		config => {
			updateState(config, true);
			return config;
		},
		error => {
			updateState(error.config, false);
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		response => {
			updateState(response.config, false);
			return response;
		},
		error => {
			updateState(error.config, false);
			return Promise.reject(error);
		},
	);

	return {
		isLoading,
		setIsLoading,
	};
};

export default useLoader;
