import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadDepartments = () => {
	const state = store.getState();
	const { departmentsLoading, departmentsFetched } = state.departmentsReducer;
	if (!departmentsLoading && !departmentsFetched) {
		dispatchAction({
			type: ACTION_TYPES.DEPARTMENTS_LOAD,
			payload: { departmentsLoading: true },
			meta: {
				action: {
					effect: {
						url: `/departments`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.DEPARTMENTS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.DEPARTMENTS_LOAD,
						payload: { departmentsLoading: false },
					},
				},
			},
		});
	}
};
