import Icon from 'components/Icon/Icon';

const PlacedStickersPopover = ({ placedStickers }) => (
	<div className='placed-sticker-popover'>
		<ul>
			{Object.keys(placedStickers).length > 0 ? (
				Object.keys(placedStickers).map(stickerId =>
					placedStickers[stickerId].authors.map(author => (
						<li key={author.placedId}>
							<Icon
								icon={placedStickers[stickerId].sticker.class}
							/>
							{author.name}
						</li>
					)),
				)
			) : (
				<li>geen stickers geplaatst</li>
			)}
		</ul>
	</div>
);

export default PlacedStickersPopover;
