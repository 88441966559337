import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
	faArrowLeftLong,
	faBirthdayCake,
	faBusinessTime,
	faEnvelope,
	faPhone,
	faUsers,
	faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { updateUserData } from 'core/store/authentication/auth.actions';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import Image from 'components/Image/Image';
import Loading from 'components/Loading/Loading';
import SocialMedia from 'components/SocialMedia/SocialMedia';

import {
	createImpersonatedUserData,
	switchBackToAuthenticatedUser,
} from './employee.helper';
import {
	getEmployee,
	startImpersonating,
	stopImpersonating,
} from './employee.service';

// import Stickers from 'components/Stickers/Stickers';
import './EmployeeDetail.scss';

const EmployeeDetail = () => {
	const { slug } = useParams();
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		getEmployee(slug).then(setUserData);
	}, [slug]);

	const [emptyInterview, setEmptyInterview] = useState(true);
	const userState = useSelector(state => state.authReducer);
	const authenticatedUser = userState?.authenticatedUser;
	const impersonating =
		userState?.authUser?.email === userData?.email && authenticatedUser;
	const hasPermissions = userState?.permissions?.includes('user_support');
	const canImpersonate = hasPermissions || impersonating;

	useEffect(() => {
		let isEmpty = true;
		userData?.interview.map(iv => {
			isEmpty = isEmpty && iv.answer == '';
		});
		setEmptyInterview(isEmpty);
	}, [userData]);

	const handleClick = async () => {
		if (impersonating) {
			await stopImpersonating();
			const oldUserData =
				switchBackToAuthenticatedUser(authenticatedUser);
			updateUserData(oldUserData);
		} else if (canImpersonate) {
			const impersonatedUser = await startImpersonating(userData.id);
			const newUserData = createImpersonatedUserData(
				userState,
				impersonatedUser,
			);
			updateUserData(newUserData);
		}
	};

	return userData ? (
		<section className='userdata__container'>
			<section className='userdata__header'>
				<section className='userdata__header__image'>
					<Image
						id={userData.picture}
						alt={userData.firstName + ' ' + userData.lastName}
						rounded={true}
						shadow={true}
					/>
				</section>
				<section className='userdata__header__container'>
					{canImpersonate ? (
						<section
							className={`userdata__header__button${impersonating ? ' impersonating' : ''}`}
							onClick={() => handleClick()}>
							<FontAwesomeIcon icon={faUserSecret} size='2x' />
						</section>
					) : null}
					{/* <Stickers
						type='sub_feeds'
						objId={userData.picture}
						stickers={mockDataStickers.stickers}
						addButton
					/> */}
					<h1>
						{userData.firstName} {userData.lastName}{' '}
					</h1>
					<div className='userdata__header__function'>
						<section
							dangerouslySetInnerHTML={{
								__html: userData.function?.replaceAll(
									'\\r\\n',
									'<br />',
								),
							}}
						/>
					</div>
					<section className='userdata__header__items'>
						<section className='userdata__header__itemblock'>
							<section className='sidebar-container__item'>
								<FontAwesomeIcon
									icon={faEnvelope}
									className='sidebar-icon'
									fixedWidth
								/>
								<a
									className='text-overflow__ellipses'
									href={'mailto:' + userData.email}>
									{userData.email}
								</a>
							</section>
							{userData.telephone.map(tel => (
								<section
									key={tel.number}
									className='sidebar-container__item'>
									<FontAwesomeIcon
										icon={faPhone}
										className='sidebar-icon'
										fixedWidth
									/>
									<span>
										<a href={'tel:' + tel.number}>
											{tel.number}
										</a>
									</span>
								</section>
							))}
						</section>
						<section className='userdata__header__itemblock'>
							<section className='sidebar-container__item'>
								<FontAwesomeIcon
									icon={faUsers}
									className='sidebar-icon'
									fixedWidth
								/>
								{userData.coces.map((coce, i) => {
									return (
										<span key={coce}>
											{coce == 'AND' ? 'STAF' : coce}{' '}
											{userData.coces[i + 1] ? ', ' : ''}
										</span>
									);
								})}
							</section>
							<section className='sidebar-container__item'>
								<FontAwesomeIcon
									icon={faBusinessTime}
									className='sidebar-icon'
									fixedWidth
								/>
								<span>
									{userData.yearsOfService < 0
										? 'Binnenkort '
										: userData.yearsOfService == 0
											? 'Minder dan 1 jaar '
											: userData.yearsOfService == 1
												? '1 jaar '
												: userData.yearsOfService +
													' jaren '}
									in dienst
								</span>
							</section>
						</section>
						<section className='userdata__header__itemblock sidebar-container__item'>
							<FontAwesomeIcon
								icon={faBirthdayCake}
								className='sidebar-icon'
								fixedWidth
							/>
							<span>
								{format(new Date(userData.birthday), 'd MMMM', {
									locale: nl,
								})}
							</span>
						</section>

						<section className='userdata__header__itemblock'>
							<div className='social-media_wrapper'>
								<SocialMedia
									socials={userData.socialMedia}
									teams={userData.email}
									squareIcons
									addClassName
								/>
							</div>
						</section>
					</section>
				</section>

				<h5 className='return-to-overview'>
					<Link to='/medewerkers'>
						<FontAwesomeIcon
							icon={faArrowLeftLong}
							className='icon'
							fixedWidth
						/>
						Terug naar medewerkers
					</Link>
				</h5>
			</section>

			{userData.biography ||
			!emptyInterview ||
			userData.upcoming.length > 0 ? (
				<section className='page-container'>
					<section className='user-data-container'>
						<section className='user-data-body'>
							{userData.biography ? (
								<>
									<h3>Biografie</h3>
									<section className='biography-container'>
										<section
											dangerouslySetInnerHTML={{
												__html: userData.biography.replaceAll(
													'\\n',
													'<br />',
												),
											}}
										/>
										{/* <Stickers
										type='sub_feeds'
										objId={userData.picture}
										stickers={mockDataStickers.stickers}
										addButton
									/> */}
									</section>
								</>
							) : (
								<span />
							)}

							{emptyInterview ? (
								<span />
							) : (
								<>
									<h3>Meer over {userData.firstName}</h3>
									<section className='interview-container'>
										{userData.interview.map(interview => {
											return interview.answer ? (
												<section
													key={interview.questionId}
													className='interview-item'>
													<div className='label-question'>
														{interview.question}
													</div>
													<div className='label-answer'>
														{interview.answer}
													</div>
												</section>
											) : null;
										})}
									</section>
								</>
							)}
							{userData.upcoming?.length > 0 ? (
								<>
									<h3>
										Toekomstige opleidingen en evenementen
									</h3>
									<section className='userdata__entries'>
										{userData.upcoming?.map(entry => {
											return (
												<section
													key={entry.id}
													className='userdata__entries__item'>
													<section className='userdata__entries__item__sidedate'>
														<section className='sidedate__month'>
															{format(
																new Date(
																	entry.dates
																		? entry
																				.dates[0]
																		: entry.date,
																),
																'MMM',
																{ locale: nl },
															)}
														</section>
														<section className='sidedate__day'>
															{format(
																new Date(
																	entry.dates
																		? entry
																				.dates[0]
																		: entry.date,
																),
																'd',
																{ locale: nl },
															)}
														</section>
													</section>
													<section className='userdata__entries__item__details'>
														<span className='userdata__entries__item__title'>
															{entry.name} <br />{' '}
															{' ('}
															{entry.dates
																? entry.dates.map(
																		(
																			date,
																			i,
																		) => {
																			return (
																				<span
																					key={
																						date
																					}>
																					{toShortDate(
																						date,
																					)}
																					{entry
																						.dates[
																						i +
																							1
																					]
																						? ', '
																						: ''}
																				</span>
																			);
																		},
																	)
																: entry.date}
															{')'}
														</span>
														<span className='userdata__entries__item__description'>
															{entry.description}
														</span>
													</section>
												</section>
											);
										})}
									</section>
								</>
							) : null}
						</section>
					</section>
				</section>
			) : null}
		</section>
	) : (
		<Loading />
	);
};

export default EmployeeDetail;
