import { useSelector } from 'react-redux';
import {
	faFacebookF,
	faInstagram,
	faLinkedin,
	faTiktok,
	faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import useAxios from 'core/hooks/api/useAxios';
import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { isValidUrl } from 'core/services/form';

import { Button, InputField, Textarea, useForm } from 'components/Form';
import { Col, Container, Row } from 'components/Grid';

import {
	createUpdateBody,
	findAnswer,
	findSocialMedia,
} from './updateProfile.helper';

import './UpdateProfile.scss';

const UpdateProfile = () => {
	const { register, values } = useForm();

	const {
		authUser: { interview, socialMedia, biography },
	} = useSelector(state => state.authReducer);

	const [{ data: questions }] = useAxios({
		url: '/questions',
	});

	const updateProfile = async () => {
		try {
			await executeAxios({
				url: '/auth/me/account',
				method: 'PUT',
				data: createUpdateBody(values),
			});
			handleAlert('success', 'Profiel is succesvol aangepast.');
		} catch (error) {
			handleAlert('error', 'Er is iets misgegaan.');
			console.log(error);
		}
	};

	return (
		<section className='profile'>
			<section className='profile__biography'>
				<header className='profile__section__header'>
					<p>Biografie</p>
				</header>
				<Container>
					<Row>
						<Col sm={12}>
							<Textarea
								{...register('biography', {
									label: 'Vertel iets over jezelf',
									placeholder: 'Vertel iets over jezelf',
									rows: 9,
									validations: [],
									value: biography?.replaceAll('\\n', '\n'),
								})}
							/>
						</Col>
					</Row>
				</Container>
			</section>
			<section className='profile__social'>
				<header className='profile__section__header'>
					<p>Social media</p>
				</header>
				<Container className='profile__section__content'>
					{socialMedia ? (
						<Row>
							<Col xl={6} lg={12}>
								<InputField
									{...register('facebook', {
										label: 'Facebook',
										placeholder: 'Facebook',
										icon: faFacebookF,
										validations: [isValidUrl],
										value: findSocialMedia(
											socialMedia,
											'facebook',
										),
									})}
								/>
							</Col>
							<Col xl={6} lg={12}>
								<InputField
									{...register('linkedin', {
										label: 'Linked-in',
										placeholder: 'Linked-in',
										icon: faLinkedin,
										validations: [isValidUrl],
										value: findSocialMedia(
											socialMedia,
											'linkedin',
										),
									})}
								/>
							</Col>
							<Col xl={6} lg={12}>
								<InputField
									{...register('instagram', {
										label: 'Instagram',
										placeholder: 'Instagram',
										icon: faInstagram,
										validations: [isValidUrl],
										value: findSocialMedia(
											socialMedia,
											'instagram',
										),
									})}
								/>
							</Col>
							<Col xl={6} lg={12}>
								<InputField
									{...register('twitter', {
										label: 'X',
										placeholder: 'Twitter',
										icon: faXTwitter,
										validations: [isValidUrl],
										value: findSocialMedia(
											socialMedia,
											'twitter',
										),
									})}
								/>
							</Col>
							<Col xl={6} lg={12}>
								<InputField
									{...register('tiktok', {
										label: 'TikTok',
										placeholder: 'TikTok',
										icon: faTiktok,
										validations: [isValidUrl],
										value: findSocialMedia(
											socialMedia,
											'tiktok',
										),
									})}
								/>
							</Col>
						</Row>
					) : null}
				</Container>
			</section>
			<section className='profile__interview'>
				<header className='profile__section__header'>
					<p>Interview</p>
				</header>
				<Container className='profile__section__content'>
					<Row>
						{interview
							? questions?.map(question => (
									<Col
										xl={6}
										lg={12}
										key={question.id}
										className='profile__section__content__item'>
										<Textarea
											{...register(
												`question-${question.id}`,
												{
													label: question.body,
													placeholder: 'Antwoord',
													validations: [],
													value: findAnswer(
														interview,
														question.id,
													),
												},
											)}
										/>
									</Col>
								))
							: null}
					</Row>
				</Container>
			</section>
			<Button buttonStyle='primary' onClick={updateProfile}>
				Bewaren
			</Button>
		</section>
	);
};

export default UpdateProfile;
