import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { updateStickersOfStickerable } from 'core/store/stickers/sticker.utils';
import {
	updateAttachments,
	updateFeedItems,
} from 'core/store/utils/updateState.utils';

let defaultState = {
	mainFeeds: [],
	mainFeedsNextPage: 1,
	mainFeedHasNextPage: true,
	mainFeedIsNextPageLoading: false,
};

const loadMainFeeds = (state, { mainFeedLoading }) => ({
	...state,
	mainFeedIsNextPageLoading: mainFeedLoading,
});

const loadMainFeedsCommit = (state, { data }) => {
	const hasNextPage = data.currentPage < data.lastPage;
	return {
		...state,
		mainFeeds: updateFeedItems(state.mainFeeds, data, updateAttachments),
		mainFeedsNextPage: hasNextPage ? data.currentPage + 1 : null,
		mainFeedHasNextPage: hasNextPage,
		mainFeedIsNextPageLoading: false,
	};
};

const updateStickersOfMainFeed = updateStickersOfStickerable('mainFeed');

export const mainFeedReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.MAIN_FEEDS_LOAD:
			return loadMainFeeds(state, payload);
		case ACTION_TYPES.MAIN_FEEDS_LOAD_COMMIT:
			return loadMainFeedsCommit(state, payload);
		case ACTION_TYPES.STICKER_ADD:
			return updateStickersOfMainFeed(state, payload, 'ADD');
		case ACTION_TYPES.STICKER_DELETE:
			return updateStickersOfMainFeed(state, payload, 'DELETE');
		default:
			return state;
	}
};
