import Skeleton from 'react-loading-skeleton';
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { bool, string } from 'prop-types';

const ImageLoading = ({ rounded = false, shadow = false, className }) => {
	const online = useOnlineStatus();

	return online ? (
		<Skeleton
			className={`picture${className ? ` ${className}` : ''}${
				rounded ? ' rounded' : ''
			}${shadow ? ' with-shadow' : ''}`}
		/>
	) : (
		<div
			className={`picture${className ? ` ${className}` : ''}${
				rounded ? ' rounded' : ''
			}${shadow ? ' with-shadow' : ''}`}>
			<FontAwesomeIcon icon={faHeartBroken} fixedWidth />
		</div>
	);
};

ImageLoading.propTypes = {
	className: string,
	rounded: bool,
	shadow: bool,
};

export default ImageLoading;
