import { useEffect, useState } from 'react';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';

const useField = ({
	disabled,
	disableWhenOffline,
	value: initialValue,
	errors: initialErrors,
	overwriteValue,
	name,
	...props
}) => {
	const [value, setValue] = useState(initialValue);
	const [errors, setErrors] = useState(initialErrors);
	const online = useOnlineStatus();
	const key = name;
	const isDisabled = disabled || (!online && disableWhenOffline);

	useEffect(() => {
		if (overwriteValue !== undefined) {
			setValue(overwriteValue);
		}
	}, [overwriteValue]);

	const validate = validations => {
		setErrors(
			validations
				.map(errorsFor => errorsFor(value, name))
				.filter(errorMsg => errorMsg.length > 0),
		);
	};

	const onChange = v => {
		setValue(v);
		if (props.onChange) {
			props.onChange(v);
		}
	};

	return {
		...props,
		value,
		setValue,
		errors,
		key,
		name,
		disabled: isDisabled,
		onChange,
		validate,
	};
};

export default useField;
