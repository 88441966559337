import { useSelector } from 'react-redux';

import './Panne.scss';

export const Panne = () => {
	const { selectedCar } = useSelector(state => state.carReducer);
	return (
		<div
			key={selectedCar.id}
			className='panne-container'
			dangerouslySetInnerHTML={{
				__html: selectedCar.info?.breakdownInfo?.replace(
					/\r\n/g,
					'<br />',
				),
			}}
		/>
	);
};
