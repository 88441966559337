import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	coces: [],
	cocesLoading: false,
	cocesFetched: false,
};

const loadCoces = state => ({
	...state,
	cocesLoading: true,
});

const loadCocesCommit = (state, { data }) => {
	return {
		...state,
		coces: [...state.coces, ...data],

		cocesLoading: false,
		cocesFetched: true,
	};
};

export const cocesReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.COCES_LOAD:
			return loadCoces(state, payload);
		case ACTION_TYPES.COCES_LOAD_COMMIT:
			return loadCocesCommit(state, payload);
		default:
			return state;
	}
};
