import { isBiggerThan, isRequired, isSmallerThan } from 'core/services/form';

const parseField = (field, format, fields) => {
	const fieldData = fields.find(f => f.name === field);
	const isGroup = fieldData?.type === 'group';
	const direction = format.direction || 'vertical';
	const count = format?.fields?.length || 1;

	return {
		fields: isGroup ? [] : [field],
		...(isGroup && {
			children: fieldData?.fields.map(field =>
				parseField(field, fieldData, fields),
			),
		}),
		width:
			direction === 'horizontal' && count > 1
				? count > 2
					? { md: 6, lg: 4 }
					: { md: 6 }
				: { lg: 12 },
	};
};

export const makeFormLayout = ({ format, fields }) => {
	if (!format || !format.fields || !fields) return {};

	return {
		grid: [
			[
				...format.fields.map(fieldName =>
					parseField(fieldName, format, fields),
				),
			],
		],
	};
};

export const makeFormObject = fields => {
	if (!fields) return {};
	return fields.reduce((acc, field) => {
		if (field.type === 'group') return acc;

		const required = [1, '1', true, 'true'].includes(field.required);

		acc[field.name] = {
			...field,
			validations: required ? [isRequired] : [],
			value: field.value || '',
			placeholder: field.placeholder || '',
			description: field.description || '',
			autocomplete: [1, '1', true, 'true'].includes(field.autocomplete),
			errors: [],
			required,
		};

		if (field.type === 'radio' || field.type === 'select') {
			acc[field.name].options = field.options.map(option => ({
				label: option.label || option,
				value: option.value || option,
			}));
		}

		if (field.type === 'number') {
			if (field.step) {
				acc[field.name].step = field.step;
			}

			if (field.min) {
				acc[field.name].validations.push(isBiggerThan(field.min));
			}

			if (field.max && field.max.value) {
				acc[field.name].validations.push(
					isSmallerThan(field.max.value),
				);
			}
		}

		if (field.type === 'checkbox') {
			acc[field.name].value = [1, '1', true, 'true'].includes(
				field.value,
			);
		}

		return acc;
	}, {});
};
