import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
	faAddressCard,
	faGear,
	faImage,
} from '@fortawesome/free-solid-svg-icons';

import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import { SecondaryNavigation } from 'components/Navigation/SecondaryNavigation/SecondaryNavigation';

import './Account.scss';

export const accountTabs = [
	{ path: 'instellingen', name: 'Instellingen', id: 1, icon: faGear },
	{ path: 'profiel', name: 'Profiel', id: 2, icon: faAddressCard },
	{ path: 'afbeelding', name: 'Afbeelding', id: 3, icon: faImage },
];

const Account = () => {
	const { authUser } = useSelector(state => state.authReducer);

	return (
		<section className='account-container'>
			<PageHeading
				pageTitle={`${authUser?.firstName} ${authUser?.lastName}`}
				extraChildrenInsideInleiding={
					<SecondaryNavigation arrayOfTabs={accountTabs} />
				}
			/>

			<Outlet />
		</section>
	);
};

export default Account;
