import { loginRequest } from 'authConfig';
import msalInstance from 'msalInstance';

import Icon from 'components/Icon/Icon';

import './Login.scss';

const Login = () => (
	<section className='login'>
		<div className='login__sign-in-box'>
			<Icon icon='ketchup_heartbeat' />

			<h2>U bent afgemeld bij uw account</h2>
			<p>U kunt opnieuw aanmelden via onderstaande knop.</p>
			<button
				onClick={async () => {
					try {
						await msalInstance.loginRedirect(loginRequest);
					} catch (e) {
						console.log(e);
					}
				}}>
				Opnieuw aanmelden
			</button>
		</div>
	</section>
);

export default Login;
