import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadEvents } from 'core/store/events/events.actions';
import { loadTags } from 'core/store/tags/tag.actions';

import { ListPageLayout } from 'components/Layouts/ListPageLayout/ListPageLayout';

const EventOverview = () => {
	const { events } = useSelector(state => state.eventReducer);
	const {
		tags: { events: tags },
	} = useSelector(state => state.tagReducer);

	useEffect(() => {
		loadEvents();
		loadTags('events');
	}, []);

	return (
		events &&
		tags && (
			<ListPageLayout
				pageTitle='Evenementen'
				contentType='evenementen'
				contentArray={events}
				tags={tags}
			/>
		)
	);
};

export default EventOverview;
