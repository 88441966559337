import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
	faFileContract,
	faFileInvoiceDollar,
	faInfoCircle,
	faNotesMedical,
	faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import Modal from 'components/Modal/Modal';
import { SecondaryNavigation } from 'components/Navigation/SecondaryNavigation/SecondaryNavigation';

import './Timesheet.scss';

export const SchadeMelden_SubText_example = [
	'Had je net een ongeval? Blijf dan vooral rustig en volg de instructies hieronder.',
	"Vergeet niet je schade te melden via het formulier 'Meld Schade'.",
	'Glasschade of panne? Wij geven je graag richtlijnen met meer hulp.',
];

const Timesheet = () => {
	const { settings } = useSelector(state => state.authReducer);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [infoModal, setInfoModal] = useState(false);
	const [infoText, setInfoText] = useState();

	useEffect(() => {
		let screen = pathname.split('/').pop();

		switch (screen) {
			case 'urenregistratie':
				setInfoText(
					<>
						<h4>
							Registreer wekelijks je gewerkte uren in KetchUP.
						</h4>
						Onder de weekselectie vind je jouw projecten, waarop je
						uren kan registreren gedurende de geselecteerde periode.
						<h4>Werkuren registreren</h4>
						Om je werkuren te registreren, volg je deze eenvoudige
						stappen: <br />
						1. Kies het project waaraan je hebt gewerkt. <br />
						2. Klik de dag waarop je uren wil registreren.
						Automatisch worden de beschikbare uren voor die dag
						geregistreerd.
						<br />
						<br />
						Indien nodig kan je: <br />
						1. Een tweede keer klikken op de geregistreerde dag.
						<br /> 2. Pas de werkuren aan, vul bijkomstige
						informatie in of verwijder de registratie.
						<h4>Onderteken timesheet</h4>
						Onderaan je timesheet zie je een knop “Teken
						timesheets”. Klik hierop om naar een overzichtpagina te
						gaan met al je openstaande timesheets.
						<h4>Problemen?</h4>
						Zie je geen projecten staan, maar zie je wel de periode
						waarop je uren kan registreren, neem dan contact op met
						jouw HR-verantwoordelijke.
						<br />
						<br />
						Geen toegang tot “Urenregistratie”? Vraag je
						HR-verantwoordelijke om te controleren of alles correct
						is ingesteld.
						<br />
						<br />
						Geen data beschikbaar? Als je de melding krijgt “Er is
						geen data voor deze periode”, wil je mogelijk data
						bekijken van vóór je in-dienst datum of van vóór 1
						januari 2024. Als dit niet het geval is, vraag dan je
						HR-verantwoordelijke om je gegevens te controleren.
					</>,
				);
				break;
			case 'afwezigheden':
				setInfoText(
					<>
						<h4>
							Zorg ervoor dat je je afwezigheden altijd vóór de
							15e maand ingeeft.
						</h4>
						<h4>Uren registreren</h4>
						Om je afwezigheid te registreren, volg je deze
						eenvoudige stappen: <br />
						1. Controleer of je de juiste maand bekijkt. <br />
						2. Klik de dag waarop je afwezigheid wil registreren.
						Automatisch worden de beschikbare uren voor die dag
						geregistreerd.
						<br />
						<br />
						Indien nodig kan je: <br />
						1. Een tweede keer klikken op de geregistreerde dag.
						<br /> 2. Pas de uren aan, verwijder de registratie of
						voeg een registratie toe aan die dag.
						<h4>Welk type afwezigheid wordt geregistreerd?</h4>
						Wanneer je een afwezigheid aanvraagt, wordt dit
						automatisch geregistreerd onder het type “Afwezigheid”.
						Het correcte type wordt pas geregistreerd op het moment
						dat je je timesheet indient, afhankelijk van de
						beschikbaarheid op dat moment.
						<br />
						<br />
						Als je geen of onvoldoende uren beschikbaar hebt, zal
						een onbetaalde afwezigheid worden geregistreerd.
						Raadpleeg de “Afwezigheidstypes” in de handleiding om
						precies te zien welke tellers meetellen bij de
						beschikbaarheid.
						<h4>Vakantieteller</h4>
						Door onderaan het overzicht van je afwezigheden op
						“Vakantieteller” te klikken, krijg je een overzicht van
						jouw vakantietellers. Het overzicht is opgesteld in uren
						en laat zien hoeveel verlofuren je op dit moment hebt
						opgebouwd.
					</>,
				);
				break;
			default:
				setInfoText(null);
				break;
		}
	}, [pathname]);

	const handleInfo = () => {
		setInfoModal(true);
	};

	const initialTabs = [
		{
			id: 2,
			path: 'afwezigheden',
			name: 'Afwezigheden',
			icon: faPlaneDeparture,
		},
		{
			id: 3,
			path: 'ziekte-meldingen',
			name: 'Ziekte meldingen',
			icon: faNotesMedical,
		},
		{
			id: 4,
			path: 'onkostennotas',
			name: "Onkostennota's",
			icon: faFileInvoiceDollar,
		},
	];

	const tabs = settings?.hasTimesheets
		? [
				{
					id: 1,
					path: 'urenregistratie',
					name: 'Urenregistratie',
					icon: faFileContract,
				},
				...initialTabs,
			]
		: initialTabs;

	useEffect(() => {
		if (pathname === '/timesheet') {
			navigate(tabs[0].path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<section className='timesheet-pagina-container'>
			<PageHeading
				pageTitle='Timesheet'
				extraChildrenInsideInleiding={
					<SecondaryNavigation arrayOfTabs={tabs} />
				}
				extraChildrenInsideHeader={
					infoText ? (
						<button className='btn infobtn' onClick={handleInfo}>
							<FontAwesomeIcon icon={faInfoCircle} size='xl' />
						</button>
					) : null
				}
			/>
			<Outlet />
			<Modal
				title='Info'
				show={infoModal}
				close={() => setInfoModal(false)}>
				{infoText}
			</Modal>
		</section>
	);
};

export default Timesheet;
