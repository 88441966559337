import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';

import ReportSickness from './ReportSickness';

const ReportSicknessPage = () => {
	return (
		<section className='report-sickness-page'>
			<PageHeading pageTitle='Meld ziekte' />
			<ReportSickness />
		</section>
	);
};

export default ReportSicknessPage;
