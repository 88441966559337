/**
 * @file timesheet.helper.js
 * @module timesheet.helper
 * @desc
 * This file contains helper functions for the TimesheetModal component.
 * There are helpers to:
 * - create the form data and layout for the timesheet modal
 * - get the initial form data for the timesheet modal
 * - create the form fields for the timesheet modal
 * - create the state for the timesheet modal
 * - create the form data for the timesheet modal
 * - create the validation for the timesheet modal
 * - add validations for the timesheet modal
 * @requires core/services/form
 * @requires core/services/form/extraction/form.extraction
 * @requires @fortawesome/free-solid-svg-icons
 */

import { faClock } from '@fortawesome/free-solid-svg-icons';
import {
	isBiggerThan,
	isBiggerThan0,
	isNotExponent,
	isNumberWith3Decimals,
	isSmallerThan,
} from 'core/services/form';
import { flattenObject } from 'core/services/form/extraction/form.extraction';

/**
 * Create a field object for the form.
 * @param {string} fieldName - The name of the field.
 * @param {string} type - The type of the field.
 * @param {string} label - The label of the field.
 * @param {string} placeholder - The placeholder of the field.
 * @param {string} description - The description of the field.
 * @param {string} className - The class name of the field.
 * @returns {object} field - The field object.
 * @example
 * const field = createField('duration', 'number', 'Werkuren', 'Vul uren in', '', 'middle field eager');
 * // field = { type: 'number', name: 'duration', label: 'Werkuren', placeholder: 'Vul uren in', description: '', className: 'middle field eager', key: 'duration', errors: [], required: true }
 */
const createField = (
	fieldName,
	type,
	label,
	placeholder,
	description,
	className,
) => ({
	type,
	name: fieldName,
	label,
	placeholder,
	description,
	className,
	key: fieldName,
	errors: [],
	required: true,
});

/**
 * Create all fields for the timesheet modal.
 * @returns {object[]} All fields for the timesheet modal.
 * @example
 * const fields = createAllFields();
 * // fields = [{ type: 'number', name: 'duration', label: 'Werkuren', placeholder: 'Vul uren in', description: '', className: 'middle field eager', key: 'duration', errors: [], required: true }, ...]
 */
const createAllFields = () => {
	return [
		// name, type, label, placeholder, description, className
		[
			'duration',
			'number',
			'Werkuren',
			'Vul uren in',
			'',
			'middle field eager',
		],
		['h100', 'number', '100%', '', '', 'small field'],
		['h150', 'number', '150%', '', '', 'small field'],
		['h200', 'number', '200%', '', '', 'small field'],
		['mileage.car', 'number', 'Km. auto', 'Auto', '', 'middle field'],
		['mileage.bike', 'number', 'Km. fiets', 'Fiets', '', 'middle field'],
		[
			'onCall.wasOnCall',
			'checkbox',
			'Ik was on call',
			'',
			'',
			'middle field eager',
		],
		[
			'onCall.hoursOnCall',
			'number',
			'Uren on call',
			'Vul uren in',
			'',
			'middle field eager',
		],
		[
			'onCall.workedOnCall',
			'number',
			'Uren gepresteerd on call',
			'Vul uren in',
			'',
			'middle field eager',
		],
		['onCall.h100', 'number', '100%', '', '', 'small field'],
		['onCall.h150', 'number', '150%', '', '', 'small field'],
		['onCall.h200', 'number', '200%', '', '', 'small field'],
		[
			'workedAbroadCountry',
			'text',
			'Werk in het buitenland',
			'Vul de plaats van verblijf in',
			'',
			'field eager',
		],
		['remark', 'textarea', 'Opmerking', '', '', 'eager field vertical'],
	].map(fieldData => createField(...fieldData));
};

/**
 * Create the state for the timesheet modal. The Form component uses this state to render the form.
 * @param {*} project - The project data.
 * @param {*} day - The day data.
 * @returns {object} state - The state for the timesheet modal.
 * @example
 * const state = createState({ id: 1 }, { date: '2021-07-01', duration: 8 });
 * // state = { date: '2021-07-01', projectId: 1, duration: '8' };
 * @example
 * const state = createState({ id: 1 }, { date: '2021-07-01', duration: 8, h100: 0 });
 * // state = { date: '2021-07-01', projectId: 1, duration: '8', h100: '' };
 * @example
 * const state = createState({ id: 1 }, { date: '2021-07-01', duration: 8, h100: 1, h150: 2, h200: 3, mileage: { car: 10, bike: 20 }, onCall: { wasOnCall: true, hoursOnCall: 8, workedOnCall: 8, h100: 1, h150: 2, h200: 3 }, workedAbroadCountry: 'België', remark: 'Opmerking' });
 * // state = { date: '2021-07-01', projectId: 1, duration: '8', h100: '1', h150: '2', h200: '3', mileage: { car: '10', bike: '20' }, onCall: { wasOnCall: true, hoursOnCall: '8', workedOnCall: '8', h100: '1', h150: '2', h200: '3' }, workedAbroadCountry: 'België', remark: 'Opmerking' };
 */
const createState = (project, day) => {
	const hasProperty = (obj, prop) =>
		Object.prototype.hasOwnProperty.call(obj, prop);
	return {
		date: day.date || '',
		projectId: project.id,
		...(day.defaultDuration || day.allowOvertime
			? { duration: `${day.duration || ''}` }
			: {}),
		...(project.complexForm
			? {
					h100: day.h100 || '',
					h150: day.h150 || '',
					h200: day.h200 || '',
				}
			: {}),
		mileage: {
			...(hasProperty(day.mileage, 'car') && {
				car: `${day.mileage.car || ''}`,
			}),
			bike: `${day.mileage.bike || ''}`,
		},
		...(project.onCall > 1 &&
			!!day.onCall && {
				onCall: {
					...(JSON.stringify(Object.keys(day.onCall)) ===
						'["wasOnCall"]' && {
						wasOnCall: day.onCall.wasOnCall || false,
					}),
					...(hasProperty(day.onCall, 'hoursOnCall') && {
						hoursOnCall: `${day.onCall.hoursOnCall || ''}`,
					}),
					...(hasProperty(day.onCall, 'workedOnCall') && {
						workedOnCall: `${day.onCall.workedOnCall || ''}`,
					}),
					...(hasProperty(day.onCall, 'h100') && {
						h100: `${day.onCall.h100 || ''}`,
					}),
					...(hasProperty(day.onCall, 'h150') && {
						h150: `${day.onCall.h150 || ''}`,
					}),
					...(hasProperty(day.onCall, 'h200') && {
						h200: `${day.onCall.h200 || ''}`,
					}),
				},
			}),
		...(project.worksAbroad && {
			workedAbroadCountry: day.workedAbroadCountry || '',
		}),
		remark: day.remark || '',
	};
};

/**
 * Create the form data for the timesheet modal.
 * @param {*} project
 * @param {*} day
 * @returns {object} formData - The form data for the timesheet modal.
 * @example
 * const formData = createFormData({ id: 1 }, { date: '2021-07-01', duration: 8, h100: 1, h150: 2, h200: 3, mileage: { car: 10, bike: 20 }, onCall: { wasOnCall: true, hoursOnCall: 8, workedOnCall: 8, h100: 1, h150: 2, h200: 3 }, workedAbroadCountry: 'België', remark: 'Opmerking' });
 * // formData = { date: '2021-07-01', projectId: 1, duration: '8', h100: '1', h150: '2', h200: '3', mileage: { car: '10', bike: '20' }, onCall: { wasOnCall: true, hoursOnCall: '8', workedOnCall: '8', h100: '1', h150: '2', h200: '3' }, workedAbroadCountry: 'België', remark: 'Opmerking' };
 */
const createFormData = (project, day) => {
	const dayData = createState(project, day);
	const flattenedDayData = flattenObject(dayData);
	return flattenedDayData;
};

/**
 * Create the fields object for the form. This object is used to render the form.
 * @param {*} formData
 * @param {*} fields
 * @returns {object} fieldsObject - The fields object for the form.
 * @example
 * const fieldsObject = createFieldsObject({ date: '2021-07-01', projectId: 1, duration: '8', h100: '1', h150: '2', h200: '3', mileage: { car: '10', bike: '20' }, onCall: { wasOnCall: true, hoursOnCall: '8', workedOnCall: '8', h100: '1', h150: '2', h200: '3' }, workedAbroadCountry: 'België', remark: 'Opmerking' }, [{ type: 'number', name: 'duration', label: 'Werkuren', placeholder: 'Vul uren in', description: '', className: 'middle field eager', key: 'duration', errors: [], required: true }, ...]);
 */
const createFieldsObject = (formData, fields) => {
	return Object.keys(formData).reduce((acc, fieldName) => {
		const field = fields.find(f => f.name === fieldName);
		if (field) {
			field.value = formData[fieldName];
			acc[fieldName] = field;
		}
		return acc;
	}, {});
};

/**
 * Create a validation function for the duration field.
 * @param {*} day
 * @returns {function} isSmallerThan - The validation function.
 * @example
 * const isSmallerThan24 = createDurationValidation({ allowOvertime: true });
 * // isSmallerThan24 = isSmallerThan(24);
 * @example
 * const isSmallerThan24 = createDurationValidation({ defaultDuration: 8 });
 * // isSmallerThan24 = isSmallerThan(8);
 * @example
 * const isSmallerThan24 = createDurationValidation({ defaultDuration: 8, allowOvertime: true });
 * // isSmallerThan24 = isSmallerThan(8);
 */
const createDurationValidation = day => {
	const max = day.allowOvertime ? 24 : day.defaultDuration || 24;
	return isSmallerThan(max);
};

/**
 * Add a validation to a field object.
 * @param {*} fieldsObject
 * @param {*} fieldName
 * @param {*} validations
 * @example
 * addValidation(fieldsObject, 'duration', [isBiggerThan0, isSmallerThan24, isNumberWith3Decimals, isNotExponent]);
 */
const addValidation = (fieldsObject, fieldName, validations) => {
	if (fieldsObject[fieldName]) {
		fieldsObject[fieldName].validations = [
			...(fieldsObject[fieldName].validations || []),
			...validations,
		];
	}
};

/**
 * Add validations to the fields object.
 * @param {*} fieldsObject
 * @param {*} fieldNames
 * @param {*} validations
 * @example
 * addValidations(fieldsObject, ['duration', 'onCall.workedOnCall', 'onCall.hoursOnCall', 'mileage.bike'], [[isBiggerThan0, isSmallerThan24, isNumberWith3Decimals, isNotExponent], [isBiggerThan0, isSmallerThan24, isNumberWith3Decimals, isNotExponent], [isBiggerThan0, isSmallerThan24, isNumberWith3Decimals, isNotExponent], [isBiggerThan(0)]]);
 */
const addValidations = (fieldsObject, fieldNames, validations) => {
	fieldNames.forEach((fieldName, index) => {
		addValidation(fieldsObject, fieldName, validations[index]);
	});
};

/**
 * Get the initial form data for the timesheet modal.
 * @param {*} project - The project data.
 * @param {*} day - The day data.
 * @returns {object} formData - The initial form data for the timesheet modal.
 * @example
 * const formData = getInitialFormData({ id: 1 }, { date: '2021-07-01', duration: 8 });
 * // formData = { date: '2021-07-01', projectId: 1, duration: { type: 'number', name: 'duration', label: 'Werkuren', placeholder: 'Vul uren in', description: '', className: 'middle field eager', key: 'duration', errors: [], required: true, button: { icon: faClock, baseValue: 8, options: [ { value: 1, label: '1' }, { value: 0.75, label: '3/4' }, { value: 0.5, label: '1/2' }, { value: 0.25, label: '1/4' } ] } }, h100: { type: 'number', name: 'h100', label: '100%', placeholder: '', description: '', className: 'small field', key: 'h100', errors: [], required: true }, h150: { type: 'number', name: 'h150', label: '150%', placeholder: '', description: '', className: 'small field', key: 'h150', errors: [], required: true }, h200: { type: 'number', name: 'h200', label: '200%', placeholder: '', description: '', className: 'small field', key: 'h200', errors: [], required: true }, mileage: { car: { type: 'number', name: 'mileage.car', label: 'Km. auto', placeholder: 'Auto', description: '', className: 'middle field', key: 'mileage.car', errors: [], required: true }, bike: { type: 'number', name: 'mileage.bike', label: 'Km. fiets', placeholder: 'Fiets', description: '', className: 'middle field', key: 'mileage.bike', errors: [], required: true } } };
 */
export const getInitialFormData = (project, day) => {
	const fields = createAllFields();
	const formData = createFormData(project, day);
	const fieldsObject = createFieldsObject(formData, fields);
	const isSmallerThan24 = createDurationValidation(project, day);

	if (fieldsObject.duration) {
		fieldsObject.duration.button = {
			icon: faClock,
			baseValue: day.defaultDuration || 8,
			options: [
				{ value: 1, label: '1' },
				{ value: 0.75, label: '3/4' },
				{ value: 0.5, label: '1/2' },
				{ value: 0.25, label: '1/4' },
			],
		};
	}

	addValidations(
		fieldsObject,
		[
			'duration',
			'onCall.workedOnCall',
			'onCall.hoursOnCall',
			'mileage.bike',
		],
		[
			[
				isBiggerThan0,
				isSmallerThan24,
				isNumberWith3Decimals,
				isNotExponent,
			],
			[
				isBiggerThan0,
				isSmallerThan24,
				isNumberWith3Decimals,
				isNotExponent,
			],
			[
				isBiggerThan0,
				isSmallerThan24,
				isNumberWith3Decimals,
				isNotExponent,
			],
			[isBiggerThan(0)],
		],
	);
	return fieldsObject;
};

/**
 * Create the form layout for the timesheet modal.
 * @param {{
 * 		duration: number,
 * 		h100: number,
 * 		h150: number,
 * 		h200: number,
 * 		mileage: { car: number, bike: number },
 * 		onCall: { wasOnCall: boolean, hoursOnCall: number, workedOnCall: number, h100: number, h150: number, h200: number },
 * 		workedAbroadCountry: string,
 * 		remark: string,
 * }} formData - The form data for the timesheet modal.
 * @param {*} project
 * @returns {object} layout - The form layout for the timesheet modal.
 * @example
 * const layout = getLayout({ duration: 8, h100: 1, h150: 2, h200: 3, mileage: { car: 10, bike: 20 }, onCall: { wasOnCall: true, hoursOnCall: 8, workedOnCall: 8, h100: 1, h150: 2, h200: 3 }, workedAbroadCountry: 'België', remark: 'Opmerking' }, { complexForm: true, complexFormInstruction: 'Vul de uren in', onCall: 2, onCallInstruction: 'Vul de uren in' });
 * // layout = { custom: { main: { className: 'form-group', tag: 'div', fields: [ 'duration', 'h100', 'h150', 'h200', 'mileage.car', 'mileage.bike' ], description: 'Vul de uren in' }, call: { className: 'form-group', tag: 'div', fields: [ 'onCall.wasOnCall', 'onCall.hoursOnCall', 'onCall.workedOnCall', 'onCall.h100', 'onCall.h150', 'onCall.h200' ], description: 'Vul de uren in' }, abroad: { className: 'form-group', tag: 'div', fields: [ 'workedAbroadCountry' ] }, remark: { className: 'form-group', tag: 'div', fields: [ 'remark' ] } } };
 */
export const getLayout = (formData, project) => {
	const showOnCall = Object.keys(formData).some(key =>
		key.includes('onCall'),
	);
	const baseLayout = {
		main: {
			className: 'form-group',
			tag: 'div',
			fields: [
				'duration',
				'h100',
				'h150',
				'h200',
				'mileage.car',
				'mileage.bike',
			],
			...(project.complexForm && project.complexFormInstruction
				? {
						description: project.complexFormInstruction,
					}
				: {}),
		},
		...(showOnCall && {
			call: {
				className: 'form-group',
				tag: 'div',
				fields: [
					'onCall.wasOnCall',
					'onCall.hoursOnCall',
					'onCall.workedOnCall',
					'onCall.h100',
					'onCall.h150',
					'onCall.h200',
				],
				...(project.onCall > 1 && project.onCallInstruction
					? {
							description: project.onCallInstruction,
						}
					: {}),
			},
		}),
		abroad: {
			className: 'form-group',
			tag: 'div',
			fields: ['workedAbroadCountry'],
		},
		remark: {
			className: 'form-group',
			tag: 'div',
			fields: ['remark'],
		},
	};
	return {
		custom: Object.keys(baseLayout).reduce((acc, group) => {
			const fields = baseLayout[group].fields.filter(
				field => formData[field],
			);
			acc[group] = {
				...baseLayout[group],
				fields,
			};
			return acc;
		}, {}),
	};
};
