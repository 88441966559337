import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

import { store } from '../store.config';

export const loadCars = () => {
	const state = store.getState();
	const { carsLoading } = state.carReducer;
	if (!carsLoading) {
		dispatchAction({
			type: ACTION_TYPES.CAR_FLEET_LOAD,
			payload: { carLoading: true },
			meta: {
				action: {
					effect: {
						url: `/carfleet`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.CAR_FLEET_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.CAR_FLEET_LOAD,
						payload: { carsLoading: false },
					},
				},
			},
		});
	}
};

export const selectCar = ({ id }) => {
	dispatchAction({
		type: ACTION_TYPES.CAR_SELECT,
		payload: { carId: id },
	});
};
