import './Table.scss';

/**
 * @typedef {
 *   [key: string]: string | number
 * } TableDataShape
 */

/**
 * A reusable table component
 * @param {{
 *   headers: TableDataShape,
 *   data: TableDataShape[]
 * 	 totalColumn: string,
 * }} props
 * @returns {JSX.Element} Table component
 */
const Table = ({ headers, data, totalColumn }) => {
	return (
		<table className='table'>
			<thead>
				<tr>
					{Object.keys(headers).map((key, index) => (
						<th key={index}>{headers[key]}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, index) => (
					<tr key={index} className={row.className || ''}>
						{Object.keys(headers).map((key, index) => (
							<td key={index}>{row[key]}</td>
						))}
					</tr>
				))}
			</tbody>
			{totalColumn && headers[totalColumn] ? (
				<tfoot>
					<tr>
						<td>Totaal</td>
						{Object.keys(headers)
							.slice(0, -2)
							.map((_key, index) => (
								<td key={index} />
							))}
						<td>
							{data.reduce(
								(acc, col) => acc + +col[totalColumn],
								0,
							)}
						</td>
					</tr>
				</tfoot>
			) : null}
		</table>
	);
};

export default Table;
