import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { getYearAndMonth } from 'core/services/date';

export const createAbsence = async date => {
	try {
		const {
			data: { data, status },
		} = await executeAxios({
			url: '/absences',
			method: 'POST',
			data: {
				date,
			},
		});
		handleAlert('success', 'Afwezigheid is succesvol toegevoegd.');
		return status === 'success' ? data : null;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het toevoegen van de afwezigheid.',
		);
		return null;
	}
};

export const updateAbsence = async absence => {
	try {
		const {
			data: { data, status },
		} = await executeAxios({
			url: `/absences/${absence.id}`,
			method: 'PUT',
			data: {
				date: absence.date,
				hours: absence.duration,
				type: absence.absenceTypeId,
				remark: absence.remark || '',
			},
		});
		handleAlert('success', 'Afwezigheid is succesvol aangepast.');
		return status === 'success' ? data : null;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het aanpassen van de afwezigheid.',
		);
		return null;
	}
};

export const deleteAbsence = async absence => {
	try {
		const { status } = await executeAxios({
			url: `/absences/${absence.id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Afwezigheid is succesvol verwijderd.');
		return status === 204;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het verwijderen van de afwezigheid.',
		);
		return false;
	}
};

export const fetchAbsences = async currentDate => {
	const url = '/absences';
	const params = getYearAndMonth(currentDate);
	try {
		const {
			data: { data },
		} = await executeAxios({ url, params });
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de afwezigheden.',
		);
		return null;
	}
};

export const getCounters = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/absences/counters',
		});
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de afwezigheden.',
		);
	}
};

export const loadOverview = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/absences/all',
		});
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van het overzicht.',
		);
		return null;
	}
};
