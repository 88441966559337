import React, { forwardRef } from 'react';
import { node, object, string } from 'prop-types';

const Container = forwardRef(({ className, style, children }, ref) => {
	let string = ['container'];
	className && string.push(className);

	return (
		<section className={string.join(' ')} style={style} ref={ref}>
			{children}
		</section>
	);
});

Container.displayName = 'Container';

Container.propTypes = {
	className: string,
	style: object,
	children: node,
};

export default Container;
