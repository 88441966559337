import { bool, func, string } from 'prop-types';

import './TimePicker.scss';

const createHours = () => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		for (let j = 0; j < 60; j += 30) {
			hours.push(`${i < 10 ? '0' : ''}${i}:${j < 10 ? '0' : ''}${j}`);
		}
	}
	return hours;
};
export default function TimePicker({
	value = '',
	handleChange,
	disabled = false,
}) {
	const empty = '__:__';

	return (
		<div className={`timepicker${disabled ? ' disabled' : ''}`}>
			<span className={`display${!value ? ' empty' : ''}`}>
				{value || empty}
			</span>
			<select
				disabled={disabled}
				className='dropdown'
				onChange={e => handleChange(e.target.value)}>
				{createHours().map((hour, index) => (
					<option className='hour-option' key={index}>
						{hour}
					</option>
				))}
			</select>
		</div>
	);
}

TimePicker.propTypes = {
	value: string,
	handleChange: func.isRequired,
	disabled: bool,
};
