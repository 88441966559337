import useClickOutside from 'core/hooks/useClickOutside';

import './OverviewPopup.scss';

const OverviewPopup = ({
	showFooter,
	title,
	close,
	children,
	count,
	onFooterClick,
	onFooterClickLess,
	showAllNotifications,
}) => {
	const ref = useClickOutside(close);

	return (
		<div className='overview-popup' ref={ref}>
			<div className='overview-popup__arrow' />
			<div className='overview-popup__inner-arrow' />
			<div className='overview-popup__header'>
				<span>
					{title}&nbsp;({count || 0})
				</span>
			</div>
			<div className='overview-popup__body'>{children}</div>
			{showFooter ? (
				showAllNotifications ? (
					<div
						className='overview-popup__footer'
						onClick={onFooterClickLess}>
						<span>Bekijk minder</span>
					</div>
				) : (
					<div
						className='overview-popup__footer'
						onClick={onFooterClick}>
						<span>Bekijk alles</span>
					</div>
				)
			) : null}
		</div>
	);
};

export default OverviewPopup;
