import { array, bool, func, number, string } from 'prop-types';

import UiInput from '../UiInput/UiInput';
import useFormInput from '../useFormInput';

const Textarea = ({
	name,
	label = '',
	description = '',
	value,
	placeholder = '',
	validate,
	required = false,
	readOnly = false,
	rows = 5,
	disabled = false,
	validations = [],
	errors = [],
	onChange,
	className,
	validateOnChange = false,
}) => {
	const { focused, id, ...handlers } = useFormInput({
		name,
		validate,
		errors,
		onChange,
		validations,
		value,
		validateOnChange,
	});

	return (
		<UiInput
			name={name}
			hasValue={value ? value.length > 0 : null}
			errorMsgs={errors.length ? errors : []}
			label={label}
			required={required}
			description={description}
			className={className}
			disabled={disabled}
			focused={focused}
			id={id}>
			<textarea
				rows={rows}
				name={name}
				id={id}
				value={value}
				placeholder={`${placeholder}${
					placeholder && required && !label ? ' *' : ''
				}`}
				{...handlers}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</UiInput>
	);
};

Textarea.propTypes = {
	name: string,
	label: string,
	placeholder: string,
	description: string,
	value: string,
	required: bool,
	readOnly: bool,
	disabled: bool,
	validations: array,
	errors: array,
	rows: number,
	onChange: func.isRequired,
	validate: func.isRequired,
	validateOnChange: bool,
};

export default Textarea;
