import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './InfoCard.scss';

const InfoCard = ({
	title,
	subtitle,
	handleDelete,
	handleChange,
	deleteDisabled,
	changeDisabled,
	infoIcon,
}) => {
	return (
		<div className='list-item'>
			<div className='list-item__icon'>
				<FontAwesomeIcon icon={infoIcon} fixedWidth />
			</div>
			<div className='list-item__info'>
				<span className='list-item__type'>{title}</span>
				<span className='list-item__price'>{subtitle}</span>
			</div>
			<div className='list-item__actions'>
				{changeDisabled ? (
					<span onClick={handleChange} className='list-item__edit'>
						<FontAwesomeIcon icon={faEye} fixedWidth />
					</span>
				) : (
					<span onClick={handleChange} className='list-item__edit'>
						<FontAwesomeIcon icon={faPen} fixedWidth />
					</span>
				)}
				<span
					onClick={() => (deleteDisabled ? null : handleDelete())}
					className='list-item__delete'>
					<FontAwesomeIcon
						icon={faTrash}
						className={`${deleteDisabled ? 'disabled' : ''}`}
						fixedWidth
					/>
				</span>
			</div>
		</div>
	);
};

export default InfoCard;
