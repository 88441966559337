import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

//Dit is basically de HTTP Request.

export const loadAvailableTags = () => {
	dispatchAction({
		type: ACTION_TYPES.TAGS_INIT,
		payload: [],
		meta: {
			action: {
				effect: {
					url: `/tags`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.TAGS_INIT },
				rollback: {},
			},
		},
	});
};

//Belangerijk
export const loadTags = type => {
	dispatchAction({
		//Dit is de reducer en de payload word meegegeven.
		type: ACTION_TYPES.TAGS_LOAD,
		payload: { type },
		meta: {
			action: {
				effect: {
					url: `/${type}/tags`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.TAGS_LOAD_COMMIT,
					payload: { type },
				},
				rollback: {},
			},
		},
	});
};

export const tagListener = (id, type, data) => {
	dispatchAction({
		type: ACTION_TYPES.TAGS_LOAD_COMMIT,
		payload: { type, id, data },
	});
};
