//todo when backend is implemented resolve no useles frangment
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Container, Row } from 'components/Grid';

import ItemCard from './ListOverviewItem';

import './ListOverview.scss';

export const ListOverview = ({ type, list, showArchived }) => {
	//Poging tot pagineren
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(9);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	let currentItems = list.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = ({ selected }) => {
		setCurrentPage(selected + 1);
	};

	return (
		<Container className='list-container'>
			{showArchived ? (
				<Row>
					{currentItems
						? currentItems.map((item, index) => (
								<ItemCard
									item={item}
									key={index}
									type={type}
									fullSizeLink
								/>
							))
						: null}
					<ReactPaginate
						onPageChange={paginate}
						pageCount={Math.ceil(list.length / postsPerPage)}
						previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
						nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
						containerClassName='pagination'
						pageLinkClassName='page-number'
						previousLinkClassName='prev-page arrow-label'
						nextLinkClassName='next-page arrow-label'
						activeLinkClassName='active'
					/>
				</Row>
			) : (
				<Row>
					{list.length != 0 ? (
						list.map((item, index) => (
							<Col xl={6} lg={12} key={index}>
								<ItemCard
									item={item}
									type={type}
									fullSizeLink
								/>
							</Col>
						))
					) : (
						<p className='no-data-message'>
							Er zijn momenteel nog geen {type} om weer te geven.{' '}
						</p>
					)}
				</Row>
			)}
		</Container>
	);
};
