import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	faBackward,
	faCheck,
	faForward,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSubmit } from 'core/hooks/useSubmit';
import {
	getCurrentWeek,
	getDateObject,
	oneWeekAfter,
	oneWeekBefore,
	shortNameDays,
	toShortDate,
	toTechnicalDate,
} from 'core/services/date';
import { updateUserData } from 'core/store/authentication/auth.actions';

import { Button } from 'components/Form';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import SelectBlock from 'components/SelectBlock/SelectBlock';

import { TekenModal } from '../Modals/TekenModal/TekenModal';
import { TimesheetModal } from '../Modals/TimesheetModal/TimesheetModal';
import { TimesheetRow } from '../TimesheetRow/TimesheetRow';
import { getItemState } from '../TimesheetRowItem/timesheetDay.helper';
import TimesheetItem from '../TimesheetRowItem/TimesheetRowItem';

import {
	createSelectWeekOptions,
	createUpdate,
	getTotalRealHoursForTheWeek,
	getTotalWorkHoursForTheWeek,
	getWeeklyAbsences,
} from './timesheet.helpers';
import {
	deleteTimesheet,
	getLockDate,
	getTimesheets,
	makeCopyRequest,
	setTimesheetDisplayNotation,
	submitTimesheets,
	uploadTimesheet,
} from './timesheet.service';

import './TimesheetForm.scss';

export const TimesheetForm = () => {
	const { settings } = useSelector(state => state.authReducer);
	const [modal, setModal] = useState();
	const [timesheetData, setProjectData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [showWeekSelector, setShowWeekSelector] = useState(false);

	const navigate = useNavigate();
	const notation = settings?.timesheetDisplay || 'time';

	const getTimesheetData = async ({ year, month, day } = {}) => {
		setLoading(true);
		try {
			const timesheetData = await getTimesheets({ year, month, day });
			setProjectData(timesheetData);
			setError(false);
		} catch (error) {
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getTimesheetData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = useSubmit(async () => {
		const success = await submitTimesheets();
		if (success) {
			await getTimesheetData();
		}
	});

	const handleStartSubmit = async () => {
		const lockDate = await getLockDate();
		setModal({ lockDate });
	};

	const copyWholeWeekFromPreviousWeek = async () => {
		const { data } = await makeCopyRequest();
		const newProjectData = {
			...timesheetData,
			projects: timesheetData?.projects?.map((project, index) => {
				return {
					...project,
					...data.projects[index],
				};
			}),
		};
		setProjectData(newProjectData);
	};

	const deleteDayDataForProject =
		projectIndex => dayIndex => async newData => {
			const deleted = await deleteTimesheet(
				timesheetData.projects[projectIndex].id,
				timesheetData.dates[dayIndex].date,
			);
			if (deleted) {
				setProjectData(prev => {
					const { newProjectData } = createUpdate(
						dayIndex,
						newData,
						projectIndex,
						prev,
					);
					return newProjectData;
				});
			}
		};

	const updateDayDataForProject =
		projectIndex => async (dayIndex, newDayData, method) => {
			try {
				const { payload } = createUpdate(
					dayIndex,
					newDayData,
					projectIndex,
					timesheetData,
				);
				const responseData = await uploadTimesheet(payload, method);

				setProjectData(prev => {
					const { newProjectData } = createUpdate(
						dayIndex,
						responseData,
						projectIndex,
						prev,
					);
					return newProjectData;
				});
			} catch (error) {
				console.log('error', error);
			}
		};

	const handlePrevWeek = () => {
		const previousWeek = oneWeekBefore(timesheetData?.dates[0].date);
		getTimesheetData(previousWeek);
	};

	const handleNextWeek = () => {
		const nextWeek = oneWeekAfter(timesheetData?.dates[0].date);
		getTimesheetData(nextWeek);
	};

	const totalWorkHoursAndAbsencesForTheWeek =
		getTotalWorkHoursForTheWeek(timesheetData);

	const totalRealHoursForTheWeek = getTotalRealHoursForTheWeek(timesheetData);

	const changeNotation = async notation => {
		const settings = await setTimesheetDisplayNotation(notation);
		if (settings) {
			updateUserData({ settings });
		}
	};

	return error ? (
		<div className='timesheet__message'>
			Er is iets misgegaan. Probeer later opnieuw.
		</div>
	) : loading ? (
		<Loading />
	) : (
		<section className='timesheet-container'>
			<section className='timesheet-navigatie-container'>
				<button
					className='btn btn--secondary previous'
					onClick={handlePrevWeek}>
					<FontAwesomeIcon icon={faBackward} className='icon' />
				</button>

				<button
					className='btn btn--secondary current'
					onClick={e => {
						e.stopPropagation();
						setShowWeekSelector(prev => !prev);
					}}>
					<span>{toShortDate(timesheetData?.dates[0].date)}</span>
					<span> - </span>
					<span>{toShortDate(timesheetData?.dates[6].date)}</span>
					{showWeekSelector ? (
						<SelectBlock
							options={createSelectWeekOptions(
								timesheetData?.dates[0].date,
							)}
							initialValue={toTechnicalDate(
								new Date(timesheetData?.dates[0].date),
							)}
							onChange={value => {
								getTimesheetData(getDateObject(value));
								setShowWeekSelector(false);
							}}
							close={() => setShowWeekSelector(false)}
						/>
					) : null}
				</button>

				<button
					className='btn btn--secondary next'
					onClick={handleNextWeek}>
					<FontAwesomeIcon icon={faForward} className='next icon' />
				</button>
			</section>
			{timesheetData?.projects ? (
				<section className='timesheet-table'>
					<section className='timesheet-table__header'>
						<section className='timesheet-table__header__notation'>
							<label htmlFor={notation}>
								<input
									className='notation-checkbox'
									checked={notation === 'time'}
									value='time'
									type='checkbox'
									onChange={() => {
										changeNotation('time');
									}}
								/>
								tijd
							</label>
							<label htmlFor={notation}>
								<input
									className='notation-checkbox'
									checked={notation === 'decimal'}
									value='number'
									type='checkbox'
									onChange={() => {
										changeNotation('decimal');
									}}
								/>
								decimaal
							</label>
						</section>
						<section className='timesheet-table__header__weekdays'>
							{timesheetData?.dates.map((datum, index) => (
								<div className='weekday-wrapper' key={index}>
									<div className='right-table-section datum-label'>
										<span className='weekdag'>
											{shortNameDays[index]}
										</span>
										<span className='datum'>
											{toShortDate(datum.date)}
										</span>
									</div>
								</div>
							))}
						</section>
					</section>

					{timesheetData.projects.map((project, index) => (
						<TimesheetRow
							key={index}
							project={project}
							totalBookedHours={
								totalWorkHoursAndAbsencesForTheWeek
							}
							totalRealHoursForTheWeek={totalRealHoursForTheWeek}
							updateDayData={updateDayDataForProject(index)}
							deleteDayData={deleteDayDataForProject(index)}
							formatState={notation}
							copyAllAction={copyWholeWeekFromPreviousWeek}
							setModal={setModal}
							complexViewModal={true}
							lastApprovedDate={timesheetData?.lastApprovedDate}
						/>
					))}

					<section className='timesheet-table__endContent'>
						{/* Afwezigheden row */}
						<section className='timesheet-table__row absence-row'>
							<section className='timesheet-table__labelContent'>
								<h2>Afwezigheden</h2>
							</section>
							<section className='timesheet-table__hourContent'>
								{getWeeklyAbsences(timesheetData).map(
									(absence, index) => (
										<TimesheetItem
											key={index}
											day={absence}
											simple={true}
											editable={false}
											formatState={notation}
											totalBookedHours={absence.duration}
											totalRealHours={
												totalRealHoursForTheWeek[index]
													.realDuration
											}
											itemState={getItemState({
												day: {
													locked: false,
													combinedHours:
														absence.duration,
												},
												totalRealHours:
													totalRealHoursForTheWeek[
														index
													].realDuration,
												dayHours: 8, // TODO: make this dynamic
											})}
										/>
									),
								)}
							</section>
						</section>
						{/* Totaal row */}
						<section className='timesheet-table__row total-row'>
							<section className='timesheet-table__labelContent'>
								<h2>
									Totaal - week{' '}
									{getCurrentWeek(
										timesheetData?.dates[0].date,
									)}
								</h2>
							</section>
							<section className='timesheet-table__hourContent'>
								{totalWorkHoursAndAbsencesForTheWeek.map(
									(day, index) => (
										<TimesheetItem
											key={index}
											day={day}
											simple={true}
											editable={false}
											formatState={notation}
											totalBookedHours={day.duration}
											totalRealHours={
												totalRealHoursForTheWeek[index]
													.realDuration
											}
										/>
									),
								)}
							</section>
						</section>
					</section>
					{modal ? (
						modal.view ? (
							<TimesheetModal modal={modal} setModal={setModal} />
						) : modal === 'teken_project' ? (
							<TekenModal setModal={setModal} />
						) : modal.lockDate ? (
							<Modal
								title='Timesheet Indienen'
								show={modal}
								cancel={false}
								small={true}
								className='timesheet-submit-modal'
								close={() => setModal(null)}>
								<div>
									<p>
										Ben je zeker dat je je timesheet wil
										indienen?
									</p>
									<p>
										Je gegevens zullen worden geblokkeerd
										tot en met {toShortDate(modal.lockDate)}
										.
									</p>
									<div className='modal__actions'>
										<div className='btn-group'>
											<Button
												className='success'
												buttonStyle='primary'
												onClick={async () => {
													await handleSubmit();
													setModal(null);
												}}
												label='Ja'
											/>
											<Button
												className='error'
												buttonStyle='primary'
												onClick={() => setModal(null)}
												label='Nee'
											/>
										</div>
									</div>
								</div>
							</Modal>
						) : (
							<Modal show={modal} close={() => setModal(null)}>
								<p>Er is iets misgegaan</p>
							</Modal>
						)
					) : null}
				</section>
			) : (
				<div className='timesheet__message'>
					<span className='no-data-message'>
						Er is geen data voor deze periode
					</span>
				</div>
			)}

			<section className='timesheet-footer'>
				<div className='timesheet-footer__left'>
					<Button
						onClick={() => navigate('/timesheet/tekenen')}
						buttonStyle='secondary'>
						Teken timesheets
					</Button>
				</div>

				{timesheetData?.projects ? (
					<section className='timesheet-footer__submit'>
						<Button
							buttonStyle='primary'
							onClick={handleStartSubmit}>
							Indienen
						</Button>
						{timesheetData?.lastApprovedDate ? (
							<span className='timesheet-footer__submit__last-approved-date'>
								<FontAwesomeIcon icon={faCheck} />
								Timesheet laatst ingediend op{' '}
								{toShortDate(timesheetData?.lastApprovedDate)}
							</span>
						) : (
							<span className='timesheet-footer__submit__last-approved-date'>
								Nog geen timesheet ingediend
							</span>
						)}
					</section>
				) : null}
			</section>
		</section>
	);
};
