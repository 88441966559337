import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { bool } from 'prop-types';

const UnprotectedRoutes = ({ loggedIn }) => {
	const location = useLocation();

	return loggedIn ? (
		<Navigate to={location.state ? location.state.from : '/'} />
	) : (
		<Outlet />
	);
};

UnprotectedRoutes.propTypes = {
	loggedIn: bool.isRequired,
};

export default UnprotectedRoutes;
