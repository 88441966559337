import { useSelector } from 'react-redux';
import { faMedal, faStar } from '@fortawesome/free-solid-svg-icons';

import MovingMissionSidebar from '../Sidebar/MovingMissionSidebar';

import MovingMissionRankingList from './MovingMissionRankingList';

const MovingMissionOverview = () => {
	const { movingMission } = useSelector(state => state.movingMissionReducer);

	return (
		<section className='moving-mission-overview'>
			<section className='moving-mission-overview__content'>
				<section className='moving-mission-overview__content__points-ranking'>
					<MovingMissionRankingList
						title='Puntenklassement'
						list={movingMission?.pointsRanking}
						type={{ singular: 'punt', plural: 'punten' }}
						icon={faMedal}
					/>
				</section>
				<section className='moving-mission-overview__content__series-ranking'>
					<MovingMissionRankingList
						title='Reeksklassement'
						list={movingMission?.streakRanking}
						type={{ singular: 'dag', plural: 'dagen' }}
						icon={faStar}
					/>
				</section>
			</section>
			<section className='moving-mission-overview__sidebar'>
				<MovingMissionSidebar />
			</section>
		</section>
	);
};

export default MovingMissionOverview;
