import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from 'core/hooks/ThemeProvider';
import { WebSocketProvider } from 'core/hooks/WebSocketProvider';
import { store } from 'core/store/store.config';
import { registerServiceWorker } from 'registerServiceWorker';

import App from './App';
import msalInstance from './msalInstance.js';
import reportWebVitals from './reportWebVitals';

import './assets/scss/app.scss';
// if translations are added uncomment below line
// import "./i18n"

await msalInstance.initialize();
await registerServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<MsalProvider instance={msalInstance}>
		<Provider store={store}>
			<ThemeProvider>
				<WebSocketProvider>
					<App />
				</WebSocketProvider>
			</ThemeProvider>
		</Provider>
	</MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
