import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import { ListOverview } from 'components/ListOverview/ListOverview';

import './ListPageLayout.scss';

export const ListPageLayout = ({ pageTitle, contentType, contentArray }) => {
	return (
		<section className='item-overview'>
			<PageHeading pageTitle={pageTitle} />
			<ListOverview
				showArchived={false}
				type={contentType}
				list={contentArray}
			/>
		</section>
	);
};
