import {
	faAddressCard,
	faBirthdayCake,
	faBriefcase,
	faComments,
	faHeartBroken,
	faIdCard,
	faPanorama,
} from '@fortawesome/free-solid-svg-icons';

/**
 * @param {string} type
 * @returns {IconDefinition}
 * @constructor
 */
export const SubFeedIconService = type => {
	let icon;

	switch (type) {
		case 'birthday':
			icon = faBirthdayCake;
			break;
		case 'yearsOfService':
			icon = faBriefcase;
			break;
		case 'bioUpdate':
			icon = faAddressCard;
			break;
		case 'pictureUpdate':
			icon = faPanorama;
			break;
		case 'interviewUpdate':
			icon = faComments;
			break;
		case 'profileUpdate':
			icon = faIdCard;
			break;
		case 'failed':
			icon = faHeartBroken;
			break;
		default:
			icon = null;
			break;
	}

	return icon;
};
