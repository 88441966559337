import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { faHeartPulse, faMedal } from '@fortawesome/free-solid-svg-icons';
import { loadMovingMissionTypes } from 'core/store/movingMission/movingMission.actions';

import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import { SecondaryNavigation } from 'components/Navigation/SecondaryNavigation/SecondaryNavigation';

import './MovingMission.scss';

const MovingMission = () => {
	useEffect(() => {
		loadMovingMissionTypes();
	}, []);

	const tabs = [
		{ id: 1, path: 'overzicht', name: 'Overzicht', icon: faMedal },
		{
			id: 2,
			path: 'je-prestaties',
			name: 'Je prestaties',
			icon: faHeartPulse,
		},
	];

	return (
		<div className='moving-mission'>
			<PageHeading
				pageTitle='Moving Mission'
				extraChildrenInsideInleiding={
					<SecondaryNavigation arrayOfTabs={tabs} />
				}
			/>
			<Outlet />
		</div>
	);
};

export default MovingMission;
