import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LayoutRouter } from 'core/routers';
import { loadMovingMission } from 'core/store/movingMission/movingMission.actions';

import { Container } from 'components/Grid';
import Header from 'components/Header/Header';
import { Impersonate } from 'components/Impersonate/Impersonate';
import MovingMission from 'components/MovingMission/MovingMission';
import SideBar from 'components/SideBar/SideBar';
import Toasts from 'components/Snackbar/Snackbar';
import WellBeingBarometer from 'components/WellBeingBarometer/WellBeingBarometer';

import './SiteLayout.scss';

const SiteLayout = () => {
	const { movingMission } = useSelector(state => state.movingMissionReducer);
	const { authenticatedUser: isImpersonating } = useSelector(
		state => state.authReducer,
	);

	useEffect(() => {
		loadMovingMission();
	}, []);

	return (
		<>
			<Header />
			<SideBar />
			<main>
				<Container>
					<WellBeingBarometer />
					<LayoutRouter />
				</Container>
			</main>
			{movingMission ? <MovingMission /> : null}
			{isImpersonating ? <Impersonate /> : null}
			<Toasts />
		</>
	);
};

export default SiteLayout;
