import React, { memo, useRef } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';
import {
	HamburgerNavigation,
	MainNavigation,
	SocialNavigation,
	TopBarNavigation,
} from '../Navigation';

import './Header.scss';

const Header = () => {
	const hamburgerRef = useRef();

	return (
		<header className='header'>
			<div className='header__top-bar'>
				<SocialNavigation />
				<TopBarNavigation />
			</div>

			<div className='header__main'>
				<Link to='/' className='card__read-more'>
					<Icon icon='ketchup_plain' className='header__logo' />
				</Link>

				<MainNavigation
					toggleHamburger={() =>
						hamburgerRef.current.toggleHamburger()
					}
				/>
				<HamburgerNavigation ref={hamburgerRef} />
			</div>
		</header>
	);
};

export default memo(Header);
