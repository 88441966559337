import { node } from 'prop-types';

const CardFooter = ({ children, className }) => {
	return <div className={`card__footer ${className}`}>{children}</div>;
};

CardFooter.propTypes = {
	children: node,
};

export default CardFooter;
