import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLoadItemsOnScroll from 'core/hooks/useLoadItemsOnScroll';
import { loadMainFeeds } from 'core/store/mainFeed/mainFeed.actions';

import EndOfList from '../EndOfList/EndOfList';
import Loading from '../Loading/Loading';
import RenderIfVisible from '../RenderIfVisible/RenderIfVisible';

import MainFeedItem from './MainFeedItem/MainFeedItem';

import './MainFeed.scss';

const MainFeed = () => {
	const {
		mainFeedHasNextPage,
		mainFeedIsNextPageLoading,
		mainFeedsNextPage,
		mainFeeds,
	} = useSelector(state => state.mainFeedReducer);

	useEffect(() => {
		if (mainFeedHasNextPage && !mainFeedIsNextPageLoading) loadMainFeeds(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoadItemsOnScroll(
		mainFeedsNextPage,
		mainFeedHasNextPage,
		mainFeedIsNextPageLoading,
		loadMainFeeds,
	);

	return (
		<div id='home' className='main-feed-list'>
			{mainFeeds.map(mainFeed => {
				return (
					<RenderIfVisible
						placeholderElementClass='card shadow with-stickers with-read-more without-picture main-feed'
						key={mainFeed.id}>
						<MainFeedItem mainFeed={mainFeed} />
					</RenderIfVisible>
				);
			})}

			{mainFeedIsNextPageLoading && mainFeedHasNextPage ? (
				<Loading />
			) : null}

			{!mainFeedHasNextPage ? <EndOfList /> : null}
		</div>
	);
};

export default MainFeed;
