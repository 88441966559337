import { executeAxios, handleAlert } from 'core/services/actions';

export const startImpersonating = async userId => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: `/users/support/${userId}`,
		});
		handleAlert('success', 'Impersonation started');
		return data;
	} catch (error) {
		handleAlert('error', 'Impersonation failed');
	}
};

export const stopImpersonating = async () => {
	try {
		await executeAxios({
			url: `/users/support/stop`,
		});
		handleAlert('success', 'Impersonation stopped');
		return true;
	} catch (error) {
		handleAlert('error', 'Impersonation failed');
		return false;
	}
};

export const getEmployee = async slug => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: `/users/${slug}`,
		});
		return data;
	} catch (error) {
		handleAlert('error', 'Failed to get employee');
	}
};
