import { useRef } from 'react';
import useIsWidthOverflow from 'core/hooks/useIsWidthOverflow';

import { getItemState } from '../TimesheetRowItem/timesheetDay.helper';
import TimesheetItem from '../TimesheetRowItem/TimesheetRowItem';

export const TimesheetRow = ({
	project,
	updateDayData,
	deleteDayData,
	totalBookedHours,
	totalRealHoursForTheWeek,
	formatState,
	setModal,
	complexViewModal,
	// eslint-disable-next-line no-unused-vars
	lastApprovedDate,
}) => {
	const projectName = useRef(null);
	const isOverflow = useIsWidthOverflow(projectName);

	return (
		<section className='timesheet-table__row'>
			<section className='timesheet-table__labelContent'>
				{isOverflow ? (
					<span className='timesheet-table__labelContent__alt'>
						{project.name}
					</span>
				) : null}
				<h2 ref={projectName}>{project.name}</h2>
			</section>

			<section className='timesheet-table__hourContent'>
				{project.dates.map((day, index) => (
					<TimesheetItem
						key={index}
						day={day}
						project={project}
						totalBookedHours={totalBookedHours[index].duration}
						totalRealHours={
							totalRealHoursForTheWeek[index].realDuration
						}
						index={index}
						setModal={setModal}
						formatState={formatState}
						complexViewModal={complexViewModal}
						updateDayData={updateDayData}
						deleteDayData={deleteDayData(index)}
						itemState={getItemState({
							day,
							totalRealHours:
								totalRealHoursForTheWeek[index].realDuration,
							dayHours:
								day?.defaultDuration / (day?.reference || 1),
							absenceDuration:
								totalRealHoursForTheWeek[index].absenceDuration,
						})}
						editable={
							// new Date(day.date) > new Date(lastApprovedDate)
							true
						}
					/>
				))}
			</section>
		</section>
	);
};
