import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
	faCar,
	faFileContract,
	faHome,
	faNotesMedical,
	faPersonRays,
	faSignOutAlt,
	faStar,
	faUser,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import msalInstance from 'msalInstance';

import { Image } from '../Image';

import './SideBar.scss';

const SideBar = () => {
	const { settings, thumbnail } = useSelector(state => state.authReducer);
	const [show, setShow] = useState(false);
	const menuRef = useRef();
	const primaryRef = useRef();
	const navigateTo = useNavigate();

	const { links } = settings ?? { links: [] };

	const handleActionOutside = event => {
		if (
			menuRef.current &&
			menuRef.current.contains(event.target) &&
			primaryRef.current &&
			!primaryRef.current.contains(event.target)
		)
			return true;
		setShow(false);
	};

	const logout = async () => {
		try {
			await msalInstance.logoutPopup();

			navigateTo('/login');
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleActionOutside, false);
		document.addEventListener('wheel', handleActionOutside, false);
		return () => {
			document.removeEventListener(
				'mousedown',
				handleActionOutside,
				false,
			);
			document.removeEventListener('wheel', handleActionOutside, false);
		};
	}, []);

	return (
		<div className={`sidebar${show ? ' sidebar--open' : ''}`} ref={menuRef}>
			<div className='sidebar__header'>
				<section onClick={() => setShow(!show)}>
					<Image
						id={thumbnail ? `${thumbnail}_thumb` : undefined}
						shadow
					/>
					<div className='sidebar__header__image-overlay'>
						<div className='sidebar__header__image-overlay__corner'>
							.
						</div>
					</div>
				</section>
				<div className='button-container'>
					<button
						className='logout'
						aria-label='Log uit'
						onClick={logout}>
						<FontAwesomeIcon className='icon' icon={faSignOutAlt} />
					</button>
				</div>
			</div>
			<nav>
				<ul className='primary-nav-group' ref={primaryRef}>
					<li>
						<NavLink
							to='/'
							className='link'
							onClick={() => setShow(false)}>
							<FontAwesomeIcon
								className='icon'
								icon={faHome}
								fixedWidth
							/>
							<span className='text'>Home</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/timesheet'
							className='link'
							onClick={() => setShow(false)}>
							<FontAwesomeIcon
								className='icon'
								icon={faFileContract}
								fixedWidth
							/>
							<span className='text'>Timesheet</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/user-feed'
							aria-label='User feed'
							className='link'
							onClick={() => setShow(false)}>
							<FontAwesomeIcon
								className='icon'
								icon={faPersonRays}
								fixedWidth
							/>
							<span className='text'>User feed</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/contacten'
							aria-label='contacten'
							className='link'
							onClick={() => setShow(false)}>
							<FontAwesomeIcon
								icon={faUsers}
								className='icon'
								fixedWidth
							/>
							<span className='text'>Contacten</span>
						</NavLink>
					</li>
				</ul>
				<div className='minor-nav-group'>
					<ul className='secondary-nav-group'>
						<li>
							<NavLink
								to='/account'
								aria-label='My account'
								className='link'
								onClick={() => setShow(false)}>
								<FontAwesomeIcon
									className='icon'
									icon={faUser}
									fixedWidth
								/>
								<span className='text'>Mijn account</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/meld-ziekte'
								aria-label='Illness'
								className='link'
								onClick={() => setShow(false)}>
								<FontAwesomeIcon
									icon={faNotesMedical}
									className='icon'
									fixedWidth
								/>
								<span className='text'>Meld ziekte</span>
							</NavLink>
						</li>
						{settings?.hasCar ? (
							<li>
								<NavLink
									to='/wagen'
									aria-label='My car'
									className='link'
									onClick={() => setShow(false)}>
									<FontAwesomeIcon
										icon={faCar}
										className='icon'
										fixedWidth
									/>
									<span className='text'>Mijn wagen</span>
								</NavLink>
							</li>
						) : null}
					</ul>

					{links.length > 0 ? (
						<ul className='extra-nav-group'>
							{links.map((link, index) => (
								<li key={index}>
									<NavLink
										to={link.url}
										aria-label={link.label}
										className='link'
										rel='noopener noreferrer'
										target='_blank'
										onClick={() => setShow(false)}>
										<FontAwesomeIcon
											icon={faStar}
											className='icon'
											fixedWidth
										/>
										<span className='text'>
											{link.label}
										</span>
									</NavLink>
								</li>
							))}
						</ul>
					) : null}
				</div>
			</nav>
		</div>
	);
};

export default SideBar;
