import Modal from 'components/Modal/Modal';
import { AanrijdingsFormulierModal } from 'components/MyCar/AanrijdingsFormulier/AanrijdingsFormulierModal';

import './OngevalModal.scss';

const OngevalModal = ({ modal, setModal }) => {
	return (
		<Modal
			title='Gegevens voor het invullen van de ongevalsaangifte'
			show={!!modal}
			close={() => setModal(null)}>
			<AanrijdingsFormulierModal
				verzekeringsNemerData={modal.insuredPersonData}
				verzekeringsOndernemingData={modal.insurerData}
				BtwData={modal.btwData}
			/>
		</Modal>
	);
};

export default OngevalModal;
