import { useCallback, useEffect, useRef } from 'react';

import useFormInput from '../useFormInput';

import { isRangeRequired, isValidRange } from './validators';

const useRangePicker = ({
	onChange,
	required,
	validations,
	validate,
	value,
	name,
	errors,
}) => {
	const ref = useRef(null);
	const [from = '', until = ''] = value;

	const handleChangeFrom = value => {
		onChange([value, until]);
	};

	const handleChangeUntil = value => {
		onChange([from, value]);
	};

	const { touched, focused, id, onBlur, onFocus } = useFormInput({
		name,
		errors,
		validate,
		onChange,
		validations: [
			...validations,
			...(required ? [isRangeRequired] : []),
			isValidRange,
		],
	});

	/**
	 * Manually handle focus and blur events
	 */
	const handleClicks = useCallback(
		e => {
			const clickOnInput = ref.current?.contains(e.target);

			if (clickOnInput && !focused) {
				onFocus();
			}
			if (!clickOnInput && focused) {
				onBlur();
			}
		},
		[focused, onBlur, onFocus],
	);

	useEffect(() => {
		window.addEventListener('click', handleClicks);
		return () => {
			window.removeEventListener('click', handleClicks);
		};
	});

	return {
		id,
		ref,
		touched,
		focused,
		onFocus,
		handleChangeFrom,
		handleChangeUntil,
		from,
		until,
	};
};

export default useRangePicker;
