import { useNavigate } from 'react-router-dom';
import { toShortDate } from 'core/services/date';

import './Alerts.scss';

const getAlertMessage = item => {
	const alertMap = {
		illness: 'Vervolledig je ziektemelding van ' + toShortDate(item.date),
		accident: 'Vervolledig je schademelding van ' + toShortDate(item.date),
		expense: 'Vervolledig je onkostennota van ' + toShortDate(item.date),
		events:
			'Schrijf je in op ' +
			item.content?.name +
			', uiterlijk voor ' +
			toShortDate(item.content?.lastAttendDate),
		trainings:
			'Schrijf je in op ' +
			item.content?.name +
			', uiterlijk voor ' +
			toShortDate(item.content?.lastAttendDate),

		timesheetendofmonth: 'Dien je timesheets van afgelopen maand in',
		timesheetapprove: 'Dien je timesheets van afgelopen week in',
		timesheetsign:
			'Je hebt timesheets die niet zijn getekend door jou of je eindklant',
	};

	return alertMap[item.type.toLowerCase()];
};

const getAlertUrl = item => {
	const urlMap = {
		illness: '/meld-ziekte',
		accident: '/wagen/ongeval',
		expense: '/timesheet/onkostennotas',
		events: '/evenementen',
		trainings: '/opleidingen',
		timesheetendofmonth: '/timesheet/urenregistratie',
		timesheetapprove: '/timesheet/urenregistratie',
		timesheetsign: '/timesheet/tekenen/overzicht',
	};

	const url = urlMap[item.type.toLowerCase()];

	if (url) {
		if (
			['illness', 'accident', 'expense'].includes(item.type.toLowerCase())
		) {
			return `${url}/${item.id}`;
		}
		if (['events', 'trainings'].includes(item.type.toLowerCase())) {
			return `${url}/${item.content?.slug}`;
		}
		return url;
	}
};

const getTitle = type => {
	const titleMap = {
		illness: 'Ziekte',
		accident: 'Ongeval',
		timesheet: 'Timesheets',
		trainings: 'Opleidingen',
		events: 'Evenementen',
		expense: 'Onkosten',
	};

	if (type.indexOf('timesheet') === 0) {
		return titleMap.timesheet;
	}

	return titleMap[type.toLowerCase()];
};

const Alerts = ({ items, title }) => {
	const navigate = useNavigate();

	const handleClick = item => {
		const url = getAlertUrl(item);
		navigate(url);
	};

	return (
		<div className='alerts'>
			{items?.length ? (
				<ul className='alerts__list'>
					{items.map((item, i) => (
						<li key={i} onClick={() => handleClick(item)}>
							<div className='alerts__item'>
								<div className='alerts__item__header'>
									<span className='alerts__item__title'>
										{getTitle(item.type)}
									</span>
									<span className='alerts__item__date'>
										{toShortDate(item.date)}
									</span>
								</div>
								<span className='alerts__item__content'>
									{getAlertMessage(item) ||
										'Geen omschrijving'}
								</span>
							</div>
						</li>
					))}
				</ul>
			) : (
				<p>Er zijn geen {title.toLowerCase()}</p>
			)}
		</div>
	);
};

export default Alerts;
