export const createImpersonatedUserData = (
	authenticatedUser,
	impersonatedUser,
) => {
	const { role, permissions, settings, thumbnail, ...authUser } =
		impersonatedUser;

	return {
		role,
		permissions,
		settings,
		thumbnail,
		authUser,
		authenticatedUser: {
			...authenticatedUser.authUser,
			role: authenticatedUser.role,
			permissions: authenticatedUser.permissions,
			settings: authenticatedUser.settings,
			thumbnail: authenticatedUser.thumbnail,
		},
	};
};

export const switchBackToAuthenticatedUser = authenticatedUser => {
	const { role, permissions, settings, thumbnail, ...authUser } =
		authenticatedUser;
	return {
		role,
		permissions,
		settings,
		thumbnail,
		authUser,
		authenticatedUser: null,
	};
};
