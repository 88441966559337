import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DisplayLocation = ({ location, hideIcon, extraClass }) => {
	return (
		<section className={`card_row ${extraClass ? extraClass : ''}`}>
			{hideIcon ? null : (
				<span className='sidebar-icon'>
					<FontAwesomeIcon icon={faLocationDot} size='lg' />
				</span>
			)}
			<p
				dangerouslySetInnerHTML={{
					__html: location.replace(', ', '<br />'),
				}}
			/>
		</section>
	);
};
