import React, { useEffect, useState } from 'react';
import { executeAxios } from 'core/services/actions/axios.service';

import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import NewsFeed from 'components/NewsFeed/NewsFeed';
import NewsFeedList from 'components/NewsFeed/NewsFeedList/NewsFeedList';

import './NewsOverview.scss';

const NewsOverview = () => {
	const [searchText, setSearchText] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		const search = async () => {
			const { data } = await executeAxios({
				url: '/news/search',
				params: { search: searchText },
			});
			setSearchResults(data.data);
		};
		let timeoutId = setTimeout(() => {
			if (searchText) {
				search();
			}
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	return (
		<div className='news-overview'>
			<PageHeading
				pageTitle='Nieuws'
				showSearchBar
				onSearch={setSearchText}
			/>
			<div id='home'>
				{searchText ? (
					<div className='news-feed'>
						<NewsFeedList newsFeeds={searchResults} />
					</div>
				) : (
					<NewsFeed />
				)}
			</div>
		</div>
	);
};

export default NewsOverview;
