import { format } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import moment from 'moment';

const flattenObject = obj => {
	const capitalize = s => {
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	function* flatten(obj, prefix = '') {
		for (const key in obj) {
			if (
				typeof obj[key] === 'object' &&
				obj[key] !== null &&
				!Array.isArray(obj[key])
			) {
				yield* flatten(obj[key], `${prefix}${key}`);
			} else {
				yield [`${prefix}${prefix ? capitalize(key) : key}`, obj[key]];
			}
		}
	}
	return flatten(obj);
};

export const parseRegistration = registration => {
	return registration?.reduce((weeks, day) => {
		const date = new Date(day.date);
		const weekNumber = moment(date).isoWeek();
		const flattenDay = {};

		if (!weeks[weekNumber]) {
			weeks[weekNumber] = { days: [], summary: {} };
		}

		for (const [key, value] of flattenObject(day)) {
			flattenDay[key] = value;

			if (typeof value !== 'number') continue;

			if (weeks[weekNumber].summary[key]) {
				weeks[weekNumber].summary[key] += value || 0;
			} else {
				weeks[weekNumber].summary[key] = value || 0;
			}
		}

		weeks[weekNumber].days.push(flattenDay);

		return weeks;
	}, {});
};

export const countTotals = registration => {
	return registration?.reduce((totals, day) => {
		for (const [key, value] of flattenObject(day)) {
			if (typeof value !== 'number') continue;

			if (!totals[key]) {
				totals[key] = value || 0;
			} else {
				totals[key] += value || 0;
			}
		}

		return totals;
	}, {});
};

export const formatDate = date => {
	return format(new Date(date), 'EEEEEE d', {
		locale: nlBE,
	});
};
