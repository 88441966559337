import { useEffect, useState } from 'react';

import FormModal from 'components/Form/FormModal/FormModal';
import { postForm } from 'components/Form/FormModal/formModal.service';
import Loading from 'components/Loading/Loading';

import { getEventForm } from '../EventDetail/eventDetails.service';

const EventFormModal = ({ eventId, setModal, modal }) => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getEventForm(eventId).then(formData => {
			if (formData.format && formData.fields) {
				setModal(modal => ({ ...modal, formData }));
			}
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePostForm = async data => {
		const posted = await postForm({
			formId: modal?.formData?.id,
			values: Object.keys(data).map(key => ({
				id: data[key].id,
				value: data[key].value,
			})),
		});

		if (posted) {
			if (modal?.postSubmitCallback) {
				modal.postSubmitCallback();
			}
			setModal(null);
		}
	};

	return loading ? (
		<Loading />
	) : modal.formData ? (
		<FormModal
			close={() => setModal(null)}
			formData={modal?.formData}
			onSubmit={handlePostForm}
		/>
	) : null;
};

export default EventFormModal;
