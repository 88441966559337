import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import {
	faCar,
	faCarBattery,
	faCarCrash,
} from '@fortawesome/free-solid-svg-icons';
import { loadCars, selectCar } from 'core/store/cars/car.actions';

import { Select } from 'components/Form';
import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import { SecondaryNavigation } from 'components/Navigation/SecondaryNavigation/SecondaryNavigation';

import './MyCar.scss';

export const schadeTabs = [
	{ id: 1, path: 'ongeval', name: 'Ongeval', icon: faCarCrash },
	{
		id: 2,
		path: 'panne-of-glasschade',
		name: 'Panne of glasschade',
		icon: faCarBattery,
	},
	{ id: 3, path: 'mijn-wagen', name: 'Mijn wagen', icon: faCar },
];

const MyCar = () => {
	const { selectedCar, cars } = useSelector(state => state.carReducer);

	useEffect(() => {
		loadCars();
	}, []);

	const { pathname } = useLocation();

	const findTabName = pathname => {
		const path = pathname.split('/').pop();
		const tab = schadeTabs.find(tab => tab.path === path);
		return tab?.name;
	};

	return (
		<section className='wagen-schade-container'>
			<PageHeading
				pageTitle={findTabName(pathname)}
				extraChildrenInsideHeader={
					cars?.length > 1 ? (
						<Select
							name='car-filter-select'
							placeholder='Kiew uw wagen'
							options={cars.map(car => ({
								label:
									car?.brand +
									' ' +
									car?.type +
									' - ' +
									car?.licencePlate,
								value: car ? car : {},
							}))}
							onChange={selectCar}
							value={selectedCar}
							validate={() => {}}
							isClearable={false}
							type='select'
						/>
					) : null
				}
				extraChildrenInsideInleiding={
					<SecondaryNavigation arrayOfTabs={schadeTabs} />
				}
			/>
			<Outlet />
		</section>
	);
};

export default MyCar;
