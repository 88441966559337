import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { INPUT_TYPES } from 'core/constants';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { FormValidation } from 'core/services/form';
import {
	any,
	arrayOf,
	bool,
	func,
	number,
	objectOf,
	shape,
	string,
} from 'prop-types';

import { Col, Container, Row } from 'components/Grid';

import Button from '../Button/Button';
import {
	Datepicker,
	InputCheckbox,
	InputField,
	RangePicker,
	Select,
	Textarea,
	Toggle,
	Upload,
} from '../FormInput';
import { InputRadioButton } from '../InputRadioButton/InputRadioButton';

import './Form.scss';

const Form = ({
	addCancel,
	buttons,
	buttonsFloatLeft,
	cancelOnClick,
	className,
	disableWhenOffline,
	fields,
	layout,
	setFields,
	showDefaultButtons,
	submitLabel,
	submitOnClick,
}) => {
	const [formValid, setFormValid] = useState(FormValidation(fields));
	const online = useOnlineStatus();

	const loadElement = fieldName => {
		const field = fields[fieldName];

		const onChange = value =>
			setFields(prev => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					value,
				},
			}));

		const validate = validations => {
			setFields(prev => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					errors: validations
						.map(errorsFor =>
							errorsFor(field.value, field.label || field.name),
						)
						.filter(errorMsg => errorMsg.length > 0),
				},
			}));
		};

		const props = {
			...fields[fieldName],
			onChange,
			validate,
			disabled:
				fields[fieldName].disabled || (!online && disableWhenOffline),
			key: fieldName,
		};

		switch (fields[fieldName].type) {
			case INPUT_TYPES.CHECKBOX:
				return <InputCheckbox {...props} />;

			case INPUT_TYPES.RADIO:
				return <InputRadioButton {...props} />;

			case INPUT_TYPES.TEXTAREA:
				return <Textarea {...props} />;

			case INPUT_TYPES.SELECT:
				return <Select {...props} />;

			case INPUT_TYPES.TIMERANGE:
				return <RangePicker {...props} />;

			case INPUT_TYPES.DATEPICKER:
				return <Datepicker {...props} />;

			case INPUT_TYPES.TOGGLE:
				return <Toggle {...props} />;

			case INPUT_TYPES.FILE:
				return <Upload {...props} />;

			default:
				return <InputField {...props} />;
		}
	};

	const loadButton = (button, i) => {
		const { buttonType, buttonStyle, action, style, label, disabled } =
			button;

		return (
			<Button
				onClick={action}
				type={buttonType}
				buttonStyle={buttonStyle}
				label={label}
				style={style}
				disabled={!disabled}
				key={i}
			/>
		);
	};

	const loadLink = (link, i) => {
		const { action, style, label } = link;

		return (
			<Link to={action} className='btn btn--link' style={style} key={i}>
				{label}
			</Link>
		);
	};

	/**
	 * Create the structure of the form using the layout parameter. If a custom layout is passed, it will use it to create the form. If a grid layout is passed, it will use the Grid components (Container, Col and Row) to create the form.
	 * @returns {JSX.Element}
	 */
	const loadGroups = () => {
		const { custom, grid } = layout;
		if (custom) {
			return Object.keys(custom).map((group, i) => {
				const {
					tag: Tag,
					className,
					fields,
					description,
				} = custom[group];
				return (
					<div key={i}>
						<Tag className={className} key={group}>
							{fields.map(field => {
								return loadElement(field);
							})}
						</Tag>
						{description ? (
							<span className='form-group-description'>
								<span
									dangerouslySetInnerHTML={{
										__html: description.replace(
											/(\\r)?\\n/g,
											'<br />',
										),
									}}
								/>
							</span>
						) : null}
					</div>
				);
			});
		} else if (grid) {
			let counter = 0;
			return (
				<Container>
					{grid.map(row => {
						return (
							<Row key={counter++}>
								{row.map(({ width, fields, children }) => {
									return (
										<Col key={`${counter++}`} {...width}>
											{children ? (
												<Row>
													{children.map(field => {
														return (
															<Col
																{...field.width}
																key={counter++}>
																{field.fields.map(
																	loadElement,
																)}
															</Col>
														);
													})}
												</Row>
											) : (
												fields.map(loadElement)
											)}
										</Col>
									);
								})}
							</Row>
						);
					})}
				</Container>
			);
		}
	};

	useEffect(() => {
		setFormValid(FormValidation(fields));
	}, [fields]);

	return (
		<div className={className}>
			{layout
				? loadGroups()
				: Object.keys(fields).map(field => loadElement(field))}

			<div
				className={`btn__group${
					buttonsFloatLeft ? ' float-left' : ''
				}`}>
				{showDefaultButtons ? (
					<Button
						onClick={submitOnClick}
						buttonStyle='primary'
						label={submitLabel}
						disabled={!formValid}
					/>
				) : null}

				{addCancel ? (
					<Button
						onClick={cancelOnClick}
						buttonStyle='secondary'
						label='Annuleren'
					/>
				) : null}

				{buttons.map((button, i) => {
					if (button.type === 'link') {
						return loadLink(button, i);
					} else {
						return loadButton(button, i);
					}
				})}
			</div>
		</div>
	);
};

Form.propTypes = {
	fields: objectOf(
		shape({
			autocomplete: bool,
			description: string,
			disabled: bool,
			errors: arrayOf(string),
			label: string,
			name: string.isRequired,
			placeholder: string,
			readOnly: bool,
			required: bool,
			type: string.isRequired,
			validations: arrayOf(func),
			value: any,
			isMulti: bool,
		}),
	),
	setFields: func.isRequired,
	submitLabel: string,
	submitOnClick: (props, propName, componentName) => {
		if (
			props.showDefaultButtons &&
			(!props[propName] || typeof props[propName] !== 'function')
		) {
			return new Error(
				`Missing prop ${propName} supplied to ${componentName}. Validation failed.`,
			);
		}
	},
	addCancel: bool,
	buttonsFloatLeft: bool,
	className: string,
	disableWhenOffline: bool,
	layout: shape({
		grid: arrayOf(
			arrayOf(
				shape({
					width: shape({
						lg: number,
						md: number,
					}),
					fields: arrayOf(string),
					children: arrayOf(
						shape({
							width: shape({
								lg: number,
								md: number,
							}),
							fields: arrayOf(string),
						}),
					),
				}),
			),
		),
		custom: objectOf(
			shape({
				tag: string,
				className: string,
				fields: arrayOf(string),
			}),
		),
	}),
	showDefaultButtons: bool,
};

export default Form;
