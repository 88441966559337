import { faDownload, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, shape, string } from 'prop-types';

import { Button } from 'components/Form';

const TimesheetOverviewItemLine = ({
	title,
	value,
	signed,
	action,
	filename,
}) => {
	const handleButtonClick = e => {
		e.preventDefault();
		if (action) {
			action();
		}
	};

	return (
		<div
			className='timesheet-overview-item__line'
			style={{ display: 'flex', justifyContent: 'space-between' }}>
			<div className='timesheet-overview-item__line__text'>
				<span>{title}</span>
				{value ? <span>&nbsp;-&nbsp;{value}</span> : null}
			</div>
			<div className='timesheet-overview-item__line__actions'>
				{signed !== undefined ? (
					<FontAwesomeIcon
						icon={faSignature}
						className={`${
							signed.client
								? 'signed'
								: signed.emp
									? 'half-signed'
									: 'unsigned'
						}`}
					/>
				) : null}
				<Button
					disabled={filename === null}
					buttonStyle='link'
					onClick={handleButtonClick}
					className='timesheet-overview-item__line__actions__button'>
					<FontAwesomeIcon icon={faDownload} />
				</Button>
			</div>
		</div>
	);
};

TimesheetOverviewItemLine.propTypes = {
	title: string.isRequired,
	value: string,
	signed: shape({
		emp: bool,
		client: bool,
	}),
	action: func,
};

export default TimesheetOverviewItemLine;
