import { NavLink } from 'react-router-dom';
import {
	faBars,
	faGlassCheers,
	faGraduationCap,
	faHome,
	faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func } from 'prop-types';

const MainNavigation = ({ toggleHamburger }) => {
	return (
		<nav className='header__nav-bar'>
			<ul>
				<li className='header__nav-bar_item'>
					<NavLink to='/' className='header__nav-bar_link'>
						<FontAwesomeIcon
							icon={faHome}
							className='icon'
							fixedWidth
						/>
						<span>Home</span>
					</NavLink>
				</li>

				<li className='header__nav-bar_item trainings'>
					<NavLink to='/opleidingen' className='header__nav-bar_link'>
						<FontAwesomeIcon
							icon={faGraduationCap}
							className='icon'
							fixedWidth
						/>
						<span>Opleidingen</span>
					</NavLink>
				</li>

				<li className='header__nav-bar_item events'>
					<NavLink to='/evenementen' className='header__nav-bar_link'>
						<FontAwesomeIcon
							icon={faGlassCheers}
							className='icon'
							fixedWidth
						/>
						<span>Evenementen</span>
					</NavLink>
				</li>

				<li className='header__nav-bar_item news'>
					<NavLink to='/nieuws' className='header__nav-bar_link'>
						<FontAwesomeIcon
							icon={faNewspaper}
							className='icon'
							fixedWidth
						/>
						<span>Nieuws</span>
					</NavLink>
				</li>

				<li className='header__nav-bar_item hamburger'>
					<button
						onClick={toggleHamburger}
						aria-label='Volledig menu'>
						<FontAwesomeIcon icon={faBars} fixedWidth />
					</button>
				</li>
			</ul>
		</nav>
	);
};

MainNavigation.propTypes = {
	toggleHamburger: func,
};

export default MainNavigation;
