export const createModalData = insurance => {
	return {
		insurerData: {
			naam: insurance.company || 'Zie verzekeringsattest',
			contractnummer:
				insurance.contractNumber || 'Zie verzekeringsattest',
			nummerGroeneKaart:
				insurance.greenCard.policyNumber || 'Zie verzekeringsattest',
			groeneKaartGeldigVan: insurance.greenCard.startDate,
			groeneKaartGeldigTot: insurance.greenCard.endDate,
			telefoon: insurance.telephone,
		},
		insuredPersonData: {
			naam: 'Unipartners Group',
			adres: 'Kardianaal Cardijnstraat 7',
			postcode: '2840',
			land: 'België',
			telefoon: '03/202 43 10',
			mail: 'ehbo@unipartners.be',
		},
		btwData: {
			beroep_eigenaar: 'mag je open laten',
			registratieNummer: 'BE0454.644.839',
			BTW_aftrekbaar: true,
			BTW_aftrekbaar_hoeveelheid: 'gedeeltelijk 50',
		},
	};
};
