import { faClock } from '@fortawesome/free-solid-svg-icons';
import { arrayOf, bool, func, string } from 'prop-types';

import UiInput from '../UiInput/UiInput';

import TimePicker from './TimePicker/TimePicker';
import useRangePicker from './useRangePicker';

import './RangePicker.scss';

const RangePicker = ({
	onChange,
	required,
	validations,
	validate,
	value,
	label,
	name,
	errors,
	disabled,
	className,
	hasValue,
	description,
}) => {
	const {
		id,
		focused,
		touched,
		ref,
		handleChangeFrom,
		handleChangeUntil,
		onFocus,
		from,
		until,
	} = useRangePicker({
		onChange,
		required,
		validate,
		validations,
		value,
		label,
		name,
		errors,
	});

	return (
		<UiInput
			id={id}
			focused={focused}
			icon={faClock}
			description={description}
			hasValue={hasValue}
			errorMsgs={touched ? [...errors] : []}
			className={`fixed-width ${className}`}>
			<div
				ref={ref}
				tabIndex='0'
				className='range-picker'
				onFocus={onFocus}>
				<div className='hour'>
					<TimePicker
						disabled={disabled}
						value={from}
						handleChange={handleChangeFrom}
					/>
				</div>
				<span className='separator'>tot</span>
				<div className='hour'>
					<TimePicker
						value={until}
						disabled={disabled}
						handleChange={handleChangeUntil}
					/>
				</div>
			</div>
		</UiInput>
	);
};

RangePicker.propTypes = {
	onChange: func.isRequired,
	validate: func.isRequired,
	required: bool,
	validations: arrayOf(func),
	value: arrayOf(string),
	label: string,
	name: string,
	errors: arrayOf(string),
	disabled: bool,
	className: string,
	hasValue: bool,
	description: string,
};

export default RangePicker;
