import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faInfoCircle, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	isBiggerThan,
	isBiggerThan0,
	isNotExponent,
	isRequired,
} from 'core/services/form';

library.add(fas);

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubmit } from 'core/hooks/useSubmit';
import { updateMovingMission } from 'core/store/movingMission/movingMission.actions';

import {
	Datepicker,
	InputField,
	InputRadioButton,
	Select,
	Upload,
	useForm,
} from 'components/Form';
import { Col, Row } from 'components/Grid';
import Modal from 'components/Modal/Modal';
import Warning from 'components/Warning/Warning';

import { addTraining } from '../movingMission.service';

const CreateWorkoutModal = ({ modal, setModal }) => {
	const { register, isValid, isDirty, values } = useForm();
	const { types, enableFreeze } = modal;
	const navigate = useNavigate();
	const isFreeze = values.action === 'freeze';
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const handleSubmit = useSubmit(async () => {
		let data = {
			date: values.date[0],
		};

		if (!isFreeze) {
			data = {
				...data,
				duration: values.duration,
				type: values.type,
				...(values.selfies[0] && { selfies: values.selfies }),
				...(values.naturePictures[0] && {
					naturePictures: values.naturePictures,
				}),
				...(values.proof[0] && { proof: values.proof[0] }),
				selfiesPrivacy: values.selfies.map(file =>
					file.shareable ? 1 : 0,
				),
				naturePicturesPrivacy: values.naturePictures.map(file =>
					file.shareable ? 1 : 0,
				),
			};
		} else {
			data = {
				...data,
				type: 'freeze',
			};
		}

		const newData = await addTraining(data);
		if (newData) {
			updateMovingMission(newData);
			setModal(null);
			navigate('/moving-mission/je-prestaties');
		}
	});

	const submitDisabled = () => {
		if (isFreeze) return !isDirty() || !values.date.length;
		return (
			!isDirty() ||
			!isValid() ||
			!values.proof.length ||
			!values.date.length ||
			!values.type
		);
	};

	const showExtraInfo = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	return (
		<Modal
			show={true}
			close={() => setModal(null)}
			title='Voeg activiteit toe'
			className='create-workout-modal'
			actions={[
				{
					label: 'Bewaar',
					styles: { marginLeft: '1rem' },
					disabled: submitDisabled(),
					action: handleSubmit,
				},
				{
					label: <FontAwesomeIcon icon={faInfoCircle} size='xl' />,
					action: showExtraInfo,
					buttonType: 'link',
					styles: { float: 'left' },
				},
			]}>
			<Row>
				<Col xs={12}>
					<Warning>
						Voeg je workouts aaneensluitend toe om je bonus te
						behouden. Voeg je je workouts in bulk toe, doe dit dan
						in volgorde om je bonus niet te mislopen.
					</Warning>
				</Col>
			</Row>
			<Row>
				<Col xl={6}>
					<Datepicker
						{...register('date', {
							required: true,
							value: [],
							disabledDays: [{ after: new Date() }],
						})}
					/>
				</Col>
				<Col xl={6}>
					<InputRadioButton
						{...register('action', {
							options: [
								{
									value: 'training',
									label: 'Workout toevoegen',
								},
								{
									value: 'freeze',
									label: 'Streak freeze',
									disabled: !enableFreeze,
								},
							],
							required: true,
							label: 'Kies een optie',
							value: 'training',
						})}
					/>
					{!isFreeze ? (
						<Select
							{...register('type', {
								required: true,
								label: 'Type Activiteit',
								placeholder: 'Selecteer een activiteit',
								isCreatable: true,
								description:
									'Selecteer een activiteit uit de lijst of start te typen en klik op enter om een nieuwe activiteit toe te voegen',
								options: types.map(type => ({
									value: type.name,
									label: (
										<span>
											<FontAwesomeIcon
												className='select-icon'
												icon={['fas', type.icon]}
											/>
											<span className='select-text'>
												{type.name}
											</span>
										</span>
									),
								})),
							})}
						/>
					) : null}
					{!isFreeze ? (
						<InputField
							{...register('duration', {
								required: true,
								label: 'Aantal Minuten',
								placeholder: 'Vul minuten in',
								validations: [
									isRequired,
									isBiggerThan(15),
									isNotExponent,
									isBiggerThan0,
								],
								button: {
									icon: faClock,
									baseValue: 60,
									useWholeNumbers: true,
									options: [
										{ value: 0.25, label: '15' },
										{ value: 0.5, label: '30' },
										{ value: 0.75, label: '45' },
										{ value: 1, label: '60' },
									],
								},
							})}
						/>
					) : null}
				</Col>
			</Row>
			{!isFreeze ? (
				<Row>
					<Col xl={12}>
						<Upload
							{...register('selfies', {
								label: 'Selfies',
								showPrivacyOptions: true,
								value: [],
							})}
						/>
					</Col>
					<Col xl={12}>
						<Upload
							{...register('naturePictures', {
								label: "Natuur foto's",
								showPrivacyOptions: true,
								value: [],
							})}
						/>
					</Col>
					<Col xl={12}>
						<Upload
							{...register('proof', {
								required: true,
								label: 'Bewijs',
								value: [],
							})}
						/>
					</Col>
				</Row>
			) : null}
			{isPopoverOpen ? (
				<section className='create-workout-info'>
					<div>
						<ol>
							<li>
								<span className='bold'>Minimale sporttijd</span>{' '}
								Zorg ervoor dat je minstens 15 minuten per
								sessie sport.
							</li>
							<li>
								<span className='bold'>Puntensysteem</span>{' '}
								Verdien 1 punt voor elke minuut van je
								sportsessie.
							</li>
							<li>
								<span className='bold'>Bonuspunten </span>Krijg
								1 extra punt voor het delen van een natuurfoto
								en 5 extra punten voor een selfie.
							</li>
							<li>
								<span className='bold'>Wekelijkse bonus </span>
								Na een aaneengesloten reeks van 7 dagen ontvang
								je een bonus. Deze bonus begint bij 5 punten en
								neemt toe met 5 punten per opeenvolgende week.
							</li>
							<li>
								<span className='bold'>Streak Freeze </span>Je
								kunt één keer per week een &apos;Streak
								Freeze&apos; gebruiken. Hiermee kun je een dag
								overslaan zonder je bonus te verliezen. Houd er
								rekening mee dat een Streak Freeze evenveel kost
								als je eerstvolgende bonus.
							</li>
						</ol>
					</div>
				</section>
			) : null}
		</Modal>
	);
};

export default CreateWorkoutModal;
