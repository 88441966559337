import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadTags } from 'core/store/tags/tag.actions';
import { loadTrainings } from 'core/store/trainings/trainings.actions';

import { ListPageLayout } from 'components/Layouts/ListPageLayout/ListPageLayout';

import './TrainingOverview.scss';

const TrainingOverview = () => {
	const { trainings } = useSelector(state => state.trainingReducer);
	const { tags } = useSelector(state => state.tagReducer);

	useEffect(() => {
		loadTrainings();
		loadTags('trainings');
	}, []);

	return trainings && tags.trainings ? (
		<ListPageLayout
			pageTitle='Opleidingen'
			contentType='opleidingen'
			contentArray={trainings}
			tags={tags.trainings}
		/>
	) : null;
};

export default TrainingOverview;
