import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	faBell,
	faExclamationTriangle,
	faGear,
	faRankingStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'core/hooks/ThemeProvider';
import { loadAlerts } from 'core/store/alerts/alerts.actions';
import {
	loadNotifications,
	setNotificationsToSeen,
} from 'core/store/notifications/notifications.actions';

import Alerts from 'components/Alerts/Alerts';
import Notifications from 'components/Notifications/Notifications';
import OverviewPopup from 'components/OverviewPopup/OverviewPopup';
import Toggle from 'components/Toggle/Toggle';

import { fetchAllNotifications } from './navigation.service';

import './TopBarNavigation.scss';

const TopBarNavigation = () => {
	const [allNotifications, setAllNotifications] = useState([]);
	const [showAlerts, setShowAlerts] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const [showAllNotifications, setShowAllNotifications] = useState(false);
	const { theme, mode, toggleTheme } = useTheme();

	const { permissions } = useSelector(state => state.authReducer);
	const { alerts } = useSelector(state => state.alertReducer);
	const { notifications } = useSelector(state => state.notificationReducer);
	const { movingMission } = useSelector(state => state.movingMissionReducer);

	const isAdmin = permissions?.includes('admin');

	const topBarRef = useRef();

	useEffect(() => {
		loadAlerts();
		loadNotifications();
	}, []);

	useEffect(() => {
		const handleScroll = e => {
			if (topBarRef.current && topBarRef.current.contains(e.target))
				return true;
			setShowAlerts(false);
			setShowNotifications(false);
		};

		window.addEventListener('scroll', handleScroll, true);

		return function cleanup() {
			window.removeEventListener('scroll', handleScroll, true);
		};
	});

	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleClickAlerts = e => {
		handleClick(e);
		setShowAlerts(prev => !prev);
		setShowNotifications(false);
	};

	const handleCloseNotifications = () => {
		setShowNotifications(false);
		setNotificationsToSeen();
	};

	const handleClickNotifications = e => {
		handleClick(e);
		if (showNotifications) {
			setNotificationsToSeen();
		}
		setShowNotifications(prev => !prev);
		setShowAlerts(false);
		setShowAllNotifications(false);
	};

	const handleFetchAllNotifications = async e => {
		handleClick(e);
		const allNotifications = await fetchAllNotifications();
		setAllNotifications(allNotifications);
		setShowAllNotifications(true);
	};
	const handleShowLessNotifications = e => {
		handleClick(e);
		setShowAllNotifications(false);
	};

	return (
		<nav className='top-bar-navigation' ref={topBarRef}>
			<ul>
				{movingMission ? (
					<li>
						<Link to='moving-mission'>
							<FontAwesomeIcon
								icon={faRankingStar}
								fixedWidth
								className='moving-mission-icon'
							/>
						</Link>
					</li>
				) : null}
				{isAdmin ? (
					<li>
						<a
							href='https://up-admin.unipartners.org'
							target='_blank'
							rel='noreferrer'>
							<FontAwesomeIcon icon={faGear} fixedWidth />
						</a>
					</li>
				) : null}
				<li>
					<Link to='' onClick={handleClickAlerts}>
						<FontAwesomeIcon
							icon={faExclamationTriangle}
							fixedWidth
						/>
						{alerts?.length ? (
							<div className='count-badge'>
								<span className='count-badge__count'>
									{alerts?.length > 9
										? '9+'
										: alerts?.length || 0}
								</span>
							</div>
						) : null}
						{alerts && showAlerts ? (
							<OverviewPopup
								title='Waarschuwingen'
								count={alerts?.length}
								close={() => setShowAlerts(false)}>
								<Alerts items={alerts} title='Waarschuwingen' />
							</OverviewPopup>
						) : null}
						<span aria-hidden='true'>Waarschuwingen</span>
					</Link>
				</li>
				<li>
					<Link to='' onClick={handleClickNotifications}>
						<FontAwesomeIcon icon={faBell} fixedWidth />
						{notifications?.length ? (
							<div className='count-badge'>
								<span className='count-badge__count'>
									{notifications?.length > 9
										? '9+'
										: notifications?.length || 0}
								</span>
							</div>
						) : null}
						{notifications && showNotifications ? (
							<OverviewPopup
								title='Notificaties'
								count={
									showAllNotifications
										? allNotifications.length
										: notifications.length
								}
								showFooter={true}
								showAllNotifications={showAllNotifications}
								onFooterClick={handleFetchAllNotifications}
								onFooterClickLess={handleShowLessNotifications}
								close={handleCloseNotifications}>
								<Notifications
									items={
										showAllNotifications
											? allNotifications
											: notifications
									}
									title='Notificaties'
								/>
							</OverviewPopup>
						) : null}
						<span aria-hidden='true'>Meldingen</span>
					</Link>
				</li>
				<li>
					<Link to='' onClick={handleClick}>
						<Toggle
							theme={theme}
							mode={mode}
							toggleTheme={toggleTheme}
						/>
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default TopBarNavigation;
