import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLoadItemsOnScroll from 'core/hooks/useLoadItemsOnScroll';
import { loadAllNews } from 'core/store/newsFeed/newsFeed.actions';

import EndOfList from '../EndOfList/EndOfList';
import Loading from '../Loading/Loading';

import NewsFeedList from './NewsFeedList/NewsFeedList';

import './NewsFeed.scss';

const NewsFeed = () => {
	const {
		newsFeedHasNextPage,
		newsFeedIsNextPageLoading,
		newsFeedsNextPage,
		newsFeeds,
	} = useSelector(state => state.newsFeedReducer);

	useEffect(() => {
		if (newsFeedHasNextPage && !newsFeedIsNextPageLoading) loadAllNews(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLoadItemsOnScroll(
		newsFeedsNextPage,
		newsFeedHasNextPage,
		newsFeedIsNextPageLoading,
		loadAllNews,
		'news-feed',
	);

	return (
		<div id='news-feed' className='news-feed'>
			<NewsFeedList newsFeeds={newsFeeds} />
			{newsFeedIsNextPageLoading && newsFeedHasNextPage ? (
				<Loading />
			) : null}
			{!newsFeedHasNextPage ? <EndOfList /> : null}
		</div>
	);
};

export default NewsFeed;
