import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import {
	updateAttachments,
	updateFeedItems,
} from 'core/store/utils/updateState.utils';

import { updateStickersOfStickerable } from '../stickers/sticker.utils';

let defaultState = {
	newsFeeds: [],
	newsFeedsNextPage: 1,
	newsFeedHasNextPage: true,
	newsFeedIsNextPageLoading: false,
	newsFeedDetails: null,
};

const loadNewsFeeds = (state, { newsFeedLoading }) => ({
	...state,
	newsFeedIsNextPageLoading: newsFeedLoading,
});

const loadNewsFeedsCommit = (state, { data }) => {
	const hasNextPage = data.currentPage < data.lastPage;
	return {
		...state,
		newsFeeds: updateFeedItems(state.newsFeeds, data, updateAttachments),
		newsFeedsNextPage: hasNextPage ? data.currentPage + 1 : null,
		newsFeedHasNextPage: hasNextPage,
		newsFeedIsNextPageLoading: false,
	};
};

const loadNewsDetailCommit = (state, { data }) => ({
	...state,
	newsFeedDetails: data,
});

const updateStickersOfNewsFeed = updateStickersOfStickerable('newsFeed');

export const newsFeedReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.NEWS_FEEDS_LOAD_ALL:
			return loadNewsFeeds(state, payload);
		case ACTION_TYPES.NEWS_FEEDS_LOAD_ALL_COMMIT:
			return loadNewsFeedsCommit(state, payload);
		case ACTION_TYPES.NEWS_DETAILS_LOAD_COMMIT:
			return loadNewsDetailCommit(state, payload);
		case ACTION_TYPES.STICKER_ADD:
			return updateStickersOfNewsFeed(state, payload, 'ADD');
		case ACTION_TYPES.STICKER_DELETE:
			return updateStickersOfNewsFeed(state, payload, 'DELETE');
		default:
			return state;
	}
};
