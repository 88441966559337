import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { loadProfile } from 'core/store/authentication/auth.actions';
import { Login } from 'views';

import { SiteLayout } from 'components/Layouts';
import Loading from 'components/Loading/Loading';

import ProtectedRoutes from '../ProtectedRoutes/ProtectedRoutes';
import UnprotectedRoutes from '../UnprotectedRoutes/UnprotectedRoutes';

const MainRouter = () => {
	useMsalAuthentication(InteractionType.Redirect);
	const isAuthenticated = useIsAuthenticated();
	const { authLoading } = useSelector(state => state.authReducer);
	const { meInfoFetched } = useSelector(state => state.authReducer);

	const { loginTries } = useSelector(state => state.authReducer);

	if (loginTries >= 5) {
		throw new Error();
	}

	useEffect(() => {
		if (isAuthenticated && !meInfoFetched) {
			loadProfile();
		}
	}, [isAuthenticated, meInfoFetched]);

	return (
		<Routes>
			<Route element={<UnprotectedRoutes loggedIn={isAuthenticated} />}>
				<Route path='/login' element={<Login />} />
				{/*<Route path="/login" element={<Login loggedIn={loggedIn} />} />
                <Route path="/wachtwoord-vergeten" element={<ForgotPassword />} />
                <Route path="/activeer" element={<Activate />} />*/}
			</Route>

			{isAuthenticated ? (
				<Route element={<ProtectedRoutes loggedIn={isAuthenticated} />}>
					<Route
						path='/*'
						element={
							authLoading ? (
								<Loading large centered />
							) : (
								<SiteLayout />
							)
						}
					/>
				</Route>
			) : null}
		</Routes>
	);
};

export default MainRouter;
