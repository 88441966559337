import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadContacts } from 'core/store/authentication/auth.actions';

import Card from 'components/Card/Card';
import { Col, Container, Row } from 'components/Grid';
import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import SocialMedia from 'components/SocialMedia/SocialMedia';

import './ContactsPage.scss';

const ContactsPage = () => {
	const { contacts } = useSelector(state => state.authReducer);

	useEffect(() => {
		loadContacts();
	}, []);

	return (
		<section className='contacts'>
			<PageHeading pageTitle='Contacts' />
			<Container className='overview'>
				<Row className='overview__items'>
					{contacts
						? contacts.map((contact, index) => {
								const {
									id,
									firstName,
									lastName,
									email,
									slug,
									telephone,
									socialMedia,
									thumbnail: picture,
								} = contact;
								return (
									<Col lg={6} key={index}>
										<Card
											title={`${firstName} ${lastName}`}
											subTitle={contact.function.replaceAll(
												'\\r\\n',
												'\r\n',
											)}
											url={`/medewerkers/${slug}`}
											picture={picture}
											roundedPicture
											key={id}>
											<p>
												<a href={`mailto:${email}`}>
													{email}
												</a>
											</p>
											<div className='telephone'>
												{telephone.map(number => (
													<a
														href={`tel: ${number.number}`}
														key={number.number}>
														{number.number}
													</a>
												))}
											</div>
											<div className='social-media_wrapper'>
												<SocialMedia
													socials={socialMedia}
													teams={email}
													squareIcons
													addClassName
												/>
											</div>
										</Card>
									</Col>
								);
							})
						: null}
				</Row>
			</Container>
		</section>
	);
};

export default ContactsPage;
