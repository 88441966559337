import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

const initialState = {
	movingMissionLoading: false,
	movingMission: null,
	types: null,
};

export const movingMissionReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.MOVING_MISSION_LOAD:
			return { ...state, movingMissionLoading: true };
		case ACTION_TYPES.MOVING_MISSION_LOAD_COMMIT:
			return {
				...state,
				movingMission: action.payload?.data,
				movingMissionLoading: false,
			};
		case ACTION_TYPES.MOVING_MISSION_TYPES_LOAD_COMMIT:
			return { ...state, types: action.payload?.data };
		default:
			return state;
	}
};
