import { node, object, string } from 'prop-types';

const Row = ({ className, style, children }) => {
	let string = ['row'];
	className && string.push(className);

	return (
		<div className={string.join(' ')} style={style}>
			{children}
		</div>
	);
};

Row.propTypes = {
	className: string,
	style: object,
	children: node,
};

export default Row;
