export const ACTION_TYPES = {
	AUTH_LOGIN: 'AUTH_LOGIN',
	AUTH_LOGIN_COMMIT: 'AUTH_LOGIN_COMMIT',
	AUTH_LOGOUT: 'AUTH_LOGOUT',
	AUTH_LOGOUT_COMMIT: 'AUTH_LOGOUT_COMMIT',
	AUTH_LOAD_PROFILE: 'AUTH_LOAD_PROFILE',
	AUTH_LOAD_PROFILE_COMMIT: 'AUTH_LOAD_PROFILE_COMMIT',
	AUTH_LOAD_PICTURE: 'AUTH_LOAD_PICTURE',
	AUTH_LOAD_CONTACTS: 'AUTH_LOAD_CONTACTS',
	AUTH_LOAD_CONTACTS_COMMIT: 'AUTH_LOAD_CONTACTS_COMMIT',

	ALERTS_LOAD: 'ALERTS_LOAD',
	ALERTS_LOAD_COMMIT: 'ALERTS_LOAD_COMMIT',
	ALERTS_UPDATE: 'ALERTS_UPDATE',

	UPDATE_BIOGRAPHY: 'UPDATE_BIOGRAPHY',
	UPDATE_SOCIAL_MEDIA: 'UPDATE_SOCIAL_MEDIA',

	MEDIA_LOAD_USER_PICTURE: 'MEDIA_LOAD_USER_PICTURE',
	MEDIA_LOAD_USER_PICTURE_COMMIT: 'MEDIA_LOAD_USER_PICTURE_COMMIT',
	MEDIA_LOAD_PICTURE: 'MEDIA_LOAD_PICTURE',
	MEDIA_LOAD_PICTURE_COMMIT: 'MEDIA_LOAD_PICTURE_COMMIT',
	MEDIA_LOAD_BLOB: 'MEDIA_LOAD_BLOB',
	MEDIA_LOAD_BLOB_COMMIT: 'MEDIA_LOAD_BLOB_COMMIT',

	NOTIFICATIONS_LOAD: 'NOTIFICATIONS_LOAD',
	NOTIFICATIONS_LOAD_COMMIT: 'NOTIFICATIONS_LOAD_COMMIT',
	NOTIFICATIONS_SEEN: 'NOTIFICATIONS_SEEN',
	NOTIFICATIONS_SEEN_COMMIT: 'NOTIFICATIONS_SEEN_COMMIT',
	NOTIFICATIONS_CLEAR: 'NOTIFICATIONS_CLEAR',

	THEME_MODE_UPDATE: 'THEME_MODE_UPDATE',
	THEME_UPDATE: 'THEME_UPDATE',
	THEME_UPDATE_COMMIT: 'THEME_UPDATE',

	TOAST_ADD: 'TOAST_ADD',
	TOAST_REMOVE: 'TOAST_REMOVE',

	STICKERS_INIT: 'STICKERS_INIT',
	STICKERS_LOAD: 'STICKERS_LOAD',
	STICKERS_LOAD_COMMIT: 'STICKERS_LOAD_COMMIT',
	STICKER_ADD: 'STICKER_ADD',
	STICKER_DELETE: 'STICKER_DELETE',

	NEWS_ARTICLES_LOAD: 'NEWS_ARTICLES_LOAD',
	NEWS_ARTICLES_LOAD_COMMIT: 'NEWS_ARTICLES_LOAD_COMMIT',

	SUB_FEEDS_LOAD: 'SUB_FEEDS_LOAD',
	SUB_FEEDS_LOAD_COMMIT: 'SUB_FEEDS_LOAD_COMMIT',

	MAIN_FEEDS_LOAD: 'MAIN_FEEDS_LOAD',
	MAIN_FEEDS_LOAD_COMMIT: 'MAIN_FEEDS_LOAD_COMMIT',

	USERS_LOAD: 'USERS_LOAD',
	USERS_LOAD_COMMIT: 'USERS_LOAD_COMMIT',
	USER_LOAD: 'USER_LOAD',
	USER_LOAD_COMMIT: 'USER_LOAD_COMMIT',

	USER_UPDATE: 'USER_UPDATE',

	COCECOS_LOAD: 'COCECO_LOAD',
	COCECOS_LOAD_COMMIT: 'COCECO_LOAD_COMMIT',

	COCES_LOAD: 'COCES_LOAD',
	COCES_LOAD_COMMIT: 'COCES_LOAD_COMMIT',

	DEPARTMENTS_LOAD: 'DEPARTMENTS_LOAD',
	DEPARTMENTS_LOAD_COMMIT: 'DEPARTMENTS_LOAD_COMMIT',
	NEWS_FEEDS_LOAD_ALL: 'NEWS_FEEDS_LOAD_ALL',
	NEWS_FEEDS_LOAD_ALL_COMMIT: 'NEWS_FEEDS_LOAD_ALL_COMMIT',
	NEWS_DETAILS_LOAD: 'NEWS_DETAILS_LOAD',
	NEWS_DETAILS_LOAD_COMMIT: 'NEWS_DETAILS_LOAD_COMMIT',

	//Attempt to make Event Feed
	EVENT_FEEDS_LOAD: 'EVENT_FEEDS_LOAD',
	EVENT_FEEDS_LOAD_COMMIT: 'EVENT_FEEDS_LOAD_COMMIT',

	EVENT_LOAD: 'EVENT_LOAD',
	EVENT_LOAD_COMMIT: 'EVENT_LOAD_COMMIT',

	//Tags for events
	TAGS_INIT: 'TAGS_INIT',
	TAGS_LOAD: 'TAGS_LOAD',
	TAGS_LOAD_COMMIT: 'TAGS_LOAD_COMMIT',

	//Attempt to make Training Feed
	TRAINING_FEEDS_LOAD: 'TRAINING_FEEDS_LOAD',
	TRAINING_FEEDS_LOAD_COMMIT: 'TRAINING_FEEDS_LOAD_COMMIT',

	TRAINING_LOAD: 'TRAINING_LOAD',
	TRAINING_LOAD_COMMIT: 'TRAINING_LOAD_COMMIT',
	TRAINING_UPDATE_ORDER: 'TRAINING_UPDATE_ORDER',

	CAR_FLEET_LOAD: 'CAR_FLEET_LOAD',
	CAR_FLEET_LOAD_COMMIT: 'CAR_FLEET_LOAD_COMMIT',
	CAR_SELECT: 'CAR_SELECT',

	TOPPINGS_INIT: 'TOPPINGS_INIT',

	INVITE_LOAD_COMMIT: 'INVITE_LOAD_COMMIT',
	INVITE_SET: 'INVITE_SET',

	WELLBEING_LOAD: 'WELLBEING_LOAD',
	WELLBEING_LOAD_COMMIT: 'WELLBEING_LOAD_COMMIT',
	WELLBEING_POST: 'WELLBEING_POST',
	WELLBEING_DELETE: 'WELLBEING_DELETE',

	MOVING_MISSION_LOAD: 'MOVING_MISSION_LOAD',
	MOVING_MISSION_LOAD_COMMIT: 'MOVING_MISSION_LOAD_COMMIT',
	MOVING_MISSION_TYPES_LOAD: 'MOVING_MISSION_TYPES_LOAD',
	MOVING_MISSION_TYPES_LOAD_COMMIT: 'MOVING_MISSION_TYPES_LOAD_COMMIT',
};
