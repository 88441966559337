import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import snackbarFactories from 'core/factories/snackbar.factories';
import { dispatchAction } from 'core/services/actions/action.service';

export const addToast = options => {
	dispatchAction({
		type: ACTION_TYPES.TOAST_ADD,
		payload: snackbarFactories(options),
	});
};

export const removeToast = id => {
	dispatchAction({
		type: ACTION_TYPES.TOAST_REMOVE,
		payload: { id },
	});
};
