export const updateFeedItems = (items, { data, from, to }, mapperFn) => {
	const newItems = mapperFn ? data.map(mapperFn) : data;
	if (items.length === from - 1) {
		return [...items, ...newItems];
	}

	return items.slice(0, from - 1).concat(newItems, items.slice(to));
};

export const updateAttachments = item => ({
	...item,
	attachments: item?.attachments?.map(m => ({
		type: 'attachments',
		uuid: m.id,
	})),
});

export const findItemPage = (items, id) => {
	const itemIndex = items.findIndex(
		x => id === x.feedItem?.entryId || id === x.entryId || id === x.id,
	);
	return itemIndex !== -1 ? Math.ceil((itemIndex + 1) / 20) : null;
};
