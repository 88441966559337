import { Link, useLocation } from 'react-router-dom';
import { faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './MovingMission.scss';

const MovingMission = () => {
	const { pathname } = useLocation();

	const isMovingMissionPage = pathname.includes('/moving-mission');

	return isMovingMissionPage ? null : (
		<div className='moving-mission-promo'>
			<Link
				to='/moving-mission'
				className='moving-mission-promo__content'>
				<FontAwesomeIcon
					className='moving-mission-promo__icon'
					icon={faRankingStar}
					size='3x'
				/>
				<span className='moving-mission-promo__title'>
					Moving Mission
				</span>
				<span className='moving-mission-promo__description'>
					Kom in actie
				</span>
			</Link>
		</div>
	);
};

export default MovingMission;
