import { INPUT_TYPES } from 'core/constants';
import { SocialMediaIconService } from 'core/services/icon/social.media/social.media.icon.service';

import { isValidUrl } from '../validation/form.validation';

export const SocialFormFields = {
	Facebook: {
		type: INPUT_TYPES.URL,
		name: 'Facebook',
		label: 'Facebook',
		errors: [],
		icon: SocialMediaIconService('Facebook'),
		validations: [isValidUrl],
		value: '',
	},
	LinkedIn: {
		type: INPUT_TYPES.URL,
		name: 'LinkedIn',
		label: 'LinkedIn',
		errors: [],
		icon: SocialMediaIconService('LinkedIn'),
		validations: [isValidUrl],
		value: '',
	},
	Twitter: {
		type: INPUT_TYPES.URL,
		name: 'Twitter',
		label: 'Twitter',
		errors: [],
		icon: SocialMediaIconService('Twitter'),
		validations: [isValidUrl],
		value: '',
	},
	Instagram: {
		type: INPUT_TYPES.URL,
		name: 'Instagram',
		label: 'Instagram',
		errors: [],
		icon: SocialMediaIconService('Instagram'),
		validations: [isValidUrl],
		value: '',
	},
	TikTok: {
		type: INPUT_TYPES.URL,
		name: 'TikTok',
		label: 'TikTok',
		errors: [],
		icon: SocialMediaIconService('TikTok'),
		validations: [isValidUrl],
		value: '',
	},
};
