import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	userPictures: [],
	entryPictures: [],
};

const loadUserPicture = (state, { uuid, loading, type }) => ({
	...state,
	userPictures: {
		...state.userPictures,
		[uuid]: {
			...state.userPictures[uuid],
			uuid,
			type,
			loading: state.userPictures[uuid]
				? state.userPictures[uuid].loading
				: loading,
			loaded: state.userPictures[uuid]
				? state.userPictures[uuid].loaded
				: false,
		},
	},
});

const loadUserPictureCommit = (state, { uuid }) => {
	return {
		...state,
		userPictures: {
			...state.userPictures,
			[uuid]: {
				...state.userPictures[uuid],
				loading: false,
				loaded: true,
			},
		},
	};
};

const loadEntryPicture = state => {
	return state;
	/*if (!state.entryPictures.find((x) => x.id === id)) {
      return {
        ...state,
        entryPictures: [
          ...state.entryPictures,
          {
            id,
            file: null,
            loading,
            loaded
          }
        ]
      };
    } else {
      return {
        ...state,
        entryPictures: state.entryPictures.map((obj) => {
          if (obj.id !== id) return obj;

          return {
            ...obj,
            id,
            loading: obj.file ? false : loading,
            loaded: obj.file ? true : loaded
          };
        })
      };
    }*/
};

const loadEntryPictureCommit = state => {
	/*({
  ...state,
  entryPictures: state.entryPictures.map((x) => {
    if (x.id !== id) return x;

    return {
      ...x,
      ...data,
      loading: false,
      loaded: true
    };
  })
})*/
	return state;
};

const loadBlob = state => {
	return state;
	/*if (!state.attachmentBlobs.find((x) => x.id === id)) {
      return {
        ...state,
        attachmentBlobs: [
          ...state.attachmentBlobs,
          {
            id,
            file: null,
            loading,
            loaded
          }
        ]
      };
    } else {
      return {
        ...state,
        attachmentBlobs: state.attachmentBlobs.map((obj) => {
          if (obj.id !== id) return obj;

          return {
            ...obj,
            id,
            loading: obj.file ? false : loading,
            loaded: obj.file ? true : loaded
          };
        })
      };
    }*/
};

const loadBlobCommit = state => {
	return state;
	/*return {
      ...state,
      attachmentBlobs: state.attachmentBlobs.map((x) => {
        if (x.id !== id) return x;

        return {
          ...x,
          ...data,
          loading: false,
          loaded: true
        };
      })
    };*/
};

export const mediaReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.MEDIA_LOAD_USER_PICTURE:
			return loadUserPicture(state, payload);
		case ACTION_TYPES.MEDIA_LOAD_USER_PICTURE_COMMIT:
			return loadUserPictureCommit(state, payload);
		case ACTION_TYPES.MEDIA_LOAD_PICTURE:
			return loadEntryPicture(state, payload);
		case ACTION_TYPES.MEDIA_LOAD_PICTURE_COMMIT:
			return loadEntryPictureCommit(state, payload);
		case ACTION_TYPES.MEDIA_LOAD_BLOB:
			return loadBlob(state, payload);
		case ACTION_TYPES.MEDIA_LOAD_BLOB_COMMIT:
			return loadBlobCommit(state, payload);
		default:
			return state;
	}
};
