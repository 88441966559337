import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	//data: []
	//events: [],
	tags: {
		events: [],
	},
};

const initTags = (state, availableTags) => ({
	...state,
	availableTags,
});

const loadTags = (state, { type }) => {
	return {
		...state,
		tags: {
			...state.tags,
			[type]: state.tags[type],
		},
	};
};

//Hier altijd data te loggen voor te zien wat je exact hebt.
const loadTagsCommit = (state, { type, data }) => {
	return {
		...state,
		tags: {
			...state.tags,
			[type]: data.data,
		},
	};
};

export const tagReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.TAGS_INIT:
			return initTags(state, payload);
		case ACTION_TYPES.TAGS_LOAD:
			return loadTags(state, payload);
		case ACTION_TYPES.TAGS_LOAD_COMMIT:
			return loadTagsCommit(state, payload);
		default:
			return state;
	}
};
