import { useState } from 'react';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';

/**
 * A `react-hook-form` like hook for handling form state.
 * @param {Object} options - The options of the hook.
 * @param {boolean} options.disableWhenOffline - Whether the form should be disabled when offline.
 * @param {(values: {[k: string]: string|boolean})=>void} options.onFormChange - The function to be called when the form changes.
 * @returns {FormMethods} The form object.
 * @example
 * const { values, register, valid } = useForm();
 * <Form onSubmit={handleSubmit}>
 * 	<InputField {...register('firstName', { value: 'John' })} />
 * 	<InputField {...register('lastName', { value: 'Doe' })} />
 * 	<Button type='submit' disabled={!valid()}>
 * 		Submit
 * 	</Button>
 * </Form>
 */
export const useForm = ({
	disableWhenOffline = true,
	onFormChange = () => {},
} = {}) => {
	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});
	const [allValidations, setAllValidations] = useState([]);
	const [dirty, setDirty] = useState(false);

	const online = useOnlineStatus();

	/**
	 * Register a field in the form.
	 * @param {string} fieldName - The name of the field.
	 * @param {FormField} options - The options of the field.
	 * @returns {UpdatedFormField}- The updated field.
	 * @example
	 * const { register } = useForm();
	 * <InputField {...register('firstName', { value: 'John' })} />
	 */
	const register = (fieldName, options) => {
		const {
			value,
			errors: fieldErrors,
			validations,
			name,
			label,
		} = options;

		if (!Object.prototype.hasOwnProperty.call(values, fieldName)) {
			setValues(prev => ({
				...prev,
				[fieldName]: value !== undefined ? value : '',
			}));
			setErrors(prev => ({
				...prev,
				[fieldName]: fieldErrors || [],
			}));
			setAllValidations(prev => [
				...prev,
				{
					fieldName,
					validations,
				},
			]);
		}

		/**
		 * A Callback function to be called when the field changes.
		 * @param {*} value - The value of the field.
		 */
		const onChange = value => {
			setValues(prev => {
				const newValues = { ...prev, [fieldName]: value };
				onFormChange(newValues);
				return newValues;
			});

			if (options.onChange) {
				options.onChange(value);
			}

			setDirty(true);
		};

		/**
		 * Validate the field.
		 * @param {((val, label: string) => void)[]} validations
		 */
		const validate = validations => {
			setErrors(prev => ({
				...prev,
				[fieldName]: validations
					.map(errorsFor =>
						errorsFor(
							values[fieldName],
							label || name || fieldName,
						),
					)
					.filter(errorMsg => errorMsg.length > 0),
			}));
		};

		return {
			...options,
			name: fieldName || options.name || options.label,
			value: values[fieldName],
			errors: errors[fieldName],
			onChange,
			validate,
			disabled: options.disabled || (!online && disableWhenOffline),
			key: fieldName,
		};
	};

	/**
	 * Unregister a field from the form.
	 * @param {string} fieldName
	 */
	const unregister = fieldName => {
		setValues(prev => {
			delete prev[fieldName];
			return prev;
		});
		setErrors(prev => {
			delete prev[fieldName];
			return prev;
		});
		setAllValidations(prev => {
			return prev.filter(({ fieldName: name }) => name !== fieldName);
		});
	};

	/**
	 * Reset the form.
	 */
	const reset = () => {
		setValues(prev => {
			const newFields = {};

			Object.keys(prev).forEach(key => {
				const isArray = Array.isArray(prev[key]);
				const isNumber = Number.isInteger(prev[key]);
				const isBoolean = typeof prev[key] === 'boolean';
				newFields[key] = isArray
					? []
					: isNumber
						? 0
						: isBoolean
							? false
							: '';
			});

			setDirty(false);
			return newFields;
		});
		setErrors(prev => {
			const newFields = {};

			Object.keys(prev).forEach(key => {
				newFields[key] = [];
			});

			return newFields;
		});
	};

	/**
	 * Set the value of a field.
	 * @param {string} fieldName
	 * @param {*} value
	 */
	const setValue = (fieldName, value) => {
		setValues(prev => ({
			...prev,
			[fieldName]: value,
		}));
		setDirty(true);
	};

	/**
	 * Get the value of a field.
	 * @param {string} fieldName
	 * @returns {*} The value of the field.
	 * @example
	 * const { getValue } = useForm();
	 * const value = getValue('firstName');
	 * // value = 'John'
	 */
	const getValue = fieldName => {
		return values[fieldName];
	};

	/**
	 * Check if the form is valid.
	 * @returns {boolean} Whether the form is valid.
	 */
	const isValid = () => {
		return allValidations.reduce(
			(acc, { fieldName, validations = [] } = {}) => {
				const value = values[fieldName];
				const errors = validations
					.map(errorsFor => errorsFor(value, fieldName))
					.filter(errorMsg => errorMsg.length > 0);
				return acc && errors.length === 0;
			},
			true,
		);
	};

	/**
	 * Check if the form is dirty.
	 * @returns {boolean} Whether the form is dirty.
	 * @example
	 * const { isDirty } = useForm();
	 * const dirty = isDirty();
	 * // dirty = true
	 */
	const isDirty = () => {
		return dirty;
	};

	return {
		values,
		register,
		unregister,
		isValid,
		reset,
		setValue,
		getValue,
		isDirty,
	};
};

/**
 * @typedef {Object} FormField
 * @property {string} type - The type of the field.
 * @property {string} name - The name of the field.
 * @property {string} label - The label of the field.
 * @property {string} placeholder - The placeholder of the field.
 * @property {string} description - The description of the field.
 * @property {boolean} required - Whether the field is required.
 * @property {boolean} readOnly - Whether the field is read only.
 * @property {boolean} disabled - Whether the field is disabled.
 * @property {string} autocomplete - The autocomplete of the field.
 * @property {Function[]} validations - The validations of the field.
 * @property {string[]} errors - The initial errors of the field.
 * @property {string} className - The className of the field.
 * @property {string} value - The initial value of the field.
 * @property {string} buttonIcon - The buttonIcon of the field.
 * @property {string} icon - The icon of the field.
 */

/**
 * @typedef {Object} ExtraProps
 * @property {Function} validate - The validate function of the field.
 * @property {Function} onChange - The onChange function of the field.
 * @property {string} key - The key of the field.
 */

/**
 * @typedef {FormField & ExtraProps} UpdatedFormField
 */

/**
 * @typedef {Object} FormMethods
 * @property {{[k: string]: string|boolean}} values - The values of the form.
 * @property {(fieldName: string, options: FormField) => UpdatedFormField} register - The register function of the form.
 * @property {(fieldName: string) => void} unregister - The unregister function of the form.
 * @property {() => boolean} isValid - The isValid function of the form.
 * @property {() => void} reset - The reset function of the form.
 * @property {(fieldName: string, value: string|boolean) => void} setValue - The setValue function of the form.
 * @property {(fieldName: string) => string|boolean} getValue - The getValue function of the form.
 * @property {() => boolean} isDirty - The isDirty function of the form.
 */
