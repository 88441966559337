import { useSelector } from 'react-redux';
import {
	deleteSticker,
	postSticker,
} from 'core/store/stickers/sticker.actions';

import Icon from 'components/Icon/Icon';

const AddStickersPopover = ({
	userSticker,
	objId,
	feedType,
	type,
	closePopover,
}) => {
	const { availableStickers } = useSelector(state => state.stickerReducer);
	const isActive = stickerId =>
		userSticker ? userSticker.sticker.id === stickerId : false;

	const handleSticker = (e, stickerId) => {
		e.preventDefault();
		e.stopPropagation();
		if (isActive(stickerId)) {
			deleteSticker(stickerId, objId, type, feedType);
		} else {
			postSticker(stickerId, objId, type, feedType, userSticker);
		}
		closePopover();
	};

	return (
		<div className='add-sticker-popover'>
			<ul>
				{availableStickers.map(sticker => {
					return (
						<li
							key={sticker.id}
							className={isActive(sticker.id) ? 'active' : ''}
							onClick={e => handleSticker(e, sticker.id)}>
							<Icon icon={sticker.class} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default AddStickersPopover;
