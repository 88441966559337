import Image from 'components/Image/Image';

import Icon from '../Icon/Icon';

import './Notifications.scss';

const Notifications = ({ items, title }) => {
	return (
		<div className='notifications'>
			{items?.length ? (
				<ul className='notifications__list'>
					{items
						.filter(
							item =>
								item.notificationableType === 'placed_stickers',
						)
						.map((item, i) => (
							<li key={i}>
								<div className='notifications__item'>
									<div className='notifications__item__image'>
										<Image id={item.author?.picture} />
									</div>
									<div className='notifications__item__content'>
										<div className='notifications__item__header'>
											<span className='notifications__item__title'>
												{item.author?.firstName}{' '}
												{item.author?.lastName}
											</span>
											<span className='notifications__item__date'>
												{item.publishedAt}
											</span>
										</div>
										<div className='notifications__item__body'>
											{item.sticker ? (
												<div className='notifications__item__body__content'>
													plaatste{' '}
													<span className='notifications__item__sticker'>
														<Icon
															icon={
																item.sticker
																	.class
															}
														/>
													</span>{' '}
													op{' '}
													<span className='notifications__item__text'>
														&quot;
														{item.subFeed?.title}
													</span>
													&quot;
												</div>
											) : null}
											{item.comment ? (
												<span
													className='notifications__item__comment'
													dangerouslySetInnerHTML={{
														__html: item.comment?.body.replace(
															/<[^>]*>([^<]*)<\/[^>]*>/,
															'<strong>$1</strong>',
														),
													}}
												/>
											) : null}
										</div>
									</div>
								</div>
							</li>
						))}
				</ul>
			) : (
				<p>Er zijn geen {title.toLowerCase()}</p>
			)}
		</div>
	);
};

export default Notifications;
