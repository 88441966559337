/**
 * @module timesheet.helpers
 * @desc This module provides helper functions for the timesheet form component.
 * The functions are used to calculate:
 * - the total absences
 * - total work hours
 * - total "real hours"
 * for the week from the timesheet data object.
 * The module also provides functions to create an option object for the select week dropdown and an array of select week options for the select week dropdown.
 * The module also provides a function to create a "payload" object that can be used in the API call to update the timesheet data and a new project data object that can be used to update the state.
 * @requires {@link core/services/date}
 */
import { toShortDate, toTechnicalDate } from 'core/services/date';

/**
 * Create a "payload" object that can be used in the API call to update the timesheet data and a new project data object that can be used to update the state.
 * @param {number} dayIndex - Index of the day in the timesheet data
 * @param {*} newDayData - New data to be updated for the day
 * @param {number} projectIndex - Index of the project in the timesheet data
 * @param {*} currentProjectData - Current timesheet data
 * @returns {object} { payload, newProjectData } - Object containing the payload and new project data
 * @example
 * const { payload, newProjectData } = createUpdate(0, { duration: 8 }, 0, { projects: [{ id: 1, dates: [{ date: '2021-07-01', duration: 0 }] }] });
 * // payload = { projectId: 1, duration: 8 }
 * // newProjectData = { projects: [{ id: 1, dates: [{ date: '2021-07-01', duration: 8 }] }] }
 */

export const createUpdate = (
	dayIndex,
	newDayData,
	projectIndex,
	currentProjectData,
) => {
	let payload = {};
	const newProjectData = {
		...currentProjectData,
		projects: currentProjectData?.projects?.map((project, index) => {
			if (index === projectIndex) {
				payload.projectId = project.id;
				return {
					...project,
					dates: project.dates?.map((date, index) => {
						if (index === dayIndex) {
							const updatedData = {
								...date,
								...newDayData,
							};
							payload = {
								...payload,
								...updatedData,
							};
							return updatedData;
						}
						return date;
					}),
				};
			}
			return project;
		}),
	};
	return { payload, newProjectData };
};

/**
 * Calculates the total absences for the week from the timesheet data object.
 * @param {*} timesheetData - Timesheet data object
 * @returns {{ date: string, duration: number }[]} - Array of objects containing the date and total duration of absences for the week
 * @example
 * const absences = getWeeklyAbsences({ dates: [{ date: '2021-07-01' }, { date: '2021-07-02'}], absences: [{ date: '2021-07-01', duration: 8 }, { date: '2021-07-02', duration: 8 }] });
 * // absences = [{ date: '2021-07-01', duration: 8, id: 1 }, { date: '2021-07-02', duration: 8, id: 2]
 */
export const getWeeklyAbsences = timesheetData => {
	const absences = timesheetData?.dates?.map(({ date }) => {
		const absencesForDate = timesheetData?.absences?.filter(
			absence => absence.date === date,
		);
		let returnVal = {
			date,
			duration: 0,
		};

		if (absencesForDate) {
			absencesForDate.map(a => {
				returnVal = {
					...returnVal,
					duration: returnVal.duration + a.duration,
				};
			});
		}
		return returnVal;
	});
	return absences;
};

export const getTimesheetDayTotal = day => {
	return (
		(day?.duration || 0) +
		(day?.h100 || 0) +
		(day?.h150 || 0) +
		(day?.h200 || 0)
	);
};

/**
 * Calculates the total work hours for the week from the timesheet data object.
 * @param {*} timesheetData - Timesheet data object
 * @returns {{ date: string, duration: number }[]} - Array of objects containing the date and total duration of work hours for the week
 * @example
 * const totalWorkHours = getTotalWorkHoursForTheWeek({ dates: [{ date: '2021-07-01' }, { date: '2021-07-02'}], projects: [{id: 1, dates: [{ date: '2021-07-01', duration: 4 }, { date: '2021-07-02', duration: 4 }] }, { id: 2, dates: [{ date: '2021-07-01', duration: 4 }, { date: '2021-07-02', duration: 4 }]}] });
 * // totalWorkHours = [{ date: '2021-07-01', duration: 8 }, { date: '2021-07-02', duration: 8 }];
 */
export const getTotalWorkHoursForTheWeek = timesheetData => {
	const absences = getWeeklyAbsences(timesheetData);
	return timesheetData?.dates?.map(({ date }, index) => ({
		date,
		duration:
			absences[index]?.duration +
			timesheetData?.projects?.reduce(
				(acc, project) =>
					acc + getTimesheetDayTotal(project.dates[index]),
				0,
			),
	}));
};

/**
 * Calculates the total "real hours" for the week from the timesheet data object. "Real hours" are the total hours worked., multiplied by a "reference factor" that is used to convert the hours worked to "real hours.
 * @param {*} timesheetData - Timesheet data object
 * @returns {{ date: string, absenceDuration: number, realDuration: number }[]} - Array of objects containing the date, total duration of absences and total duration of real hours for the week
 * @example
 * const totalRealHours = getTotalRealHoursForTheWeek({ dates: [{ date: '2021-07-01' }, { date: '2021-07-02'}], projects: [{id: 1, dates: [{ date: '2021-07-01', realDuration: 4 }, { date: '2021-07-02', realDuration: 4 }] }, { id: 2, dates: [{ date: '2021-07-01', realDuration: 4 }, { date: '2021-07-02', realDuration: 4 }]}], absences: [{ date: '2021-07-01', duration: 4 }, { date: '2021-07-02', duration: 4 }] });
 * // totalRealHours = [{ date: '2021-07-01', absenceDuration: 4, realDuration: 8 }, { date: '2021-07-02', absenceDuration: 4, realDuration: 8 }];
 */
export const getTotalRealHoursForTheWeek = timesheetData => {
	const absences = getWeeklyAbsences(timesheetData);
	return timesheetData?.dates?.map(({ date }, index) => ({
		date,
		absenceDuration: absences[index]?.duration,
		realDuration:
			absences[index]?.duration +
			timesheetData?.projects?.reduce(
				(acc, project) =>
					acc +
					Number(
						(
							project.dates[index]?.combinedHours ||
							project.dates[index]?.realDuration ||
							0
						).toFixed(3),
					),
				0,
			),
	}));
};

/**
 * Creates an option object for the select week dropdown.
 * @param {string} dateString - Date string in the format of `YYYY-MM-DD`
 * @returns {{ value: string, label: string }} - Object containing the value and label for the select week option
 * @example
 * const option = createSelectWeekOption('2021-07-01');
 * // option = { value: '2021-07-01', label: '01/07/2021 - 07/07/2021' }
 */
const createSelectWeekOption = dateString => {
	const beginWeek = new Date(dateString);
	return {
		value: dateString,
		label: `${toShortDate(dateString)} - ${toShortDate(
			new Date(beginWeek.setDate(beginWeek.getDate() + 6)),
		)}`,
	};
};

/**
 * Creates an array of select week options for the select week dropdown. The options are generated for the current week and the next 7 weeks. The options are generated based on the date string provided.
 * @param {string} dateString - Date string in the format of `YYYY-MM-DD`
 * @returns {{ value: string, label: string }[]} - Array of objects containing the value and label for the select week options
 * @example
 * const options = createSelectWeekOptions('2021-07-01');
 * // options = [{ value: '2021-07-01', label: '01/07/2021 - 07/07/2021' }, { value: '2021-07-08', label: '08/07/2021 - 14/07/2021' }, { value: '2021-07-15', label: '15/07/2021 - 21/07/2021' }, { value: '2021-07-22', label: '22/07/2021 - 28/07/2021' }, { value: '2021-07-29', label: '29/07/2021 - 04/08/2021' }, { value: '2021-08-05', label: '05/08/2021 - 11/08/2021' }, { value: '2021-08-12', label: '12/08/2021 - 18/08/2021' }, { value: '2021-08-19', label: '19/08/2021 - 25/08/2021' }];
 */
export const createSelectWeekOptions = dateString => {
	const options = [];
	const startDate = new Date(dateString);
	startDate.setDate(1);
	const diff =
		startDate.getDate() -
		startDate.getDay() +
		(startDate.getDay() === 0 ? -6 : 1);

	startDate.setDate(diff);

	const targetMonth = new Date(startDate).getMonth() + 2;
	const targetYear = new Date(startDate).getFullYear() + 1;

	let count = 0;

	while (
		startDate.getMonth() <= targetMonth &&
		startDate.getFullYear() <= targetYear &&
		count < 8
	) {
		options.push(createSelectWeekOption(toTechnicalDate(startDate)));
		startDate.setDate(startDate.getDate() + 7);
		count++;
	}
	return options;
};
