/**
 * @module timesheet.service
 * @desc This module provides functions to interact with the timesheet API.
 * @requires core/services/actions
 * @requires core/services/actions/action.service
 */

import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';

/**
 * Uploads a timesheet.
 * @param {Object} payload - The timesheet data to be uploaded.
 * @param {string} [method='POST'] - The HTTP method to be used for the request.
 * @returns {Promise<Object>} The response data from the API.
 * @throws {Error} If an error occurs during the API request.
 */
export const uploadTimesheet = async (payload, method) => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/timesheets',
			method: method || 'POST',
			data: payload,
		});
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		throw error;
	}
};

/**
 * Makes a copy request for the timesheet.
 * @returns {Promise<Object>} The response data from the API.
 * @throws {Error} If an error occurs during the API request.
 */
export const makeCopyRequest = async () => {
	try {
		const { data } = await executeAxios({
			url: '/timesheets/copy',
		});
		handleAlert('success', 'Timesheet gekopieerd');
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

/**
 * Submits the timesheets.
 * @returns {Promise<boolean>} A boolean indicating if the timesheets were successfully submitted.
 * @throws {Error} If an error occurs during the API request.
 */
export const submitTimesheets = async () => {
	try {
		const {
			data: { status },
		} = await executeAxios({
			method: 'POST',
			url: '/timesheets/submit',
		});
		if (status === 'success') {
			handleAlert('success', 'Timesheet ingediend');
			return true;
		}
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

/**
 * Retrieves the lock date for the timesheets.
 * @returns {Promise<Object>} The lock date data from the API.
 * @throws {Error} If an error occurs during the API request.
 */
export const getLockDate = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/timesheets/lock-date',
		});
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};

/**
 * Retrieves the timesheets.
 * @param {Object} params - The query parameters for the API request.
 * @returns {Promise<Object>} The timesheet data from the API.
 * @throws {Error} If an error occurs during the API request.
 */
export const getTimesheets = async params => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/timesheets',
			params,
		});
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		throw error;
	}
};

/**
 * Deletes a timesheet.
 * @param {string} projectId - The ID of the project associated with the timesheet.
 * @param {string} date - The date of the timesheet.
 * @returns {Promise<boolean>} A boolean indicating if the timesheet was successfully deleted.
 */
export const deleteTimesheet = async (projectId, date) => {
	if (!projectId || !date) {
		handleAlert('error', 'Niet alle velden zijn ingevuld');
		return false;
	}
	try {
		await executeAxios({
			method: 'DELETE',
			url: '/timesheets',
			data: {
				projectId,
				date,
			},
		});
		handleAlert('success', 'Uren verwijderd');
		return true;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		return false;
	}
};

/**
 * Sets the timesheet display notation.
 * @param {string} notation - The notation to be set for the timesheet display.
 * @returns {Promise<Object>} The response data from the API.
 * @throws {Error} If an error occurs during the API request.
 */
export const setTimesheetDisplayNotation = async notation => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			method: 'PUT',
			url: '/auth/me/settings',
			data: {
				settings: { timesheetDisplay: notation },
			},
		});
		handleAlert('success', 'Notatie gewijzigd');
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
	}
};
