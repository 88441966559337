import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundry';
import { MainRouter } from './core/routers';

const App = () => (
	<BrowserRouter>
		<ErrorBoundary>
			<MainRouter />
		</ErrorBoundary>
	</BrowserRouter>
);

export default App;
