import { handleAlert } from 'core/services/actions/action.service';
import { executeAxios } from 'core/services/actions/axios.service';

export const getUnsignedTimesheets = async () => {
	try {
		const { data } = await executeAxios({
			url: '/timesheets/records',
		});
		if (data.status === 'success') {
			const projectData = data.data;
			if (!projectData || !Array.isArray(projectData)) {
				return null;
			}

			return projectData;
		}
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de timesheet.',
		);
	}
};

export const signTimesheet = async date => {
	const year = new Date(date).getFullYear();
	const month = new Date(date).getMonth() + 1;
	try {
		await executeAxios({
			method: 'POST',
			url: `/timesheets/records/sign?year=${year}&month=${month}`,
			data: {},
		});
		handleAlert('success', 'Timesheet ondertekend');
	} catch (error) {
		console.log('error', error);
		handleAlert('error', 'Er is iets misgegaan');
	}
};

export const downloadTimesheet = async (fileId, filename) => {
	try {
		const { data } = await executeAxios({
			url: `/data/${fileId}`,
			responseType: 'blob',
		});

		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		handleAlert('success', 'Timesheet gedownload');
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		console.log(error);
	}
};
