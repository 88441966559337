import { useEffect, useState } from 'react';

export default function useInputField({
	name,
	validate,
	value,
	onChange,
	validations,
	validateOnChange,
	reset,
}) {
	const [touched, setTouched] = useState(false);
	const [focused, setFocused] = useState(false);

	const id = name
		? name
				.toLowerCase()
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join('')
		: Math.random().toString(36).substring(2, 15);

	const handleFocus = () => setFocused(true);

	const handleBlur = () => {
		setFocused(false);
		setTouched(true);
		validate(validations);
	};

	const handleChange = e => {
		onChange(e.target.value);
	};

	useEffect(() => {
		if (!reset && (validateOnChange || touched)) {
			validate(validations);
		}
	}, [value]); // eslint-disable-line

	useEffect(() => {
		if (value) {
			validate(validations);
		}
	}, []); // eslint-disable-line

	return {
		focused,
		id,
		onBlur: handleBlur,
		onFocus: handleFocus,
		onChange: handleChange,
	};
}
