import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { bool } from 'prop-types';

const ProtectedRoutes = ({ loggedIn }) => {
	const location = useLocation();

	return loggedIn ? (
		<Outlet />
	) : (
		<Navigate to='/login' replace state={{ from: location }} />
	);
};

ProtectedRoutes.propTypes = {
	loggedIn: bool.isRequired,
};

export default ProtectedRoutes;
