import {
	faBicycle,
	faCar,
	faClock,
	faComment,
	faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toShortDate } from 'core/services/date';
import { getTimeInFormat } from 'core/services/parse';
import { bool, func, object, string } from 'prop-types';

import './TimesheetDay.scss';

const TimesheetDay = ({
	day,
	onClick,
	format = 'time',
	itemState = 'blank',
	simple = false,
	editable = true,
	header = false,
}) => {
	const stateMap = {
		disabled: 'disabled-item',
		mismatch: 'probleem',
		complete: 'afgerond',
		submitted: 'afgerond',
		incomplete: 'incompleet',
		available: 'available',
		unavailable: 'unavailable',
		blank: 'blanco',
	};

	const extraClass = stateMap[itemState] || '';

	const hasField = (obj, fieldName) =>
		Object.prototype.hasOwnProperty.call(obj, fieldName);

	return (
		<div
			className={`timesheet-item-container${
				day.date ? '' : ' invisible'
			}`}>
			<section
				className={`timesheet-item ${extraClass} ${
					editable ? '' : 'timesheet-item--non-editable'
				} ${header ? 'timesheet-item--always-header' : ''}`}
				onClick={editable ? onClick : null}>
				<span className='timesheet-item__header'>
					{toShortDate(day?.date)}
				</span>
				<div className={`timesheet-item__body ${extraClass}`}>
					<p>{getTimeInFormat(format, day?.duration)}</p>
					{simple ? null : (
						<div className='icon-container'>
							{hasField(day, 'duration') &&
							itemState !== 'unavailable' ? (
								<FontAwesomeIcon
									icon={faClock}
									className={`icon ${
										day.duration ? 'icon__done' : ''
									} ${
										itemState === 'mismatch'
											? 'icon__error'
											: ''
									}`}
								/>
							) : null}
							{hasField(day, 'onCall') ? (
								<FontAwesomeIcon
									icon={faStopwatch}
									className={`icon ${
										Object.values(day?.onCall).some(
											val => val,
										)
											? 'icon__done'
											: ''
									}`}
								/>
							) : null}
							{hasField(day, 'mileage') &&
							hasField(day?.mileage, 'car') ? (
								<FontAwesomeIcon
									icon={faCar}
									className={`icon ${
										day?.mileage?.car ? 'icon__done' : ''
									}`}
								/>
							) : null}
							{hasField(day, 'mileage') &&
							hasField(day?.mileage, 'bike') ? (
								<FontAwesomeIcon
									icon={faBicycle}
									className={`icon ${
										day?.mileage?.bike ? 'icon__done' : ''
									}`}
								/>
							) : null}
							{hasField(day, 'remark') ? (
								<FontAwesomeIcon
									icon={faComment}
									className={`icon ${
										day?.remark ? 'icon__done' : ''
									}`}
								/>
							) : null}
						</div>
					)}
				</div>
			</section>
		</div>
	);
};

TimesheetDay.propTypes = {
	day: object.isRequired,
	onClick: func,
	format: string,
	itemState: string,
	simple: bool,
	editable: bool,
	header: bool,
};

export default TimesheetDay;
