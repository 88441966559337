import axios from 'axios';
import axiosRetry from 'axios-retry';
import { store } from 'core/store/store.config';
import { db } from 'dexie-db';

import { handleAlert } from './action.service';

export const dispatchMedia = ({ uuid, type }, loadThumb = false) => {
	if (uuid) {
		handleDispatch(uuid, type)
			.then(object => {
				if (object) {
					if (!object.loading && !object.loaded) {
						return handleUpdateMedia(uuid, type, { ...object });
					} else {
						return false;
					}
				} else {
					return handleAddMedia(uuid, type);
				}
			})
			.then(data => {
				if (data !== false) {
					const online = window.navigator.onLine;

					if (online) {
						return executeAction(uuid, type, loadThumb);
					} else {
						return storeActionForExecution(uuid, type, loadThumb);
					}
				}
			})
			// eslint-disable-next-line no-unused-vars
			.then(succeeded => {
				//console.log('%csucceeded', 'color: red', succeeded)
			});
	}
};

const handleDispatch = async (uuid, type) => {
	return db.media
		.where({
			type,
			uuid,
		})
		.first();
};

const executeAction = async (uuid, type, loadThumb) => {
	const token = store.getState().authReducer.token;
	const online = window.navigator.onLine;

	let headers = { Authorization: `Bearer ${token}` };

	axiosRetry(axios, {
		retries: 5,
		retryDelay: retryCount => {
			handleAlert(
				'warning',
				`Er ging iets fout. We proberen het nog ${
					6 - retryCount
				} keer, binnen ${retryCount * 2} seconden.`,
				retryCount * 2000,
			);

			return retryCount * 2000;
		},
		// eslint-disable-next-line no-unused-vars
		retryCondition: error => {
			//return error.response.status === 500;
		},
	});

	return (
		axios({
			url: `/${type}/${uuid}/media${loadThumb ? '?thumb=true' : ''}`,
			method: 'get',
			headers,
			baseURL: process.env.REACT_APP_API_URL,
		})
			.then(({ data }) => {
				return handleUpdateMedia(uuid, type, data);
			})
			.then(data => {
				return data === 1;
			})
			// eslint-disable-next-line no-unused-vars
			.catch(error => {
				if (!online) {
					storeActionForExecution(uuid, type, loadThumb);
				} else {
					handleUpdateMedia(uuid, type, {
						description: null,
						filename: null,
						mime: null,
						file: 'fail',
					});

					handleAlert(
						'error',
						`De ${
							type === 'user-picture' || type === 'images'
								? 'afbeelding'
								: 'bijlage'
						} kon niet worden gedownload.`,
					);
				}

				return false;
			})
	);
};

const storeActionForExecution = async (uuid, type, loadThumb) => {
	handleRollback(uuid, type);

	if (type === 'user-picture' || type === 'image') {
		return db.dispatchMedia
			.add({ uuid, type, loadThumb })
			.then(() => {
				handleAlert(
					'info',
					'De afbeelding wordt gedownload wanneer je weer verbonden bent met het netwerk.',
				);
			})
			.catch(() => {
				handleAlert(
					'error',
					'Je actie kon niet worden bewaard. Probeer het later opnieuw.',
				);
			})
			.finally(() => false);
	}

	return false;
};

const handleAddMedia = async (uuid, type) => {
	return await db.media.add({
		uuid: uuid,
		type: type,
		loading: true,
		loaded: false,
		description: null,
		filename: null,
		mime: null,
		file: null,
	});
};

const handleUpdateMedia = async (
	uuid,
	type,
	{ description, filename, mime, file },
) => {
	return await db.media.update([uuid, type], {
		loading: !file && !description,
		loaded: !!(file || description),
		description: description,
		filename: filename,
		mime: mime,
		file: file,
	});
};

const handleRollback = (uuid, type) => {
	db.media.update([uuid, type], {
		loading: false,
	});
};
