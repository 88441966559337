import { useState } from 'react';

export const useSubmit = submitFunction => {
	const [loading, setLoading] = useState(false);

	const handleSubmit = async e => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		if (loading) return;

		setLoading(() => true);
		await submitFunction();
		setLoading(() => false);
	};

	return handleSubmit;
};
